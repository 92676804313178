<template>
  <v-container grid-list-md>
    <h2 class="py-2 px-6">Applicant List</h2>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="10">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-6">
            <v-col cols="12" md="2">
              <v-select
                label="Term"
                dense
                v-model = searchForm.term_id
                :items=terms
                item-text="name"
                item-value="id"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                label="Tentative Title"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.proposal_title
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-6" justify="start">
            <v-col>
              <v-text-field
                label="Collaborator Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.collaborator_name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Collaborator Email"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.collaborator_email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Collaborator Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.collaborator_affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="px-6 d-flex justify-start">
            <v-checkbox
              hide-details
              label="Not Registered"
              class="ma-4 ml-0"
              value="0"
              v-model="searchForm.status"
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Registered"
              class="ma-4"
              value="1"
              v-model="searchForm.status"
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Rejected"
              class="ma-4"
              value="2"
              v-model="searchForm.status"
            ></v-checkbox>
            <v-col cols="2" md="6">
              <v-select
                label="Early Career"
                dense
                v-model = searchForm.early_career
                :items = earlyCareer
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
              ></v-select>
            </v-col>
          </div>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="applicantSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/proposers/applicant?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        
  <div>
    <div class="d-flex justify-space-between align-end mb-5">
      <div class="text-caption">{{total}} results</div>
      <v-btn color="deep-orange darken-1" dark @click="downLoad"
        ><v-icon>mdi-download</v-icon>Download CSV</v-btn
      >
    </div>
    <v-card>
      <v-data-table
        :items="items"
        :headers="headers"
        :page.sync="page"
        hide-default-footer
        no-data-text="No data"
        disable-pagination
      >
        <template v-slot:item="{ item }">
            <tr>
              <td>
                  <v-btn
                    x-small
                    outlined
                    :to="'/proposers/applicant/detail/' + item.id"
                    >DETAIL</v-btn
                  >
                </td>
              <td>{{ item.name }}</td>
              <td>
                {{ item.email }}<br>
                {{ item.affiliation }}
              </td>
              <td>{{ earlyCareerShort[item.early_career] }}</td>
              <td>
                {{ item.proposal_title }}
              </td>
              <td class="py-3">
                <div v-if="item.applicant_collaborator.length < 3">
                  <span v-for="(collaborator, index) in item.applicant_collaborator" :key="collaborator.id">
                    {{index + 1}}:{{ collaborator.name }} ({{ collaborator.email }}) {{ collaborator.affiliation }} <br />
                  </span>
                </div>
                <div v-else>
                  <span v-for="(collaborator, index) in item.applicant_collaborator" :key="collaborator.id">
                    <div v-if="index < 3">
                      {{index+1}}:{{ collaborator.name }} ({{ collaborator.email }}) {{ collaborator.affiliation }}
                    </div>
                    <div v-else>
                      <div v-if="isActive">
                          {{index+1}}:{{ collaborator.name }} ({{ collaborator.email }}) {{ collaborator.affiliation }}
                      </div>
                    </div>
                  </span>
                  <div class="text-right">
                    <span class="button" @click="active">...[detailed]</span>
                  </div>
                </div>
              </td>
              <td class="text-left">
                <div v-if="item.status == 0">
                  <b class="red--text text--darken-4">Not Registered</b>
                </div>
                <div v-if="item.status == 1">
                  <b class="blue--text text--darken-4">Registered</b>
                </div>
                <div v-else-if="item.status == 2">
                  <span class="text-caption">Rejected</span>
                </div>
              </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
    
    <div class="text-center py-5">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="emitChangePage()"
        circle
        color="blue darken-4"
      ></v-pagination>
    </div>
  </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      name: null,
      buttonStatus: false,
      registDialog: false,
      errors: [],
      terms: [],
      breadcrumbs: [
      ],
      samples: {},
      statuses: [],
      statusesFlat: {},
      earlyCareer:[],
      earlyCareerShort: [],
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        affiliation: "",
        proposal_title: "",
        term_id: null,
        status: ["0","1","2"],
        collaborator_name: "",
        collaborator_email: "",
        collaborator_affiliation: "",
        early_career: "",
      },
      //search用
      searchScheme: [
        "name",
        "email",
        "affiliation",
        "proposal_title",
        "term_id",
        "status",
        "collaborator_name",
        "collaborator_email",
        "collaborator_affiliation",
        "early_career",
        ], //検索条件
      searchURL: "/api/applicants", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "",
        },
        {
          text: "E-mail / Affiliation",
          sortable: false,
          value: "email affiliation",
          width: "",
        },
        {
          text: "Career",
          sortable: false,
          value: "early_career",
          width: "",
        },
        {
          text: "Tentative Title",
          sortable: false,
          value: "proposal_title",
          width: "15%",
        },
        {
          text: "Collaborators",
          sortable: false,
          value: "collaborators",
          width: "",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: "10%",
        },
      ],
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term_id = String(response.data.id);
          this.list(false,this.searchForm.term_id);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let term in response.data) {
            this.terms.push({id: term, name: response.data[term]});
          }
        });
      this.$axios
        .get("/api/masters/formArray/early_career_short")
        .then((response) => {
          this.earlyCareerShort = response.data;
      });
      this.$axios
        .get("/api/masters/early_career_type")
        .then((response) => {
          this.earlyCareer.push({id: null, label: "-"});
          for(let k in response.data) {
            this.earlyCareer.push({id: response.data[k].id, label: response.data[k].label});
          }
      });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    applicantSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    async downLoad(){
      try {
        //動作確認用
        const res = await this.$axios.get('/api/applicant/download', {
          params: {
            name: this.searchForm.name,
            affiliation: this.searchForm.affiliation,
            email: this.searchForm.email,
            term_id: this.searchForm.term_id,
            proposal_title: this.searchForm.proposal_title,
            collaborator_name: this.searchForm.collaborator_name,
            collaborator_email: this.searchForm.collaborator_email,
            collaborator_affiliation: this.searchForm.collaborator_affiliation,
            status: this.searchForm.status,
            early_career: this.searchForm.early_career,
          }
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    }
  },
};
</script>
