<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Information</h2>
      
      <div class="pt-2 pr-2">
        <v-btn
          dark
          color="blue darken-4"
          class="mr-3"
          to="/information/add"
          ><v-icon class="mr-2">mdi-newspaper-plus</v-icon>ADD</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="mt-5 mb-5 px-6">
      <v-col lg="12">
        <div>
          <v-card>
            <v-data-table
              :items="items"
              :headers="headers"
              :sort-by="['date']"
              hide-default-footer
              no-data-text="No data"
              disable-pagination
            >
            <template v-slot:item="{ item }">
                <tr v-bind:class="{ disabled: item.disabled }">
                  <td>
                    <v-btn
                      x-small
                      outlined
                      :to="'/information/edit/' + item.id"
                      >Edit</v-btn
                    >
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ target[item.target] }}</td>
                  <td v-if="item.date">{{ item.date | moment("YYYY-MM-DD") }}</td>
                  <td v-else></td>
                  <td><span v-if="item.published">x</span></td>
                  <td>
                    <v-btn v-if="item.published === 0"
                      x-small
                      outlined
                      @click="deleteInfo(item.id)"
                      color="error"
                      >DELETE</v-btn
                    >
                  </td>
                </tr>
            </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      target:[],
      items:[],
      reportStatus:[],
      status:[],
      headers: [
        {
            text: "",
            sortable: false,
            width: "10%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Target",
          sortable: false,
          value: "target",
          width: "20%",
        },
        {
          text: "Date",
          sortable: false,
          value: "date",
          width: "20%",
        },
        {
          text: "Published",
          sortable: false,
          value: "published",
          width: "20%",
        },
        {
          text: "",
          sortable: false,
          width: "10%",
        },
      ],
      isActive: false,
    };
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/masters/formArray/information_target_list")
        .then((response) => {
          this.target = response.data;
      });
      this.$axios
        .get("/api/information")
        .then((response) => {
          this.items = response.data;
      });
    })();
  },
  methods: {
    deleteInfo: function(id) {
      if(confirm('Do you delete the information?')){
        this.$axios
          .put(`/api/information/delete/${id}`)
          .then((response) => {
            response;
            if(response.data.result == true){
              this.$store.commit("setMessage",{
                text:response.data.message,
                color:"success",
              });
              this.$router.go({path: this.$router.currentRoute.path, force: true})
            }else if(response.data.result == false){
              this.$store.commit("setMessage",{
                text:response.data.message,
                color:"alert",
              });
            }
        });
      }
    },
  },
};
</script>