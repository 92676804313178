<template>
  <v-container grid-list-md>
    <div>
      <h2 class="pa-2">Home</h2>
      <v-divider></v-divider>

      <v-row justify="center" align="center" class="my-5">
        <v-col lg="8">
          <div class="mt-4 mb-5" lg="8">
            <!-- <div v-if="
            !(
              this.$store.state.auth.authrole.includes('admin')
              || this.$store.state.auth.authrole.includes('panel')
              || this.$store.state.auth.authrole.includes('reviewer')
            ) && typeof myProposal.id !== 'number' && !loading"
            class="mb-8">
            
              <h2 align="left">Registration (Notice of Intent) for the {{termText}} AO</h2>
              <p class="mt-2 mb-0 orange--text text--darken-2 text-h6">Users registered in the previous AOs also need registration for the {{termText}} AO. Please click "Registration" below and fill out the form.</p>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="text-h6">
                    <a href="/applicants/create">
                      &gt;&gt;Registration (Notice of Intent)
                    </a>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div> -->
            <v-list
              subheader
              two-line
              class="text-h6"
            >
              <v-list-item
              v-for="info in information"
              :key="info.id"
              >
             <template v-if="info.content">
                <a :href="'/information/show/' + info.id" style="text-decoration:none;">
                  <v-list-item-content>
                    <v-list-item-title v-text="info.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="info.date"></v-list-item-subtitle>
                  </v-list-item-content>
                </a>
              </template>
              <template v-else-if="info.url">
                <a :href="info.url" style="text-decoration:none;" target=”_blank” rel="noopener noreferrer">
                  <v-list-item-content>
                    <v-list-item-title v-text="info.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="info.date"></v-list-item-subtitle>
                  </v-list-item-content>
                </a>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-text="info.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="info.date"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
      
      <v-row justify="center" align="center" class="mt-4 mb-5">
        <v-col lg="8">
          <div class="mt-4 mb-5" lg="8">
            <h3 align="left">Link</h3>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <a href="https://darts.isas.jaxa.jp/app/curation/ryugu/" target="_blank">
                    &gt;&gt;Ryugu Sample DB
                  </a>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>

      <div v-if="myProposal.status === 0">
        <v-row justify="center" align="center" class="mt-4 mb-5">
          <v-btn 
            color="blue darken-4"
            large
            dark
            class="mr-5"
            :to="'/proposals/edit/' + myProposal.id"
            v-if="proposalAccepted"
            >CREATE PROPOSAL</v-btn
          >
          <v-btn 
            color="black darken-4"
            large
            dark
            to="/myprofile"
            >My Profile</v-btn
          >
        </v-row>
      </div>
      <div v-if="myProposal.status === 1 || myProposal.status === 2">
        <v-row justify="center" align="center" class="mt-4 mb-5">
          <v-btn
            color="blue darken-4"
            large
            dark
            class="mr-5"
            to="/proposals/myproposal"
            v-if="proposalAccepted"
            >MY PROPOSAL</v-btn
          >
          <v-btn 
            color="black darken-4"
            large
            dark
            to="/myprofile"
            >My Profile</v-btn
          >
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      myProposal: [],
      information: [],
      proposalAccepted: false,
      loading: true,
      termText: '',
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/myProposal`)
        .then(response => {
          this.myProposal = response.data;
          this.loading = false;
        });
      this.$axios
        .get(`/api/myinformation`)
        .then(response => {
          this.information = response.data;
        });
      this.$axios
        .get(`/api/masters/getTermText/0`).then((response) => {
          this.termText = response.data;
        });
      await this.$axios
        .get(`/api/masters/checkProposalAccepted`)
        .then((response) => {
          this.proposalAccepted = response.data;
      });
    })();
  },
};
</script>