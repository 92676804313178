<template>
  <v-card color="basil" flat>
    <v-card-text>
      <v-row justify="center" class="pa-4 my-4">
        <v-col md="12">
          <v-card>
            <v-card-title class="px-4 py-2 blue darken-4">
              <h3 class="subtitle-1 font-weight-bold white--text">
                Requested Sample
              </h3>
            </v-card-title>
            
            <v-simple-table class="ma-4">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th width="20%">Remarks</th>
                    <td class="nl2br">{{fields.properties}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-simple-table class="ma-4">
              <template v-slot:default>
                <thead>
                  <th>Priority</th>
                  <th>Type</th>
                  <th colspan="2">Sample</th>
                  <th width="20%">Description</th>
                  <th>Chamber</th>
                  <th>Number of bottles</th>
                  <th>Requested size<br>of samples [mm]</th>
                  <th>Requested weight<br>of samples [mg]</th>
                </thead>
                <tbody>
                  <tr v-for="item in fields.requested_samples" :key="item.id" :sample_lists="sample_lists">
                    <template v-if="item.sample_id">
                      <td class="text-center">{{ item.priority }}</td>
                      <td class="text-center">{{ sampleTypes[item.sample_type] }}</td>
                      <td class="text-right">
                        <a v-bind:href="sampleDBUrl + item.sample_id" target=”_blank”>{{ sample_lists[item.sample_id].name }}</a>
                      </td>
                      <!-- sample情報をtypeによって変更 -->
                      <td class="text-left pl-0" v-if="item.sample_type == 0">
                        [{{ sample_lists[item.sample_id].size | sizeMM }} mm, {{ sample_lists[item.sample_id].weight }} mg]
                      </td>
                      <td class="text-left pl-0" v-else-if="item.sample_type == 1">
                        [{{ sample_lists[item.sample_id].weight }} mg]
                      </td>
                      <td class="text-left pl-0" v-else-if="item.sample_type == 2">
                          [{{ sample_lists[item.sample_id].pressure }} Pa, {{ sample_lists[item.sample_id].volume }} ml]
                      </td>
                      <td class="text-left pl-0" v-else-if="item.sample_type == 3">
                      </td>

                      <td class="text-left">{{ sample_lists[item.sample_id].description }}</td>
                      
                      <!-- chamberはAnyの場合のみ表示 -->
                      <td class="text-center">-</td>

                      <!-- type=2 のみnumber of bottles表示 -->
                      <td class="text-center" v-if="item.sample_type == 2">{{ item.number_of_bottles }}</td>
                      <td class="text-center" v-else>-</td>

                      <!-- type=0 のみsize表示 -->
                      <td class="text-center" v-if="item.sample_type == 0">({{ sample_lists[item.sample_id].size | sizeMM }})</td>
                      <td class="text-center" v-else>-</td>

                      <!-- type=0or1 のみweight表示 -->
                      <td class="text-center" v-if="item.sample_type == 0 || item.sample_type == 1">({{ sample_lists[item.sample_id].weight }})</td>
                      <td class="text-center" v-else>-</td>
                    </template>
                    <template v-else-if="item.sample_id == null">
                      <!-- Anyの場合 -->
                      <td class="text-center">{{ item.priority }}</td>
                      <td class="text-center">{{ sampleTypes[item.sample_type] }}</td>
                      <td class="text-center" colspan="2">Any</td>
                      <td class="text-left">-</td>

                      <!-- type=0or1 の場合にchamber表示 -->
                      <td class="text-center" v-if="item.sample_type == 0 || item.sample_type == 1">{{ chambers[item.chamber] }}</td>
                      <td class="text-center" v-else>-</td>

                      <td class="text-center">-</td>
                      <td class="text-center">{{ (item.size ? item.size : "-") | sizeMM }}</td>
                      <td class="text-center">{{ item.weight ? item.weight : "-" }}</td>
                    </template>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td colspan="7" class="text-right">Total</td>
                    <td class="text-center">{{ requestedSampleTotal }}</td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'tabAssignedSample',
  props: ['fields', 'sample_lists', 'sampleDBUrl', 'requestedSampleTotal', 'assignedSampleTotal', 'sampleTypes', 'chambers'],
  data() {
    return {
    };
  },
  filters: {
    sizeMM(value) {
      if (isNaN(value) || value == 0) {
        return "-";
      }
      const size = value / 1000;
      return size.toFixed(3);
    }
  },
};
</script>
