<template>
  <v-app>
    <v-container fluid class="login">
      <v-row justify="center" align="center">
        <v-col xs="10" sm="6" md="6" lg="4">
          <v-card color="grey lighten-5">
            <v-toolbar color="grey lighten-2" flat>
              <h4 class="mx-auto">
                Change password
              </h4>
            </v-toolbar>
            <v-divider> </v-divider>
            <div class="pa-6">
              <v-alert v-if="errorMessageShow" border="top" type="error" dark>{{
                errorMessage
              }}</v-alert>
              <v-text-field
                v-model="currentPass"
                :append-icon="passwordShow3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow3 ? 'text' : 'password'"
                outlined
                color="black"
                label="Your current password"
                :error-messages="this.errors.current_password"
                @click:append="passwordShow3 = !passwordShow3"
              ></v-text-field>

              <v-divider class="mb-3"></v-divider>
            </div>
            <div class="pa-6 pt-0">
              <v-text-field
                v-model="newPass"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow ? 'text' : 'password'"
                outlined
                color="black"
                label="Create new password"
                :error-messages="this.errors.new_password"
                :error-count="
                  this.errors.new_password ? this.errors.new_password.length : 0
                "
                @click:append="passwordShow = !passwordShow"
              ></v-text-field>

              <v-text-field
                v-model="newPassConfirm"
                :append-icon="passwordShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow2 ? 'text' : 'password'"
                outlined
                color="black"
                label="Confirm new password"
                :error-messages="this.errors.new_password_confirmation"
                :error-count="
                  this.errors.new_password_confirmation
                    ? this.errors.new_password_confirmation.length
                    : 0
                "
                @click:append="passwordShow2 = !passwordShow2"
                class="mb-4"
              ></v-text-field>
              <div class="login-btn text-center">
                <v-btn
                  class="font-weight-bold"
                  color="blue darken-4"
                  large
                  dark
                  @click="change"
                >
                  Change
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      currentPass: null,
      newPass: null,
      passwordShow: false,
      newPassConfirm: null,
      passwordShow2: false,
      passwordShow3: false,
      errorMessageShow: false,
      errorMessage: "",
      errors: {},
    };
  },
  methods: {
    change() {
    this.buttonStatus = true;
    this.errors = {};
    this.$axios.post('/api/change_password', {
      currentPass: this.currentPass,
      newPass: this.newPass,
      newPassConfirm: this.newPassConfirm,
    })
    .then(response => {
      if(response.data.result == true){
        this.$store.commit('setMessage', {
          text : response.data.message,
          color : 'success'
      });
      this.errorMessageShow = false;
      this.errorMessage = '';
      this.buttonStatus = false;
      this.$router.back();
      } else if (response.data.error_type == 'surrent_password') {
        this.buttonStatus = false;
        this.errorMessageShow = true;
        this.errorMessage = response.data.message;
        this.errors.current_password = response.data.message;
      } else if (response.data.error_type == 'new_password') {
        this.buttonStatus = false;
        this.errorMessageShow = true;
        this.errors.new_password = response.data.message;
        this.errorMessage = response.data.message;
      } else if (response.data.error_type == 'confirm_password') {
        this.buttonStatus = false;
        this.errorMessageShow = true;
        this.errors.new_password = response.data.message;
        this.errors.new_password_confirmation = response.data.message;
        this.errorMessage = response.data.message;
      }
    }).catch(error => {
      if (error.response.status === 400) {
        this.errors = error.response.data.errors || {};
        this.buttonStatus = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login_title {
  width: 100%;
}
.login {
  height: 100%;
  .row {
    height: 100%;
  }
}
</style>
