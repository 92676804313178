<template>
  <div>
    <Navigation :drawer.sync="drawer" :header="header" />
    <v-app-bar
      :color="header.color"
      clipped-left
      app
      dark
      src="@/assets/bg-bar2.jpg"
      shrink-on-scroll
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right,rgba(12,99,172,.7),rgba(4,30,51,.9)"
        ></v-img>
        <!--                gradient="to top right, rgba(119,68,0,.8), rgba(156,133,7,.5)"-->
      </template>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/"
          ><img
            src="@/assets/logo.png"
            width="60"
            class="logo_img" /></router-link
        ><span>Ryugu Sample AO System</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y dark>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" depressed light class="mt-1">
            <v-icon> mdi-account </v-icon>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="userMenu in userMenus"
            :key="userMenu.name"
            :to="userMenu.link"
            @click="userMenu.action"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ userMenu.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import Navigation from "./Navigation";

export default {
  components: { Navigation },
  props: {
    header: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      drawer: null,
      userMenus: [
        {
          name: "Change Password",
          link: "/password",
          action: () => {},
        },
        {
          name: "Log Out",
          action: this.logout,
        },
      ],
    };
  },
  methods: {
    logout: function() {
      this.$axios.get("/api/sanctum/csrf-cookie").then(() => {
        this.$axios
          .post("/api/admin/logout")
          .then((response) => {
            if (response.data.success) {
              this.$store.commit("auth/resetAuthUser");
              window.location.href = "/login";
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style>
.logo_img {
  vertical-align: bottom;
  margin-right: 20px;
}
</style>