import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import store from "./store";

axios.defaults.withCredentials = true;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // 認証エラー時の処理
    if (error.response.status === 401 || error.response.status === 419) {
      location.href = "/login";
    } else if (error.response.status === 500) {
      store.commit("setMessage", {
        text: "System Error has occurred.",
        color: "error",
      });
    } else if (error.response.status === 429) {
      store.commit("setMessage", {
        text: "You have reached the trial limit. Please try again after 1 minute.",
        color: "warning",
      });
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
