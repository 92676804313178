<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/reviewers" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <v-btn :to="'/reviewers/detail/' + fields.prevReviewerID" exact outlined v-if="fields.prevReviewerID">
              <v-icon>
                mdi-skip-previous
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
            <span>Reviewer Information</span>
            <v-btn :to="'/reviewers/detail/' + fields.nextReviewerID" exact outlined v-if="fields.nextReviewerID">
              <v-icon>
                mdi-skip-next
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="25%">Name</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.name }}
                  </td>
                </tr>
                <tr>
                  <th width="25%">E-mail</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.email }}
                  </td>
                </tr>
                <tr>
                  <th width="25%">Affiliation</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.affiliation }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Proposals
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Proposer</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Rank</th>
                  <th>Science Score</th>
                  <th>Technical Score</th>
                  <th>Report</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in fields.reports"
                  :key="item.id"
                >
                  <td>
                    {{ item.proposal.id }}
                  </td>
                  <td>
                    {{ item.proposal.name }}
                  </td>
                  <td>
                    <a :href="'/proposals/detail/' + item.proposal_id">{{ item.proposal.title }}</a>
                  </td>
                  <td>
                    <span class="red--text text--darken-4 font-weight-bold" v-if="item.status === 0">{{ status[item.status] }}</span>
                    <div class="blue--text text--darken-4 font-weight-bold" v-else-if="item.status === 1">
                      {{ status[item.status] }}
                    <v-btn
                      v-if="adminFlg"
                      class="font-weight-bold mr-4"
                      color="gray darken-4"
                      x-small
                      dark
                      @click="decline(`${item.id}`)"
                      >
                      DECLINE
                    </v-btn>

                    </div>
                    <div class="orange--text text--darken-3 font-weight-bold" v-else-if="item.status === 2">{{ status[item.status] }}</div>
                    <div class="green--text text--darken-4 font-weight-bold" v-else-if="item.status === 3">{{ status[item.status] }}</div>
                    <div class="black--text text--darken-4" v-else-if="item.status === 9">{{ status[item.status] }}</div>
                    
                    <v-btn color="red darken-4" class="ml-4" x-small dark v-if="item.status === 0 && adminFlg === 1"  @click="reportId = item.id,showRequestDialog = true">
                      SEND REQUEST
                    </v-btn>
                  </td>
                  <td>
                    {{ item.rank }}
                  </td>
                  <td>
                    {{ item.science_score }}
                  </td>
                  <td>
                    {{ item.technical_score }}
                  </td>
                  <td>
                    <v-btn
                      class="font-weight-bold mr-4"
                      color="green darken-4"
                      small
                      dark
                      v-if="item.status === 3"
                      :href="`/api/reports/download_reports/${item.id}`"
                    >
                      Download
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center mb-4">

      <v-btn v-if="adminFlg === 1"
        class="font-weight-bold mr-4 mt-4"
        color="blue darken-4"
        x-large
        dark
        @click="showRequestAllDialog = true"
      >
        SEND All REQUEST
      </v-btn>
      <v-btn v-if="adminFlg === 1"
        class="font-weight-bold mr-4 mt-4"
        color="orange darken-4"
        x-large
        dark
        @click="showReminderDialog = true"
      >
        SEND REMINDER MAIL
      </v-btn>
    </div>

    <v-row justify="center" align="center">
      <v-col md="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Proposals
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Term</th>
                  <th>ID</th>
                  <th>Proposer</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Rank</th>
                  <th>Science Score</th>
                  <th>Technical Score</th>
                  <th>Report</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in fields.previous_reports"
                  :key="item.id"
                >
                  <td>
                    {{ terms[item.term_id] }}
                  </td>
                  <td>
                    {{ item.proposal.id }}
                  </td>
                  <td>
                    {{ item.proposal.name }}
                  </td>
                  <td>
                    <a :href="'/proposals/detail/' + item.proposal_id">{{ item.proposal.title }}</a>
                  </td>
                  <td>
                    <span class="red--text text--darken-4 font-weight-bold" v-if="item.status === 0">{{ status[item.status] }}</span>
                    <div class="blue--text text--darken-4 font-weight-bold" v-else-if="item.status === 1">
                      {{ status[item.status] }}
                    <v-btn
                      v-if="adminFlg"
                      class="font-weight-bold mr-4"
                      color="gray darken-4"
                      x-small
                      dark
                      @click="decline(`${item.id}`)"
                      >
                      DECLINE
                    </v-btn>

                    </div>
                    <div class="orange--text text--darken-3 font-weight-bold" v-else-if="item.status === 2">{{ status[item.status] }}</div>
                    <div class="green--text text--darken-4 font-weight-bold" v-else-if="item.status === 3">{{ status[item.status] }}</div>
                    <div class="black--text text--darken-4" v-else-if="item.status === 9">{{ status[item.status] }}</div>
                  </td>
                  <td>
                    {{ item.rank }}
                  </td>
                  <td>
                    {{ item.science_score }}
                  </td>
                  <td>
                    {{ item.technical_score }}
                  </td>
                  <td>
                    <v-btn
                      class="font-weight-bold mr-4"
                      color="green darken-4"
                      small
                      dark
                      v-if="item.status === 3"
                      :href="`/api/reports/download_reports/${item.id}`"
                    >
                      Download
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog 
      transition="dialog-top-transition"
      max-width="600"
      v-model="showRequestAllDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to make a request for all Proposals? <br>（Passwords for reviewers not logged in will be reset.）
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showRequestAllDialog = false">Cancel</v-btn>
          <v-btn @click="requestAll()" :disabled="loading" :loading="loading" color="black" dark>REQUEST</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
      transition="dialog-top-transition"
      max-width="600"
      v-model="showReminderDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to send a reminder mail? <br>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showReminderDialog = false">Cancel</v-btn>
          <v-btn @click="sendReminder()" :disabled="loading" :loading="loading" color="black" dark>SEND</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showRequestDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to make a request for this Proposal? 
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showRequestDialog = false">Cancel</v-btn>
          <v-btn @click="request()"  :disabled="loading" :loading="loading" color="black" dark>REQUEST</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      adminFlg: 0,
      loading: false,
      showRequestAllDialog: false,
      showRequestDialog: false,
      showReminderDialog: false,
      reportId: null,
      fields: {},
      status: [],
      earlyCareerScientists: {},
      terms: [],
      breadcrumbs: [
        {
          text: "Reviewer List",
          disabled: false,
          href: "/reviewers/",
        },
        {
          text: "Reviewer Detail",
          disabled: true,
          href: "/reviewers/detail",
        },
      ],
    };
  },
  methods: {
    getReviewerData(id) {
      this.$axios
        .get(`/api/reviewers/${id}`)
        .then(response => {
          this.fields = response.data;
        });
    },
    requestAll(){
      this.showRequestAllDialog = false;
      this.loading = true;
      this.$axios
        .put(`/api/reviewers/request_all/${this.$route.params.id}`)
        .then((response) => {
          if(response.data.result == true){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"success",
            });
            this.$router.go({path: this.$router.currentRoute.path, force: true})
          }else if(response.data.result == false){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"alert",
            });
            this.loading = false;
          }
        });
      },
      request(){
      this.showRequestDialog = false;
      this.loading = true;
      this.$axios
        .put(`/api/reviewers/request/${this.reportId}`)
        .then((response) => {
          response;
          if(response.data.result == true){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"success",
            });
            this.$router.go({path: this.$router.currentRoute.path, force: true})
          }else if(response.data.result == false){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"alert",
            });
            this.loading = false;
          }
        });
        this.reportId = null;
      },
      sendReminder(){
      this.showReminderDialog = false;
      this.loading = true;
      this.$axios
        .put(`/api/reviewers/send_reminder/${this.$route.params.id}`)
        .then((response) => {
          response;
          if(response.data.result == true){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"success",
            });
          }else if(response.data.result == false){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"alert",
            });
          }
          this.loading = false;
        });
      },
      decline(id) {
        this.loading = true;
        this.$axios
        .post(`/api/reports/decline/` + id)
        .then((response) => {
          if (response.data.result == true) {
            location.reload();
          } else {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
          this.loading = false;
        });
      },
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    (async () => {
      await this.$axios
        .get("/api/masters/formArray/report_status")
        .then((response) => {
          this.status = response.data;
      });
      this.getReviewerData(this.$route.params.id);
      this.$axios
        .get(`/api/users/${this.$store.state.auth.authuser.id}`)
        .then((response) => {
          this.adminFlg = response.data.is_administration_office;
      });
    })();
    this.$axios
      .get(`/api/masters/getTerms`)
      .then(response => {
        this.terms = response.data;
      });
  },
  watch: {
    loading(val) {
      if (!val) return;
      //setTimeout(() => ((this.loading = false), (this.register = true)), 1000);
    },
    '$route' (to) {
      this.getReviewerData(to.params.id)
    }
  }
};
</script>