<template>
  <v-app>
    <v-container fluid class="application">
      <v-row justify="center" align="center">
        <v-col xs="10" sm="8" md="8" lg="6">
          <div class="text-left text-h5">
              Registration Form (Notice of Intent for the {{termText}} AO) - Confirm
          </div>
          <v-card color="grey lighten-5" class="mb-5">
            <v-toolbar color="grey lighten-2" flat>
              <h3>Proposer</h3>
            </v-toolbar>
            <v-divider> </v-divider>
            <div class="pa-6">
              <dl>
                <dt class="font-weight-bold">Name:</dt>
                <dd class="ml-4 mb-2">{{ first_name }} {{ middle_name }} {{ last_name }} {{ suffix }}</dd>
                <dt class="font-weight-bold">E-mail:</dt>
                <dd class="ml-4 mb-2">{{ email }}</dd>
                <dt class="font-weight-bold">Affiliation:</dt>
                <dd class="ml-4 mb-2">{{ affiliation }}</dd>
                <dt class="font-weight-bold">Early Career Scientist:</dt>
                <dd class="ml-4 mb-2">{{ earlyCareerScientist[early_career].label }}</dd>
                <dt class="font-weight-bold">Tentative title of the proposal:</dt>
                <dd class="ml-4 mb-2">{{ proposal_title }}</dd>
                <dt class="font-weight-bold">Brief description of the proposal:</dt>
                <dd class="ml-4" style="white-space: pre-wrap;">{{ proposal_abstract }}</dd>
              </dl>
            </div>
          </v-card>

          <v-card color="grey lighten-5" class="mb-5">
            <v-card-title>
              <h4>Collaborator</h4>
            </v-card-title>
            <div class="pa-6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">E-mail</th>
                      <th class="text-left">Affiliation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in collaborators" :key="item.name">
                      <td>{{ item.first_name }} {{ item.middle_name }} {{ item.last_name }} {{ item.suffix }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.affiliation }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card>

          <div class="login-btn text-center mt-6 pt-6 mb-5">
            <v-btn
              class="font-weight-bold mr-4"
              color="grey lighten-2"
              large
              to="/applicants/create"
            >
              Return
            </v-btn>
            <v-btn
              class="font-weight-bold"
              color="blue darken-4"
              dark
              large
              :disabled="loading"
              :loading="loading"
              @click="submit"
            >
              Send
            </v-btn>
            <v-dialog v-model="loading" hide-overlay persistent width="300">
              <v-card color="black" dark>
                <v-card-text>
                  Waiting...
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              transition="dialog-top-transition"
              max-width="600"
              v-model="dialog"
            >
              <v-card>
                <v-card-text>
                  <div class="text-h6 pa-8 grey--text text-center">
                    This application has been sent successfully
                  </div>
                </v-card-text>
                <v-card-actions class="justify-center pb-5">
                  <v-btn to="/mypage" color="black" dark>Back to TOP</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      fields:{},
      loading: false,
      dialog: false,
      earlyCareerScientist: {},
      termText: '',
    };
  },
  computed: {
    ...mapState({
      first_name: "first_name",
      middle_name: "middle_name",
      last_name: "last_name",
      suffix: "suffix",
      email: "email",
      affiliation: "affiliation",
      early_career: "early_career",
      proposal_title: "proposal_title",
      proposal_abstract: "proposal_abstract",
      collaborators: "collaborators",
    }),
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      (async () => {
        await this.$axios.get("/api/masters/early_career_type").then((response) => {
          this.earlyCareerScientist = response.data;
        });
      this.$axios.get("/api/masters/getTermText/0")
        .then((response) => {
          this.termText = response.data;
        });
      })();
    },
    submit() {
      this.loading = true;
      this.errors = {};
      this.fields.methods = "add";
      this.fields.first_name = this.first_name;
      this.fields.middle_name = this.middle_name;
      this.fields.last_name = this.last_name;
      this.fields.suffix = this.suffix;
      this.fields.email = this.email;
      this.fields.affiliation = this.affiliation;
      this.fields.early_career = this.early_career;
      this.fields.proposal_title = this.proposal_title;
      this.fields.proposal_abstract = this.proposal_abstract;
      this.fields.collaborators = this.collaborators;
      this.$axios
        .post("/api/applicants/create", this.fields)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false
            this.dialog = true
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            // this.$router.push('/applicants/create')
          }
        });
    },
  },
};
</script>
