var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('div',{staticClass:"d-flex justify-space-between mb-8"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"-"}}),_c('div',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"to":'/reports/detail/' + _vm.fields.id,"exact":"","outlined":""}},[_vm._v("Back")])],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"lg":"10"}},[_c('v-card',{attrs:{"color":"grey lighten-4"}},[_c('v-card-title',{staticClass:"px-4 py-2 d-flex justify-space-between"},[_c('h3',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" Proposal ")])]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',{attrs:{"width":"20%"}},[_vm._v("ID: Title")]),_c('td',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.fields.reports.proposal.id)+": "+_vm._s(_vm.fields.reports.proposal.title)+" ")])]),_c('tr',[_c('th',[_vm._v("Proposer Name")]),_c('td',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.fields.reports.proposal.name))])]),_c('tr',[_c('th',[_vm._v("Abstract")]),_c('td',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.fields.reports.proposal.abstract)+" ")])])])]},proxy:true}])})],1)],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"10"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-4 py-2 indigo d-flex justify-space-between white--text"},[_c('h3',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" Review ")])]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v("Science score "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Science score")]),_c('v-card-text',[_c('div',{staticClass:"pa-4"},[_c('p',[_vm._v(" Choose one score from 5-point scale from the viewpoint of scientific merit. ")])])])],1)],1)],1),_c('td',{staticClass:"pt-3 pb-4"},[_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"dense":"","items":_vm.score_list,"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"brown","background-color":"white","flat":""},model:{value:(_vm.fields.reports.science_score),callback:function ($$v) {_vm.$set(_vm.fields.reports, "science_score", $$v)},expression:"fields.reports.science_score"}}),(_vm.errors.science_score)?_c('div',{staticClass:"text-danger v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_vm._v(" This field is required. ")])])]):_vm._e()],1)],1)]),_c('tr',[_c('th',[_vm._v("Technical score "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Technical score")]),_c('v-card-text',[_c('div',{staticClass:"pa-4"},[_c('p',[_vm._v(" Choose one score from 5-point scale from the viewpoint of technical feasibility. ")])])])],1)],1)],1),_c('td',{staticClass:"pt-3 pb-4"},[_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"dense":"","items":_vm.score_list,"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"brown","background-color":"white","flat":""},model:{value:(_vm.fields.reports.technical_score),callback:function ($$v) {_vm.$set(_vm.fields.reports, "technical_score", $$v)},expression:"fields.reports.technical_score"}}),(_vm.errors.technical_score)?_c('div',{staticClass:"text-danger v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_vm._v(" This field is required. ")])])]):_vm._e()],1)],1)]),_c('tr',[_c('th',[_vm._v("Science Comment Strength "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Science Comment Strength")]),_c('v-card-text',[_c('div',{staticClass:"pa-4"},[_c('p',[_vm._v(" Write your review comment of strength points of the reviewed proposal from the viewpoint of scientific merit. ")])])])],1)],1)],1),_c('td',{staticClass:"pt-3 pb-4"},[_c('v-textarea',{attrs:{"outlined":"","solo":"","dense":"","flat":"","rows":"5","auto-grow":"","color":"black"},model:{value:(_vm.fields.reports.science_comment_strength),callback:function ($$v) {_vm.$set(_vm.fields.reports, "science_comment_strength", $$v)},expression:"fields.reports.science_comment_strength"}})],1)]),_c('tr',[_c('th',[_vm._v("Science Comment Weakness "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Science Comment Weakness")]),_c('v-card-text',[_c('div',{staticClass:"pa-4"},[_c('p',[_vm._v(" Write your review comment of weakness points of the reviewed proposal from the viewpoint of scientific merit. ")])])])],1)],1)],1),_c('td',{staticClass:"pt-3 pb-4"},[_c('v-textarea',{attrs:{"outlined":"","solo":"","dense":"","flat":"","rows":"5","auto-grow":"","color":"black"},model:{value:(_vm.fields.reports.science_comment_weakness),callback:function ($$v) {_vm.$set(_vm.fields.reports, "science_comment_weakness", $$v)},expression:"fields.reports.science_comment_weakness"}})],1)]),_c('tr',[_c('th',[_vm._v("Technical Comment Strength "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Technical Comment Strength")]),_c('v-card-text',[_c('div',{staticClass:"pa-4"},[_c('p',[_vm._v(" Write your review comment of strength points of the reviewed proposal from the viewpoint of technical feasibility. ")])])])],1)],1)],1),_c('td',{staticClass:"pt-3 pb-4"},[_c('v-textarea',{attrs:{"outlined":"","solo":"","dense":"","flat":"","rows":"5","auto-grow":"","color":"black"},model:{value:(_vm.fields.reports.technical_comment_strength),callback:function ($$v) {_vm.$set(_vm.fields.reports, "technical_comment_strength", $$v)},expression:"fields.reports.technical_comment_strength"}})],1)]),_c('tr',[_c('th',[_vm._v("Technical Comment Weakness "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"black"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v("Technical Comment Weakness")]),_c('v-card-text',[_c('div',{staticClass:"pa-4"},[_c('p',[_vm._v(" Write your review comment of weakness points of the reviewed proposal from the viewpoint of technical feasibility. ")])])])],1)],1)],1),_c('td',{staticClass:"pt-3 pb-4"},[_c('v-textarea',{attrs:{"outlined":"","solo":"","dense":"","flat":"","rows":"5","auto-grow":"","color":"black"},model:{value:(_vm.fields.reports.technical_comment_weakness),callback:function ($$v) {_vm.$set(_vm.fields.reports, "technical_comment_weakness", $$v)},expression:"fields.reports.technical_comment_weakness"}})],1)])])]},proxy:true}])})],1)],1)],1),_c('div',{staticClass:"login-btn text-center mt-4 mb-8"},[_c('v-btn',{staticClass:"font-weight-bold mr-4",attrs:{"color":"grey lighten-2","large":"","disabled":_vm.buttonStatus},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"blue darken-4","dark":"","large":"","disabled":_vm.buttonStatus},on:{"click":_vm.submit}},[_vm._v(" Save ")]),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"black","dark":""}},[_c('v-card-text',[_vm._v(" Waiting... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }