<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">User List</h2>
      <div class="pt-2 pr-2">
        <v-btn
          dark
          color="blue darken-4"
          class="mr-3"
          to="/users/add"
          ><v-icon class="mr-2">mdi-account-plus</v-icon>ADD</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="10">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-select
                label="Status"
                dense
                :items="['All', 'Active', 'Disabled']"
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
                v-model = searchForm.status
              ></v-select>
            </v-col>
          </v-row>
          <div class="px-6 d-flex justify-start">
            <v-checkbox
              hide-details
              label="Administration Office"
              class="ma-4 ml-0"
              v-model = searchForm.is_admin
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Panel"
              class="ma-4"
              v-model = searchForm.is_panel
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Reviewer"
              class="ma-4"
              v-model = searchForm.is_reviewer
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Proposer"
              class="ma-4"
              v-model = searchForm.is_proposer
            ></v-checkbox>
          </div>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="userSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/users?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-right pr-6 mb-5">
      <v-btn color="deep-orange darken-1" dark @click="downloadMailList"
        ><v-icon>mdi-download</v-icon>Download Address List</v-btn
      >
    </div>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <div class="text-caption mb-3">{{total}} results</div>
          <div>
            <v-card>
              <v-data-table
                :items="items"
                :headers="headers"
                :page.sync="page"
                hide-default-footer
                no-data-text="No data"
                disable-pagination
              >
              <template v-slot:item="{ item }">
                  <tr v-bind:class="{ disabled: item.disabled }">
                    <td>
                      <v-btn
                        x-small
                        outlined
                        :to="'/users/detail/' + item.id"
                        >DETAIL</v-btn
                      >
                    </td>
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email}}</td>
                    <td>{{ item.affiliation }}</td>
                    <td class="text-center"><span v-if="item.is_administration_office">x</span></td>
                    <td class="text-center"><span v-if="item.is_panel">x</span></td>
                    <td class="text-center"><span v-if="item.is_reviewer">x</span></td>
                    <td class="text-center"><span v-if="item.is_proposer">x</span></td>
                    <td class="text-center"><span v-if="item.disabled" class="text-caption">disabled</span></td>
                  </tr>
              </template>
              </v-data-table>
            </v-card>
            <div class="text-center py-5">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @input="emitChangePage()"
                circle
                color="blue darken-4"
              ></v-pagination>
            </div>
          </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      name: null,
      buttonStatus: false,
      registDialog: false,
      errors: [],
      items: [],
      samples: {},
      statuses: [],
      statusesFlat: {},
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        status: "All",
        is_admin: "1",
        is_panel: "1",
        is_reviewer: "1",
        is_proposer: "1",
      },
      //search用
      searchScheme: [
        "name",
        "email",
        "status",
        "is_admin",
        "is_panel",
        "is_reviewer",
        "is_proposer",
        ], //検索条件
      searchURL: "/api/users", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "ID",
          sortable: false,
          value: "id",
          width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "",
        },
        {
          text: "E-mail",
          sortable: false,
          value: "email",
          width: "",
        },
        {
          text: "Affiliation",
          sortable: false,
          value: "affiliation",
          width: "",
        },
        {
          text: "Admin",
          sortable: false,
          value: "is_administration_office",
          width: "5%",
        },
        {
          text: "Panel",
          sortable: false,
          value: "is_panel",
          width: "5%",
        },
        {
          text: "Reviewer",
          sortable: false,
          value: "is_reviewer",
          width: "5%",
        },
        {
          text: "Proposer",
          sortable: false,
          value: "is_proposer",
          width: "5%",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: "5%",
        },
      ],
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.list(false);
    })();
  },
  methods: {
    list(scrollFlg) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    userSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    async downloadMailList(){
      try {
        let value = {};
        for (const key of this.searchScheme) {
          value[key] = this.searchForm[key];
        }
        this.searchForm = value;
        const res = await this.$axios.get('/api/users/download_mail_list', {
          params: {
            name: this.searchForm.name,
            email: this.searchForm.email,
            status: this.searchForm.status,
            is_admin: (this.searchForm.is_admin == '1') ? 1 : 0,
            is_panel: (this.searchForm.is_panel == '1') ? 1 : 0,
            is_reviewer: (this.searchForm.is_reviewer == '1') ? 1 : 0,
            is_proposer: (this.searchForm.is_proposer == '1') ? 1 : 0,
          }});
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    }
  },
};
</script>

<style lang="scss">
.v-data-table {
  tbody {
    tr.disabled {
      background-color: #ddd !important;
    }
  }
}
</style>

