import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      state: {
        authuser: {},
        authrole: "0",
        loggedout: true,
      },
      mutations: {
        setAuthUser(state, { authuser, authrole }) {
          state.authuser = authuser;
          state.authrole = authrole;
          state.loggedout = false;
        },
        resetAuthUser(state) {
          state.authuser = {};
          state.authrole = "";
          state.loggedout = true;
        },
        updateLoginState(state, { loggedout }) {
          state.loggedout = loggedout;
        },
      },
      namespaced: true,
    },
  },
  state: {
    fullname: "",
    message: {
      //ヘッダフラッシュメッセージ
      text: "",
      color: "success",
      view: false,
      timeout: 5000,
    },
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: "",
    email: "",
    affiliation: "",
    early_career: "",
    proposal_title: "",
    proposal_abstract: "",
    collaborators: [],
  },
  plugins: [
    createPersistedState({
      paths: ["auth"],
    }),
    createMutationsSharer({
      predicate: [
        "auth/setAuthUser",
        "auth/resetAuthUser",
        "auth/updateLoginState",
      ],
    }),
  ],
  mutations: {
    updateFirstName(state, first_name) {
      state.first_name = first_name;
    },
    updateMiddleName(state, middle_name) {
      state.middle_name = middle_name;
    },
    updateLastName(state, last_name) {
      state.last_name = last_name;
    },
    updateSuffix(state, suffix) {
      state.suffix = suffix;
    },
    updateEmail(state, email) {
      state.email = email;
    },
    updateAffiliation(state, affiliation) {
      state.affiliation = affiliation;
    },
    updateEarlyCareer(state, early_career) {
      state.early_career = early_career;
    },
    updateProposalTitle(state, proposal_title) {
      state.proposal_title = proposal_title;
    },
    updateProposalAbstract(state, proposal_abstract) {
      state.proposal_abstract = proposal_abstract;
    },
    updateCollaborators(state, collaborators) {
      state.collaborators = collaborators;
    },
    updateFullname(state, fullname) {
      state.fullname = fullname;
    },
    setMessage(state, { text, color }) {
      state.message.text = text;
      state.message.color = color;
      if (color == "error") {
        state.message.timeout = -1;
      }
      state.message.view = true;
    },
    resetMessage(state) {
      state.message.text = "";
      state.message.view = false;
    },
  },
  actions: {},
});
