<template>
  <v-app>
    <v-container fluid class="login">
      <v-row justify="center" align="center">
        <v-col xs="10" sm="6" md="6" lg="4">
          <div class="text-center mb-4">
            <img src="../../assets/logo.png" width="100" class="mx-auto mb-4" />
          </div>
          <v-card color="grey lighten-5">
            <v-toolbar color="grey lighten-2" flat>
              <h3 class="mx-auto">
                Ryugu Sample AO System
              </h3>
            </v-toolbar>
            <v-divider> </v-divider>
            <div class="pa-6">
              <v-alert v-if="errorMessageShow" border="top" type="error" dark>{{
                errorMessage
              }}</v-alert>

              <v-text-field
                v-model="email"
                :rules="[emailRules.required]"
                outlined
                label="ID"
                class="mb-3"
                color="black"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordRules.required]"
                :type="passwordShow ? 'text' : 'password'"
                outlined
                color="black"
                label="Password"
                @click:append="passwordShow = !passwordShow"
                class="mb-4"
              ></v-text-field>
              <div class="login-btn text-center">
                <v-btn
                  @click="login"
                  class="font-weight-bold"
                  color="blue darken-4"
                  dark
                  large
                >
                  LOGIN
                </v-btn>
              </div>
              <div class="text-right mt-3 white--text">
                <v-btn
                  class="font-weight-bold"
                  color="black darken-4"
                  dark
                  small
                  :href="topUrl"
                >
                  BACK to TOP
                </v-btn>
              </div>
            </div>
          </v-card>
          <div class="text-right mt-3 white--text">
            <v-btn small color="light" to="/request"
              >If you forget your password
              <v-icon right>mdi-chevron-right</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      emailRules: {
        required: (value) => !!value || "Please enter your email address",
      },
      password: null,
      passwordShow: false,
      passwordRules: {
        required: (value) => !!value || "Please enter your password",
      },
      errorMessage: "",
      errorMessageShow: false,
      topUrl: ""
    };
  },
  mounted() {
    (async () => {
      await this.$axios
        .get(`/api/masters/getTopUrl`)
        .then((response) => {
          this.topUrl = response.data;
      });
    })();
  },
  methods: {
    login: function() {
      this.$axios.get("/api/sanctum/csrf-cookie").then((response) => {
        response;
        this.$axios
          .post("/api/admin/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.data.success) {
              //ログイン成功
              this.$store.commit('auth/setAuthUser', {
                authuser : response.data.user,
                authrole : response.data.role
              });
              this.errorMessage = "";
              this.errorMessageShow = false;
              this.$router.push(response.data.redirect);
            } else {
              this.errorMessage = "The login attempt failed. Either the user ID or password is invalid.";
              this.errorMessageShow = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 429) {
              this.errorMessage =
                "The limit of login attempts has been reached. Please try again in a few minutes.";
              this.errorMessageShow = true;
            } else {
              this.errorMessage = "Server error occurred.";
              this.errorMessageShow = true;
            }
          });
      });
    },
  },
};
</script>

<style>
.title-bg {
  background-color: #19407f;
}
</style>

<style lang="scss">
.login_title {
  width: 100%;
}
.login {
  height: 100%;
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #004e92,
    #000428
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  .row {
    height: 100%;
  }
}
</style>
