<template>
  <v-container grid-list-md>
    <div>
      <h2 class="pa-2">Dashboard</h2>
      <v-divider></v-divider>

      <v-row justify="center" align="center" class="mt-4 mb-5">
        <v-col lg="6">
          <v-list>
            <v-list-item v-if="this.$store.state.auth.authrole.includes('admin')">
              <v-list-item-content>
                <router-link :to="{name:'Applicant'}">
                  Applicants Not Registered : {{ notRegisteredNum }}
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" class="my-5">
        <v-col lg="8">
          <div class="mt-4 mb-5 text-h6" lg="8">
            <h3 align="left">Information</h3>
            <v-list
              subheader
              two-line
            >
              <v-list-item
              v-for="info in information"
              :key="info.id"
              >
              <template v-if="info.content">
                <a :href="'/information/show/' + info.id" style="text-decoration:none;">
                  <v-list-item-content>
                    <v-list-item-title v-text="info.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="info.date"></v-list-item-subtitle>
                  </v-list-item-content>
                </a>
              </template>
              <template v-else-if="info.url">
                <a :href="info.url" style="text-decoration:none;" target="_blank" rel="noopener noreferrer">
                  <v-list-item-content>
                    <v-list-item-title v-text="info.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="info.date"></v-list-item-subtitle>
                  </v-list-item-content>
                </a>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-text="info.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="info.date"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-if="this.$store.state.auth.authrole.includes('reviewer')">
      
      <div v-if="requestedProposals.length > 0">
        <div class="d-flex justify-space-between">
          <h2 class="py-2 px-6">Requested Proposals</h2>
        </div>
        <v-divider></v-divider>

        <v-row justify="center" align="center" class="mt-5 mb-5 px-6">
          <v-col lg="12">
            <template>
              <div>
                <v-card>
                  <v-data-table
                    :items="requestedProposals"
                    :headers="headers_req"
                    :page.sync="page"
                    hide-default-footer
                    no-data-text="No data"
                    disable-pagination
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td></td>
                        <td>{{ item.proposal.name }}</td>
                        <td>{{ item.proposal.affiliation }}</td>
                        <td>{{ item.proposal.id }}: {{ item.proposal.title }}</td>
                        <td>{{ item.proposal.abstract }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </template>
          </v-col>
        </v-row>

        <div justify="center" align="center">
          <v-btn
            class="font-weight-bold mr-5"
            color="blue darken-4"
            dark
            large
            @click="showAcceptDialog = true">Accept</v-btn>
          <v-btn
            class="font-weight-bold mr-5"
            color="red darken-2"
            dark
            large
            @click="showDeclineDialog = true">Decline</v-btn>
        </div>
      
      </div>

      <div class="d-flex justify-space-between">
        <h2 class="py-2 px-6">Assigned Proposals</h2>
      </div>
      <v-divider></v-divider>

      <v-row justify="center" align="center" class="mt-5 mb-5 px-6">
        <v-col lg="12">
          <template>
            <div>
              <v-card>
                <v-data-table
                  :items="items"
                  :headers="headers"
                  :page.sync="page"
                  hide-default-footer
                  no-data-text="No data"
                  disable-pagination
                >
                  <template v-slot:item="{ item }">
                    <tr v-if="item.status !== 0"><!-- status = 0 （初期状態）の proposal は対象に含めない。 -->
                      <td>
                        <v-btn
                          x-small
                          outlined
                          :to="'/reports/detail/' + item.id"
                          >DETAIL</v-btn
                        >
                      </td>
                      <td>{{ item.proposal.name }}</td>
                      <td>{{ item.proposal.affiliation }}</td>
                      <td>{{ item.proposal.id }}: {{ item.proposal.title }}</td>
                      <td>{{ item.proposal.shortAbstract }}</td>
                      <td>{{ item.rank }}</td>
                      <td>{{ item.science_score }}</td>
                      <td>{{ item.technical_score }}</td>
                      <td v-if="item.status === 1">
                        <v-btn color="red darken-4" x-small dark>{{ status[1] }}</v-btn>
                      </td>
                      <td v-else-if="item.status === 2">
                        <v-btn color="black darken-4" x-small dark>{{ status[2] }}</v-btn>
                      </td>
                      <td v-else></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="this.$store.state.auth.authrole.includes('admin') || this.$store.state.auth.authrole.includes('panel')">
      <v-row justify="center" align="center" class="my-5">
        <v-col lg="8">
          <div class="d-flex justify-space-between">
            <h2 class="mt-4 mb-5">CSV Export</h2>
          </div>
          <v-divider></v-divider>
          <v-simple-table>
            <tbody>
              <tr v-if="this.$store.state.auth.authrole.includes('admin')">
                <td class="py-3 px-5" width="60%">Applicants List</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvApplicantsList"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/proposers/applicant">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5" width="60%">Proposers List</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvProposersList"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/proposers">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr v-if="this.$store.state.auth.authrole.includes('admin')">
                <td class="py-3 px-5">Proposers &amp; Collaborators List</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvProposersAndCollaboratorsList"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/proposers/collaborators">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5 font-weight-bold">Proposals List</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvProposalsList"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/proposals">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5 font-weight-bold">Review List</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvReviewersList"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/reviewers">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table class="mt-5">
            <tbody>
              <tr>
                <td class="py-3 px-5 font-weight-bold" width="60%">Distribution Plan Sheet (Particle &amp; Aggregate)</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvDistributionPlanSheetA"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/samples/assign/Particle">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5 font-weight-bold" width="60%">Distribution Plan Sheet (Gas)</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvDistributionPlanSheetB"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/samples/assign/Gas">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5 font-weight-bold" width="60%">Distribution Plan Sheet (Previously allocated)</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvDistributionPlanSheetC"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/samples/assign/Processed">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5 font-weight-bold" width="60%">Distribution Plan Sheet (All)</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvDistributionPlanSheetAll"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td class="py-3 px-5">Proposals List w/Assigned Samples</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvProposalsListWAssignedSamples"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/proposals/assigned_samples">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
              <tr v-if="this.$store.state.auth.authrole.includes('admin')">
                <td class="py-3 px-5">Assigned Samples</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvAssignedSamples"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/samples/assigned">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          
          <v-simple-table class="mt-5" v-if="this.$store.state.auth.authrole.includes('admin')">
            <tbody>
              <tr>
                <td class="py-3 px-5" width="60%">All Users Address</td>
                <td>
                  <v-btn
                    class="font-weight-bold ma-3"
                    color="black darken-1"
                    outlined
                    @click="downloadCsvAllUsersAddress"
                    small>Download
                  </v-btn>
                </td>
                <td>
                  <a href="/users">&gt;&gt;Detailed search &amp; export</a>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showAcceptDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you accept these reviews?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showAcceptDialog = false">Cancel</v-btn>
          <v-btn @click="acceptAll" color="black" dark>Accept</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDeclineDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you decline these reviews?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDeclineDialog = false">Cancel</v-btn>
          <v-btn @click="declineAll" color="black" dark>Decline</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      notRegisteredNum: null,
      information: [],
      errors: [],
      status: [],
      requestedProposals:[],
      showAcceptDialog: false,
      showDeclineDialog: false,
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        affiliation: "",
        title: "",
        abstract: "",
        keywords: null,
        term: null,
        not_submitted: true,
        submitted: true,
        assigned: true,
        reviewed: true,
        scored: true,
        excellent: true,
        very_good: true,
        good: true,
        fair: true,
        poor: true,
        not_scored: true,
      },
      //search用
      searchScheme: [
        "name",
        "email",
        "affiliation",
        "title",
        "abstract",
        "keywords",
        "term",
        "not_submitted",
        "submitted",
        "assigned",
        "reviewed",
        "scored",
        "excellent",
        "very_good",
        "good",
        "fair",
        "poor",
        "not_scored",
        ], //検索条件
      searchURL: "/api/assigned_proposals", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "12%",
        },
        {
          text: "Affiliation",
          sortable: false,
          value: "affiliation",
          width: "12%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "15%",
        },
        {
          text: "Abstract",
          sortable: false,
          value: "abstract",
          width: "20%",
        },
        {
          text: "Rank",
          sortable: false,
          value: "Rank",
          width: "10%",
        },
        {
          text: "Science Score",
          sortable: false,
          value: "global score",
          width: "5%",
        },
        {
          text: "Technical Score",
          sortable: false,
          value: "technical score",
          width: "5%",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: "5%",
        },
      ],
      headers_req: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "15%",
        },
        {
          text: "Affiliation",
          sortable: false,
          value: "affiliation",
          width: "15%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Abstract",
          sortable: false,
          value: "abstract",
          width: "45%",
        },
      ],
    }
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/applicants/getNotRegisteredCount`)
        .then(response => {
          this.notRegisteredNum = response.data;
        });
      this.$axios
        .get(`/api/myinformation`)
        .then(response => {
          this.information = response.data;
        });
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get("/api/masters/formArray/report_status")
        .then((response) => {
          this.status = response.data;
      });
      this.$axios
        .get("/api/requested_proposals")
        .then((response) => {
          this.requestedProposals = response.data;
      });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtrueを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    acceptAll() {
    this.$axios
      .post(`/api/reports/accept_all`)
      .then((response) => {
        if (response.data.result == true) {
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "success",
          });
          window.location.reload()
        }else{
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "alert",
          });
        }
      })
      .catch((error) => {
        this.errors = error.response.data.errors || {};
        if (error.response.status === 400){
          this.$store.commit("setMessage", {
              text: 'There are errors. Please check the contents.',
              color: "warning",
          });
        }
      });
    },
    declineAll() {
    this.$axios
      .post(`/api/reports/decline_all`)
      .then((response) => {
        if (response.data.result == true) {
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "success",
          });
          window.location.reload()
        }else{
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "alert",
          });
        }
      })
      .catch((error) => {
        this.errors = error.response.data.errors || {};
        if (error.response.status === 400){
          this.$store.commit("setMessage", {
              text: 'There are errors. Please check the contents.',
              color: "warning",
          });
        }
      });
    },
    async downloadCsvApplicantsList() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/applicant/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvProposersList() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/proposers/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvProposersAndCollaboratorsList() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/people/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvProposalsList() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/proposals/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvReviewersList() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/reviewers/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        console.log(e);
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvDistributionPlanSheetA() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/samples/download/1');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvDistributionPlanSheetB() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/samples/download/2');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvDistributionPlanSheetC() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/samples/download/3');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvDistributionPlanSheetAll() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/samples/download/0');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvProposalsListWAssignedSamples() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/proposals/download_assigned_samples');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvAssignedSamples() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/samples/download_assigned');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
    async downloadCsvAllUsersAddress() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/users/download_mail_list');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          this.$store.commit("setMessage", {
            text: 'failed.',
            color: "alert",
          });
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        this.$store.commit("setMessage", {
          text: 'failed.',
          color: "alert",
        });
      }
    },
  }
}
</script>