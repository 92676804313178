<template>
  <v-container grid-list-md>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Proposal Reviewers List</h2>
      <div class="pt-3 pr-2">
        <tr>
          <td>
            <v-select
              label="Term"
              v-model = searchForm.term
              :items = terms
              item-text="label"
              item-value="value"
              color="brown"
              background-color="white"
              flat
              dense
              hide-details
            ></v-select>
          </td>
          <td>
            <v-btn small class="ml-2" dark color="black" @click="proposalSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
          </td>
        </tr>
      </div>
    </div>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <template>
          <div>
            <v-card>
              <v-data-table
                :items="items"
                :headers="headers"
                :page.sync="page"
                hide-default-footer
                no-data-text="No data"
                disable-pagination
              >
                <template v-slot:item="{ item }">
                  <tr v-if="item.status !== 0"><!-- status = 0 （初期状態）の proposal は対象に含めない。 -->
                    <td>
                      <v-btn
                        x-small
                        outlined
                        :to="'/reviewers/detail/' + item.user.id"
                        >DETAIL</v-btn
                      >
                    </td>
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.email }}<br>
                      {{ item.affiliation }}
                    </td>
                    <td>{{ item.title }}</td>
                    <td class="py-3">
                      <span v-for="report in item.reports" :key="report.id">
                        <span style="width:120px;">
                        {{report.id}}: <a href="">{{ report.title }}</a>
                        </span>
                        <span class="ml-4">
                        <b class="red--text text--darken-4" x-small dark v-if="report.status === 0">{{ status[report.status] }}</b>
                        <b class="blue--text text--darken-4" x-small dark v-else-if="report.status === 1">{{ status[report.status] }}</b>
                        <b class="orange--text text--darken-4" x-small dark v-else-if="report.status === 2">{{ status[report.status] }}</b>
                        <b class="green--text text--darken-4" x-small dark v-else-if="report.status === 3">{{ status[report.status] }}</b>
                        </span>
                        : <router-link :to="'/reviewers/detail/' + report.id">{{ report.user.name }}</router-link>
                        <br />
                      </span>
                    </td>
                    <td v-if="item.global_score === null">
                      -
                    </td>
                    <td v-else-if="item.global_score <= 1">
                      <b class="green--text text--darken-4" x-small dark>{{ global_score[1] }}</b>
                    </td>
                    <td v-else-if="1 < item.global_score && item.global_score <= 2">
                      <b class="red--text text--darken-4" x-small dark>{{ global_score[2] }}</b>
                    </td>
                    <td v-else-if="2 < item.global_score && item.global_score <= 3">
                      <b class="gray--text text--darken-4" x-small dark>{{ global_score[3] }}</b>
                    </td>
                    <td v-else-if="3 < item.global_score && item.global_score <= 4">
                      <b class="yellow--text text--darken-4" x-small dark>{{ global_score[4] }}</b>
                    </td>
                    <td v-else-if="4 < item.global_score">
                      <b class="blue--text text--darken-4" x-small dark>{{ global_score[5] }}</b>
                    </td>
                    <td v-else></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
            <div class="text-center pt-5">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @input="emitChangePage()"
                circle
                color="blue darken-4"
              ></v-pagination>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from "../../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      errors: [],
      terms:[],
      status: [],
      reportStatus: [],
      global_score: [],
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        affiliation: "",
        title: "",
        term: null,
        not_submitted: true,
        submitted: true,
        assigned: true,
        reviewed: true,
        scored: true,
      },
      breadcrumbs: [
        {
          text: "Proposal List",
          disabled: false,
          href: "/proposals",
        },
        {
          text: "Proposal Reviewer List",
          disabled: true,
          href: "/proposals/reviewers",
        },
      ],
      //search用
      searchScheme: [
        "term",
        ], //検索条件
      searchURL: "/api/proposals/reviewers", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "12%",
        },
        {
          text: "Email / Affiliation",
          sortable: false,
          value: "email affiliation",
          width: "12%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Reviewers",
          sortable: false,
          value: "reviewers",
          width: "30%",
        },
        {
          text: "Global Score",
          sortable: false,
          value: "global score",
          width: "5%",
        },
      ],
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get("/api/masters/report_status")
        .then((response) => {
          this.reportStatus = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/report_status")
        .then((response) => {
          this.status = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/global_score_list")
        .then((response) => {
          this.global_score = response.data;
      });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    //検索ボタンがクリックされた時
    proposalSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>
