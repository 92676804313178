<template>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showWindow"
    >
      <v-card>
        <v-card-title>Update Global Score</v-card-title>
        <v-card-text>
          <div class="text-subtitle-1 pa-4 grey--text text-center">
            <v-row>
              <v-col md="8">Science Score Average</v-col>
              <v-col md="2">
                {{ showUpdateScoreWindowFields.science_score_average }}
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">Technical Score Average</v-col>
              <v-col md="2">
                {{ showUpdateScoreWindowFields.technical_score_average }}
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">Total Score Average</v-col>
              <v-col md="2">
                {{ showUpdateScoreWindowFields.total_score_average }}
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">Global Score</v-col>
              <v-col md="2">
                {{ globalScore }}
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">New Global Score</v-col>
              <v-col md="3">
              <v-text-field
                solo
                outlined
                flat
                color="black"
                hide-details
                dense
                type="number"
                step="0.01"
                max="5"
                min="0"
                v-model="new_score"
              ></v-text-field>
              <div class="v-text-field__details" v-if="errors['global_score']">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="messages__message">{{ errors['global_score'][0] }}</div>
                  </div>
                </div>
              </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">Rank Average</v-col>
              <v-col md="2">
                {{ showUpdateScoreWindowFields.rank_average }}
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">Rank</v-col>
              <v-col md="2">
                {{ rank }}
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">New Rank</v-col>
              <v-col md="3">
              <v-text-field
                solo
                outlined
                flat
                color="black"
                hide-details
                dense
                type="number"
                step="0.01"
                max="20"
                min="0"
                v-model="new_rank"
              ></v-text-field>
              <div class="v-text-field__details" v-if="errors['rank']">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="messages__message">{{ errors['rank'][0] }}</div>
                  </div>
                </div>
              </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="CloseWindow()">Cancel</v-btn>
          <v-btn @click="UpdateScore()" color="blue darken-4" dark>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'showUpdateScoreWindow',
  props: ['showUpdateScoreWindow', 'showUpdateScoreWindowFields', 'globalScore', 'newScore', 'rank', 'newRank'],
  data() {
    return {
      showWindow: false,
      request: {},
      global_score: 0,
      new_rank: 0,
      new_score: 0,
      errors: {},
    };
  },
  methods: {
    UpdateScore() {
      this.errors = {};
      this.request.global_score = parseFloat(this.new_score);
      this.request.rank = parseFloat(this.new_rank);
      this.$axios
        .put(`/api/proposals/global_score/${this.$route.params.id}`, this.request)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }
          this.showWindow = false;
          this.$emit('close-update-score',this.$route.params.id);
          response;
        })
        .catch((error) => {
          if (error.response.status == '400') {
            this.errors = error.response.data.errors || {};
          } else {
            console.log(error);
          }
        });
    },
    CloseWindow() {
        this.showWindow = false;
        this.$emit('close-update-score', this.showWindow);
    },
    closeDropDown(event) {
      console.log(event);
    },
  },
  watch: {
    globalScore: {
      immediate: true,
      handler(value) {
        this.global_score = value;
      },
    },
    newScore: {
      immediate: true,
      handler(value) {
        this.new_score = value;
      },
    },
    newRank: {
      immediate: true,
      handler(value) {
        this.new_rank = value;
      },
    },
    showUpdateScoreWindow: {
      immediate: true,
      handler(value) {
        this.showWindow = value;
      },
    },
  }
};
</script>