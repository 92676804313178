<template>
  <v-card flat>
    <v-card-text>
      <v-row justify="center" class="my-8">
        <v-col md="8">
          <p class="nl2br" v-text="fields.additional_notes" />
        </v-col>
      </v-row>
      <div class="text-center my-6"
        v-if="
          this.$store.state.auth.authrole.includes('admin')
        ">
        <v-btn class="mr-4" color="blue darken-4" dark @click="clickEditNote()">
          <v-icon class="mr-2">mdi-pencil</v-icon>
          Edit additional note
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'tabAssignedSample',
  props: ['fields'],
  data() {
    return {
      showWindow: false,
    };
  },
  methods: {
    clickEditNote() {
        this.showWindow = true;
        this.$emit('click-edit-note', this.showWindow);
    }
  }
};
</script>
