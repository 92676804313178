<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/group-mail" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <v-btn :to="'/group-mail/detail/' + fields.prevID" exact outlined v-if="fields.prevID">
              <v-icon>
                mdi-skip-previous
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
            {{ fields.title }}
            <v-btn :to="'/group-mail/detail/' + fields.nextID" exact outlined v-if="fields.nextID">
              <v-icon>
                mdi-skip-next
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Title</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.title }}
                  </td>
                </tr>
                <tr>
                  <th width="20%">Content</th>
                  <td class="text-subtitle-1" colspan="3">
                    <p class="nl2br" v-text="fields.content" />
                  </td>
                </tr>
                <tr>
                  <th width="20%">Bcc</th>
                  <td class="text-subtitle-1" colspan="3">
                    {{ fields.bcc ? fields.bcc : 'none' }}
                  </td>
                </tr>
                <tr>
                  <th>Reply To Address</th>
                  <td class="text-subtitle-1">
                    {{ fields.reply_to_address ? fields.reply_to_address : 'none' }}
                  </td>
                  <th>Reply To Name</th>
                  <td class="text-subtitle-1">
                    {{ fields.reply_to_name ? fields.reply_to_name : 'none' }}
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                    <td class="text-subtitle-1">
                      <div v-if="fields.transmission_status == 0" class="red--text font-weight-bold">Unsent</div>
                      <div v-else-if="fields.transmission_status == 1" class="indigo--text">Sent</div>
                    </td>
                  <th>Transmission Date</th>
                  <td>
                    {{ fields.transmission_date ? fields.transmission_date : 'none' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col md="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Destinations
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Affiliation</th>
                  <th>Admin</th>
                  <th>Panel</th>
                  <th>Reviewer</th>
                  <th>Proposer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user,index) in fields.users" :key="user.id">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ user.name }}
                  </td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td>
                    {{ user.affiliation }}
                  </td>
                  <td class="text-center"><span v-if="user.is_administration_office">x</span></td>
                  <td class="text-center"><span v-if="user.is_panel">x</span></td>
                  <td class="text-center"><span v-if="user.is_reviewer">x</span></td>
                  <td class="text-center"><span v-if="user.is_proposer">x</span></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

        <div v-if="fields.transmission_status === 0">
          <div class="text-center mt-5">
            <v-btn 
              class="font-weight-bold" 
              outlined 
              :to="'/group-mail/edit/' + fields.id"
            >
              <v-icon left>mdi-pen</v-icon>
              Edit
            </v-btn>
            <v-btn 
              class="font-weight-bold ml-4" 
              color="green darken-1" 
              outlined 
              @click="showMailDialog = true"
            >
              <v-icon left>mdi-email-fast</v-icon>
              Send
            </v-btn>
          </div>
          <div class="text-right pr-6">
            <v-btn 
              class="font-weight-bold" 
              color="red darken-1" 
              outlined 
              @click="showDeleteDialog = true"
            >
              <v-icon left>mdi-trash-can</v-icon>
              Delete
            </v-btn>
          </div>
        </div>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showMailDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you send this group mail?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showMailDialog = false">Cancel</v-btn>
          <v-btn @click="sendGroupMail(fields.id)" color="black" dark>Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDeleteDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you delete this group mail?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn @click="deleteGroupMail(fields.id)" color="black" dark>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      fields: {},
      users: [],
      showDeleteDialog: false,
      showMailDialog: false,
      breadcrumbs: [
        {
          text: "Group Mail List",
          disabled: false,
          href: "/group-mail/",
        },
        {
          text: "Group Mail Detail",
          disabled: true,
          href: "/group-mail/detail",
        },
      ],
    };
  },
  mounted() {
    this.getGroupMailData(this.$route.params.id);
    (async () => {
      this.$axios
        .get("/api/users/all")
        .then((response) => {
          response.data.forEach(e => {
            this.users[e['id']] = e['name'] + ' / ' + e['email'];
          });
      });
    })();
  },
  methods: {
    getGroupMailData(id) {
      this.$axios
      .get(`/api/group_mail/${id}`)
      .then(response => {
        this.fields = response.data;
      });
    },
    deleteGroupMail(id) {
      this.$axios
        .put(`/api/group_mail/delete/${id}`)
        .then((response) => {
          response;
          if(response.data.result === true){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"success",
            });
            this.$router.go(-1)
          }else{
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"alert",
            });
          }
      });
    },
    sendGroupMail(id) {
      this.loading = true;
      this.$axios
        .post(`/api/group_mail/send/${id}`)
        .then((response) => {
          if(response.data.result === true){
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"success",
            });
            this.loading = false;
            this.showMailDialog = false;
            window.location.reload(); //送信日時を更新
          }else{
            this.$store.commit("setMessage",{
              text:response.data.message,
              color:"alert",
            });
          }
      });
    },
  },
  watch: {
    '$route' (to) {
      this.getGroupMailData(to.params.id)
    },
  },
};
</script>