<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/proposals/myProposal" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4" v-if="this.fields.id">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Term</th>
                  <td class="text-h6">{{ terms[fields.term_id] }}</td>
                </tr>
                <tr>
                  <th width="20%">Title</th>
                  <td class="text-h6">{{ fields.title }}</td>
                </tr>
                <tr>
                  <th width="20%">Name</th>
                  <td class="text-h6">{{ fields.name }}</td>
                </tr>
                <tr>
                  <th>Affiliation</th>
                  <td class="text-subtitle-1">{{ fields.affiliation }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td class="text-subtitle-1">{{ fields.email }}</td>
                </tr>
                <tr>
                  <th width="20%">Early career</th>
                  <td class="text-subtitle-1">{{ earlyCareerScientists[fields.early_career] }}</td>
                </tr>
                <tr>
                  <th width="20%">Collaborator</th>
                  <td>
                    <div v-for="item in fields.proposal_collaborator" :key="item.id">
                      {{item.name}} / {{ item.affiliation }} ({{ item.email }})<br>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="text-subtitle-1">
                    <div v-if="fields.status == 0">
                      -
                    </div>
                    <div v-else-if="fields.status == 1">
                      Not Submitted
                    </div>
                    <div v-else>
                      Submitted
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4" justify="center" align="center" v-if="this.fields.id">
      <v-col md="10">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="blue lighten-5"
            color="blue darken-3"
            grow
            slider-size="4"
          >
            <v-tab> Proposal </v-tab>

            <v-tab :disabled="sampleLoading"> Sample </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <tabDetail :fields="fields" :user_type="user_type" />
            </v-tab-item>
            <v-tab-item>
              <tabAssignedSample :fields="fields" :sample_lists="sample_lists" :sampleDBUrl="sampleDBUrl" :requestedSampleTotal="requestedSampleTotal" :assignedSampleTotal="assignedSampleTotal" :sampleTypes="sampleTypes" :chambers="chambers" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tabDetail from "../detail/tabDetail";
import tabAssignedSample from "./tabAssignedSample";
import moment from "moment";
export default {
  components: { tabDetail, tabAssignedSample },
  data() {
    return {
      loading: false,
      sampleLoading: true,
      user_type: "proposer",
      tab: null,
      sample_lists: [],
      fields: {
        file: {
          original_file_name: '',
        },
      },
      sampleDBUrl:"",
      requestedSampleTotal: 0,
      assignedSampleTotal: 0,
      earlyCareerScientists: {},
      sampleTypes: [],
      chambers: [],
      terms: [],
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/mypage",
        },
        {
          text: "MyProposal",
          disabled: false,
          href: "/proposals/myProposal",
        },
        {
          text: "Proposals from the past",
          disabled: true,
        },
      ],
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/masters/formArray/early_career_type")
        .then((response) => {
          this.earlyCareerScientists = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/sample_type")
        .then((response) => {
          this.sampleTypes = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/ChamberList")
        .then((response) => {
          this.chambers = response.data;
      });
      this.$axios
        .get(`/api/masters/getAllTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms[t] = t;
          }
        });
      this.$axios
        .get(`/api/proposals/myOldProposal/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;
          let totalR = 0;
          let totalA = 0;
          for (let i in response.data.requested_samples) {
            if (response.data.requested_samples[i].sample_type === 0
              || response.data.requested_samples[i].sample_type === 1) {
              if (!isNaN(response.data.requested_samples[i].weight) && response.data.requested_samples[i].weight) {
                totalR += parseFloat(response.data.requested_samples[i].weight);
              }
            }
          }
          for (let i in response.data.assigned_samples_status_true) {
            totalA += parseFloat(response.data.assigned_samples_status_true[i].weight);
          }
          this.requestedSampleTotal = (Math.floor(totalR* 10))/10;
          this.assignedSampleTotal = (Math.floor(totalA* 10))/10;
        });
      this.$axios
        .get(`/api/masters/getAllSamples`)
        .then(response => {
          for(let kw in response.data) {
            this.sample_lists[response.data[kw].id] = response.data[kw];
          }
          this.sampleLoading = false;
        });
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then((response) => {
          this.sampleDBUrl = response.data;
        });
    })();
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
};
</script>
