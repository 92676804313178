<template>
  <v-container grid-list-md>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <h2 class="py-2 px-6">Assigned Sample List</h2>
    <v-divider></v-divider>
    <div class="d-flex justify-space-between mt-5 px-6">
      <v-col md="2">
        <v-select
          label="Term"
          v-model = searchForm.term
          :items = terms
          item-text="label"
          item-value="value"
          color="brown"
          background-color="white"
          flat
          dense
          hide-details
          @change="changeTerm"
        ></v-select>
      </v-col>
      <v-btn color="deep-orange darken-1 mt-5" dark @click="downLoad"
        ><v-icon>mdi-download</v-icon>Download CSV</v-btn
      >
    </div>

      <v-row justify="center" align="center" class="mt-2 mb-5 px-6">
        <v-col lg="12">
          <template>
            <div>
              <v-card>
                <v-data-table                
                  :items="sample_lists"
                  :headers="headers"
                  hide-default-footer
                  no-data-text="No data"
                  disable-pagination
                >
                  <template v-slot:item="{ item }">
                    <tr v-if="(searchForm.term == '' && item.term.disabled == 0) || searchForm.term == item.term.id">
                      <td>{{ item.name }}</td>
                      <td>{{ sampleTypes[item.type] }}</td>
                      <td>{{ item.weight }}</td>
                      <td>{{ item.size }}</td>
                      <td>{{ item.pressure }}</td>
                      <td>{{ item.volume }}</td>
                      <td><a :href="sampleDBUrl + item.id" target="_blank">>>detail</a></td>
                      <td>
                          <a v-if="(searchForm.term == '' && item.term.disabled == 0) || searchForm.term == item.term.id" :href="'/proposals/detail/' + item.proposal_id">{{ item.proposal_id}} : {{ item.proposal_title }}</a>
                      </td>
                      <td>
                        {{ item.status }}
                      </td>
                      <td>
                        <v-btn
                          v-if="item.term.disabled == 0 && item.status_num == 1 && searchForm.term == item.term.id" 
                          @click="showCancelDialog = true,buttonStatus = true,cancelId = item.main_key"
                          color="error"
                          x-small
                          class="mx-1"
                        >
                          CANCEL
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </template>
        </v-col>
      </v-row>
      <div v-if="this.showConfirmBtn && this.$store.state.auth.authrole.includes('admin')" class="text-center my-6">
        <v-btn class="font-weight-bold mr-4" color="orange darken-4" large dark @click="showDialog = true,buttonStatus = true" :disabled="buttonStatus">
          Confirm
        </v-btn>
      </div>
      <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Are you sure you want to confirm these assignments?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false,buttonStatus = false">NO</v-btn>
          <v-btn @click="confirm()" color="black" dark>YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showCancelDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to cancel this confirmation?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showCancelDialog = false,buttonStatus = false">NO</v-btn>
          <v-btn @click="cancel(cancelId)" color="black" dark>YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sampleLoading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Loading samples...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      showConfirmBtn: true,
      loading: false,
      sampleLoading: true,
      buttonStatus: false,
      cancelConfirm: false,
      cancelId: null,
      errors: [],
      sample_lists : [],
      sampleData: [],
      sampleTypes: [],
      proposals: [],
      requests: [],
      sampleDBUrl: null,
      showCancelDialog: false,
      terms: [],
      searchForm: {
        term: '',
      },
      breadcrumbs: [
        {
          text: "Sample List",
          disabled: false,
          href: "/samples",
        },
        {
          text: "Assigned Sample List",
          disabled: true,
          href: "/samples/assigned",
        },
      ],
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "10%",
        },
        {
          text: "Type",
          sortable: false,
          value: "type",
          width: "10%",
        },
        {
          text: "Weight[mg]",
          sortable: false,
          value: "weight",
          width: "10%",
        },
        {
          text: "Size[mm]",
          sortable: false,
          value: "size",
          width: "10%",
        },
        {
          text: "Pressure[Pa]",
          sortable: false,
          value: "pressure",
          width: "10%",
        },
        {
          text: "Volume[ml]",
          sortable: false,
          value: "volume",
          width: "10%",
        },
        {
            text: "",
            sortable: false,
            width: "10%",
        },
        {
          text: "Assigned",
          sortable: false,
          value: "assigned",
          width: "50%",
        },
        {
          text: "Confirmed",
          sortable: false,
          value: "status",
          width: "5%",
        },
        {
          text: "",
          sortable: false,
          value: "cancel",
          width: "5%",
        },
      ],
    }
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = String(response.data.id);
        });
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then((response) => {
          this.sampleDBUrl = response.data;
      });
      this.$axios
        .get(`/api/samples/assigned`)
        .then(response => {
          this.sampleData = response.data;
          for(let kw in response.data) {
            var status = (response.data[kw].status == 1) ? "x": "";
            this.sample_lists.push({
              main_key: response.data[kw].main_key,
              id: response.data[kw].id,
              type: response.data[kw].type,
              name: response.data[kw].name,
              weight: response.data[kw].weight,
              size: !isNaN(response.data[kw].size) ? (response.data[kw].size / 1000).toFixed(3) : response.data[kw].size,
              pressure: response.data[kw].pressure,
              volume: response.data[kw].volume,
              proposal_id: response.data[kw].proposal_id,
              proposal_title: response.data[kw].proposal_title,
              status_num: response.data[kw].status,
              status: status,
              term: response.data[kw].term,
            });
          }
          this.sampleLoading = false;
        });
      this.$axios
      .get(`/api/samples/allocation/0`)
      .then(response => {
        for(let kw in response.data) {
          this.requests.push({
            id: response.data[kw].id, 
            name: response.data[kw].name,
            weight: response.data[kw].weight,
            size: (response.data[kw].size / 1000).toFixed(3),
            data: response.data[kw].requested,
            assigned: response.data[kw].assigned,
          });
        }
      });
      this.$axios
        .get(`/api/masters/getAllTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get("/api/masters/formArray/sample_type")
        .then((response) => {
          this.sampleTypes = response.data;
      });
    })();
  },
  filters: {
    sizeMM(value) {
      const size = value / 1000;
      return size.toFixed(3);
    }
  },
  methods: {
    async downLoad(){
      try {
        const res = await this.$axios.get('/api/samples/download_assigned', {
          params: {
            term_id: this.searchForm.term,
          }
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
    changeTerm(){
      if (this.searchForm.term == '') {
        this.showConfirmBtn = true;
        return '';
      }
      for(let key in this.sample_lists) {
          if (this.sample_lists[key].term.id == this.searchForm.term && this.sample_lists[key].term.disabled) {
            this.showConfirmBtn = false;
            return '';
          }
          this.showConfirmBtn = true;
      }
    },
    confirm() {
      this.loading = true;
      var assigned_proposals = [];
      for(let key in this.requests) {
        if (this.requests[key].assigned != null) {
          assigned_proposals.push({
            'sample_id':this.requests[key].id,
            'proposal_id': this.requests[key].assigned
          });
        }
      }
      this.$axios
        .post(`/api/samples/assign`,assigned_proposals)
        .then((response) => {
          if (response.data.result == true) {
            this.showDialog = false;
            this.loading = false;
            this.buttonStatus = false;
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }
          window.location.reload()
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.showDialog = false;
            this.loading = false;
            this.buttonStatus = false;
            this.$store.commit("setMessage", {
              text: this.errors,
              color: "error",
            });
          }
        });
    },
    cancel(id) {
      this.loading = true;
      this.$axios
        .put(`/api/samples/cancel_assign/${id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            })
            this.showCancelDialog = false;
            this.loading = false;
            this.buttonStatus = false;
            window.location.reload()
          }else{
            this.$store.commit("setMessage", {
            text: response.data.message,
            color: "alert",
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessage", {
              text: this.errors,
              color: "error",
            });
          }
        });
      this.showCancelDialog = false;
      this.loading = false;
      this.buttonStatus = false;
    }
  }
}
</script>