import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Forbidden from "../pages/Forbidden";
import Password from "../pages/Password";
import PasswordRequest from "../pages/Password/Request";
import PasswordReset from "../pages/Password/Reset";

import Home from "../pages/Home.vue";
import Dashboard from "../pages/Dashboard";
import ProposerDashboard from "../pages/Dashboard/Proposer";
import Proposers from "../pages/Proposers";
import ProposerCollaborators from "../pages/Proposers/Collaborators";
import ProposersDetail from "../pages/Proposers/detail";
import Applicant from "../pages/Applicant";
import ApplicantDetail from "../pages/Applicant/detail";
import ApplicantEdit from "../pages/Applicant/edit";
import CreateApplication from "../pages/Applicant/CreateApplication/index";
import ConfirmApplication from "../pages/Applicant/CreateApplication/Confirm";
import Proposals from "../pages/Proposals";
import ProposalReviewers from "../pages/Proposals/reviewers";
import ProposalAssignedSamples from "../pages/Proposals/assignedSample";
import ProposalsDetail from "../pages/Proposals/detail";
import ProposalEdit from "../pages/Proposals/edit";
import MyProposal from "../pages/Proposals/myproposal";
import MyOldProposal from "../pages/Proposals/myOldProposal";
import MyProfile from "../pages/Proposers/myprofile";
import Reviewers from "../pages/Reviewers";
import ReviewersDetail from "../pages/Reviewers/detail";
import ReportsDetail from "../pages/Reports/detail";
import ReportsEdit from "../pages/Reports/edit";
import AssignedProposals from "../pages/Proposals/assignedProposals";
import Users from "../pages/Users";
import UserDetail from "../pages/Users/detail";
import UserEdit from "../pages/Users/Edit";
import UserAdd from "../pages/Users/Add";
import Information from "../pages/Information";
import InformationAdd from "../pages/Information/Add";
import InformationEdit from "../pages/Information/Edit";
import InformationShow from "../pages/Information/Show";
import MasterList from "../pages/Masters";
import KeywordsMaster from "../pages/Masters/Keywords";
import KeywordAdd from "../pages/Masters/Keywords/Add";
import KeywordEdit from "../pages/Masters/Keywords/Edit";
import MethodsMaster from "../pages/Masters/Methods";
import MethodsAdd from "../pages/Masters/Methods/Add";
import MethodsEdit from "../pages/Masters/Methods/Edit";
import SampleList from "../pages/Samples/indexCurrent";
import SampleAssign from "../pages/Samples/Assign";
import SampleAssignParticle from "../pages/Samples/Assign/particle";
import SampleAssignGas from "../pages/Samples/Assign/gas";
import SampleAssignProcessed from "../pages/Samples/Assign/processed";
import SampleAssigned from "../pages/Samples/Assigned";
import GroupMail from "../pages/GroupMail";
import GroupMailDetail from "../pages/GroupMail/Detail";
import GroupMailAdd from "../pages/GroupMail/Add";
import GroupMailEdit from "../pages/GroupMail/Edit";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: Login,
    props: true,
  },
  {
    name: "NotFound",
    path: "*",
    component: NotFound,
    meta: { isPublic: true },
  },
  {
    name: "Forbidden",
    path: "/403",
    component: Forbidden,
    meta: { isPublic: true },
  },
  {
    name: "Password",
    path: "/password",
    component: Password,
    meta: {
      role: ["admin", "panel", "reviewer", "proposer"],
    },
  },
  {
    name: "PasswordRequest",
    path: "/request",
    component: PasswordRequest,
    meta: { isPublic: true },
  },
  {
    name: "PasswordReset",
    path: "/password/reset/:token",
    component: PasswordReset,
    meta: { isPublic: true },
  },
  {
    path: "",
    component: Home,
    props: true,
    children: [
      {
        name: "ProposerHome",
        path: "/mypage",
        component: ProposerDashboard,
        meta: {
          role: ["proposer"],
        },
      },
      {
        name: "Home",
        path: "/",
        component: Dashboard,
        meta: {
          role: ["admin", "panel", "reviewer"],
        },
      },
      {
        name: "Proposers",
        path: "/proposers",
        component: Proposers,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "Applicant",
        path: "/proposers/applicant",
        component: Applicant,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "ApplicantDetail",
        path: "/proposers/applicant/detail/:id",
        component: ApplicantDetail,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "ApplicantEdit",
        path: "/proposers/applicant/edit/:id",
        component: ApplicantEdit,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "CreateApplication",
        path: "/applicants/create/",
        component: CreateApplication,
      },
      {
        name: "ConfirmApplication",
        path: "/applicants/confirm",
        component: ConfirmApplication,
      },
      {
        name: "ProposerCollaborators",
        path: "/proposers/collaborators",
        component: ProposerCollaborators,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ProposersDetail",
        path: "/proposers/detail/:id",
        component: ProposersDetail,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "Proposals",
        path: "/proposals",
        component: Proposals,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ProposalReviewers",
        path: "/proposals/reviewers",
        component: ProposalReviewers,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ProposalAssignedSamples",
        path: "/proposals/assigned_samples",
        component: ProposalAssignedSamples,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ProposalsDetail",
        path: "/proposals/detail/:id",
        component: ProposalsDetail,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ProposalEdit",
        path: "/proposals/edit/:id",
        component: ProposalEdit,
        meta: {
          role: ["proposer"],
        },
      },
      {
        name: "MyProposal",
        path: "/proposals/myproposal",
        component: MyProposal,
        meta: {
          role: ["proposer"],
        },
      },
      {
        name: "MyOldProposal",
        path: "/proposals/oldProposal/:id",
        component: MyOldProposal,
        meta: {
          role: ["proposer"],
        },
      },
      {
        name: "Reviewers",
        path: "/reviewers",
        component: Reviewers,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ReviewersDetail",
        path: "/reviewers/detail/:id",
        component: ReviewersDetail,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "ReportsDetail",
        path: "/reports/detail/:id",
        component: ReportsDetail,
        meta: {
          role: ["admin", "panel", "reviewer"],
        },
      },
      {
        name: "ReportsEdit",
        path: "/reports/edit/:id",
        component: ReportsEdit,
        meta: {
          role: ["admin", "panel", "reviewer"],
        },
      },
      {
        name: "AssignedProposals",
        path: "/proposals/assigned_proposals",
        component: AssignedProposals,
        meta: {
          role: ["reviewer"],
        },
      },
      {
        name: "MyProfile",
        path: "/myprofile",
        component: MyProfile,
        meta: {
          role: ["proposer"],
        },
      },
      {
        name: "Users",
        path: "/users",
        component: Users,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "UserDetail",
        path: "/users/detail/:id",
        component: UserDetail,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "UserEdit",
        path: "/users/edit/:id",
        component: UserEdit,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "UserAdd",
        path: "/users/add",
        component: UserAdd,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "Information",
        path: "/information",
        component: Information,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "InformationAdd",
        path: "/information/add",
        component: InformationAdd,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "InformationEdit",
        path: "/information/edit/:id",
        component: InformationEdit,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "InformationShow",
        path: "/information/show/:id",
        component: InformationShow,
        meta: {
          role: ["admin", "panel", "reviewer", "proposer"],
        },
      },
      {
        name: "Masters",
        path: "/masters",
        component: MasterList,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "KeywordsMaster",
        path: "/masters/keywords",
        component: KeywordsMaster,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "KeywordAdd",
        path: "/masters/keywords/add",
        component: KeywordAdd,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "KeywordEdit",
        path: "/masters/keywords/edit/:id",
        component: KeywordEdit,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "MethodsMaster",
        path: "/masters/methods",
        component: MethodsMaster,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "MethodsAdd",
        path: "/masters/methods/add",
        component: MethodsAdd,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "MethodsEdit",
        path: "/masters/methods/edit/:id",
        component: MethodsEdit,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "Samples",
        path: "/samples",
        component: SampleList,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "SampleAssign",
        path: "/samples/assign",
        component: SampleAssign,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "SampleAssignParticle",
        path: "/samples/assign/particle",
        component: SampleAssignParticle,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "SampleAssignGas",
        path: "/samples/assign/gas",
        component: SampleAssignGas,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "SampleAssignProcessed",
        path: "/samples/assign/processed",
        component: SampleAssignProcessed,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "SampleAssigned",
        path: "/samples/assigned",
        component: SampleAssigned,
        meta: {
          role: ["admin", "panel"],
        },
      },
      {
        name: "GroupMail",
        path: "/group-mail",
        component: GroupMail,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "GroupMailDetail",
        path: "/group-mail/detail/:id",
        component: GroupMailDetail,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "GroupMailAdd",
        path: "/group-mail/add/",
        component: GroupMailAdd,
        meta: {
          role: ["admin"],
        },
      },
      {
        name: "GroupMailEdit",
        path: "/group-mail/edit/:id",
        component: GroupMailEdit,
        meta: {
          role: ["admin", "panel"],
        },
      },
      //{
      //  name: "ConditionsMaster",
      //  path: "/masters/conditions",
      //  component: ConditionsMaster,
      //  meta: {
      //    role: ["admin"],
      //  },
      //},
      //{
      //  name: "ConditionsAdd",
      //  path: "/masters/conditions/add",
      //  component: ConditionsAdd,
      //  meta: {
      //    role: ["admin"],
      //  },
      //},
      //{
      //  name: "ConditionsEdit",
      //  path: "/masters/conditions/edit",
      //  component: ConditionsEdit,
      //  meta: {
      //    role: ["admin"],
      //  },
      //},
    ],
  },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: "history",
    routes,
});
import { checkAuth, checkAuthByRole } from "../mixins/Auth.js";
router.beforeEach((to, from, next) => {
  //isPublicがtrueの場合には必ずアクセス可能
  //各ルートに meta: { isPublic: true } を追加します。
  if (to.matched.some(page => page.meta.isPublic)) {
    next();
  } else {
    //async/awaitでそれぞれのログイン状況を取得する
    (async () => {
      let Auth = await checkAuth();
      //ログイン済
      if (Auth) {
        let is_next = true;
        //(種別)
        if (to.meta.role) {
          is_next = false;
          let AuthRole = await checkAuthByRole(to.meta.role);
          if (AuthRole) {
            is_next = true;
          }
        }

        //権限あり
        if (is_next) {
          next();
        } else {
          if (to.path == '/') {
            next({ path: "/login" });
          } else {
            next({ path: "/denied" });
          }
        }
      }
      //ログインしていない
      else {
        if (to.path.indexOf("/auth") === 0 || to.path.indexOf("/login") === 0) {
          next();
        } else {
          next({ path: "/login" });
        }
      }
    })();
  }
});

export default router;