//import axios from "axios";

export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 0,
            total: 0,
            items: []
        }
    },
    methods: {
        //pageと検索条件からAPIリクエストを実行
        async search(params, onLoad = false) {
            //保存された検索条件の削除
            if (params.clear !== undefined) {
                this.resetParams();
            }
            //検索条件の復元
            //onLoad = trueの時のみ復元する。
            if (onLoad && this.getRestoreParams() !== null) {
                params = this.getRestoreParams();
            }
            await this.$axios
                .get(this.searchURL, { params })
                .then((response) => {
                    //ページング用メタ情報
                    //全件数
                    this.total = response.data.total;
                    //ページ数
                    this.pageCount = response.data.last_page;
                    //1ページあたりの件数
                    this.itemsPerPage = response.data.per_page;
                    //データ
                    this.items = response.data.data;
                    //現在のページを保持する
                    this.page = response.data.current_page;

                    //URLを変更
                    this.$router.push({
                        query: params,
                    }).catch(err => { err });
                    //ページTOPへ
                    if (params.scrollFlg == true) {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }
                    //検索の値を復元
                    for (const searchKey of this.searchScheme) {
                        if (params[searchKey] !== undefined) {
                            this.searchForm[searchKey] = params[searchKey];
                        }
                    }
                    this.saveParams(params);
                });
        },
        //ページングがクリックされた時
        changePage(value) {
            this.page = value.page;
            this.pageCount = value.pageCount;
            this.itemsPerPage = value.itemsPerPage;
            let query = this.getSearchBase();
            query.page = value.page;
            this.search(query);
        },
        //検索条件のベースを返す
        getSearchBase() {
            let data = { page: 1 };
            for (const searchKey of this.searchScheme) {
                if (this.searchForm[searchKey]) {
                    data[searchKey] = this.searchForm[searchKey];
                }
            }
            return data;
        },
        saveParams(params) {
            //検索条件の保存
            sessionStorage.setItem(this.getStorageKey(), JSON.stringify(params));
        },
        resetParams() {
            sessionStorage.removeItem(this.getStorageKey());
        },
        getRestoreParams() {
            return JSON.parse(sessionStorage.getItem(this.getStorageKey()));
        },
        getStorageKey() {
            return location.pathname.replace(/\/$/, "");
        },
    },
};