<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Group Mail List</h2>
      <div class="pt-2 pr-2">
        <v-btn
          dark
          color="blue darken-4"
          class="mr-3"
          :to="'/group-mail/add'"
          ><v-icon class="mr-2">mdi-account-plus</v-icon>ADD</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="10">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Keyword"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.keyword
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="groupMailSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
              v-bind:href="'/group-mail?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <div class="d-flex align-end mb-5">
          <div class="text-caption">
            {{total}} results
          </div>
        </div>
        <div>
          <v-card>
            <v-data-table
              :items="items"
              :headers="headers"
              :page.sync="page"
              hide-default-footer
              no-data-text="No data"
              disable-pagination
            >
            <template v-slot:item="{ item }">
                <tr v-bind:class="{ disabled: item.disabled }">
                  <td>
                    <v-btn
                      x-small
                      outlined
                      :to="'/group-mail/detail/' + item.id"
                      >DETAIL</v-btn
                    >
                  </td>
                  <td v-if="item.title">{{ item.title | omitTitle}}</td>
                  <td v-else></td>
                  <td v-if="item.content">{{ item.content | omitContent }}</td>
                  <td v-else></td>
                  <td>{{ users[item.sender_id] ?  users[item.sender_id] : ''}}</td>
                  <td v-if="item.users[0]">
                    <span v-for="(value, key) in item.users" :key="key">
                      {{ key + 1 }}: {{ value.name ? value.name : '' }} / {{ value.email ? value.email : ''}}<br>
                      <span v-if="(key + 1) !== item.users.length">
                        <br>
                      </span>
                    </span>
                  </td>
                  <td v-else></td>
                  <td v-if="item.transmission_status === 0">unsent</td>
                  <td v-else-if="item.transmission_status === 1">sent</td>
                  <td v-else> '' </td>
                  <td>{{ item.transmission_date ? item.transmission_date : ''}}</td>
                </tr>
            </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="emitChangePage()"
              circle
              color="blue darken-4"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      name: null,
      buttonStatus: false,
      addDialog: false,
      errors: [],
      items:[],
      status:[],
      users:[],
      searchForm: {
        //検索のデフォルト値
        keyword: '',
        email: '',
      },
      searchScheme: [
        "keyword",
        "email",
      ], //検索条件
      searchURL: "/api/group_mail",
      headers: [
        {
            text: "",
            sortable: false,
            width: "5%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Content",
          sortable: false,
          value: "content",
          width: "30%",
        },
        {
          text: "Sender",
          sortable: false,
          value: "sender",
          width: "15%",
        },
        {
          text: "Destination",
          sortable: false,
          value: "destination",
          width: "40%",
        },
        {
          text: "Transmission Status",
          sortable: false,
          value: "transmission_status",
          width: "5%",
        },
        {
          text: "Transmission Date",
          sortable: false,
          value: "transmission_date",
          width: "5%",
        },
      ],
      isActive: false,
      showDialog: false,
      loading: false,
      showButton: true,
    };
  },
  filters: {
    omitTitle(title) {
      const words = title.split(/[\s]/);
      let trimmedTitle = '';
      if(words.length > 5){
        for(let i = 0; i < 5; i++){
          trimmedTitle += words[i] + ' ';
        }
        trimmedTitle += "...";
      }else{
        words.forEach(value => {
          trimmedTitle += value + ' ';
        });
      }
      return trimmedTitle;
    },
    omitContent(content) {
      const words = content.split(/[\s]/);
      let trimmedContent = '';
      if(words.length > 10){
        for(let i = 0; i < 10; i++){
          trimmedContent += words[i] + ' ';
        }
        trimmedContent += "...";
      }else{
        words.forEach(value => {
          trimmedContent += value + ' ';
        });
      }
      return trimmedContent;
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/users/all")
        .then((response) => {
          response.data.forEach(e => {
            this.users[e['id']] = e['name'] + ' / ' + e['email'];
          });
      });
      this.list(false);
    })();
  },
  methods: {
    list(scrollFlg) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtrueを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    changeStatus(status){
      this.searchForm.status = status;
    },
    //検索ボタンがクリックされた時
    groupMailSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
  },
  watch: {
    loading(val) {
      if (!val) return;

      //setTimeout(() => ((this.loading = false), (this.add = true)), 1000);
    },
  },
};
</script>