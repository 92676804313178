<template>
  <v-app>
    <v-container fluid class="login">
      <v-row justify="center" align="center">
        <v-col xs="10" sm="6" md="6" lg="4">
          <v-card color="grey lighten-5">
            <v-toolbar color="grey lighten-2" flat>
              <h4 class="mx-auto">
                Password Reset Request
              </h4>
            </v-toolbar>
            <v-divider> </v-divider>
            <div class="pa-6">
              <v-alert v-if="errorMessageShow" border="top" type="error" dark>{{
                errorMessage
              }}</v-alert>
              <v-alert v-if="successMessageShow" border="top" type="success" dark>{{
                successMessage
              }}</v-alert>
              <v-text-field
                v-model="email"
                :rules="[emailRules.required]"
                outlined
                label="E-mail"
                class="mb-3"
                color="black"
              ></v-text-field>
            </div>

            <div class="pa-6 pt-0">
              <div class="text-center">
                <v-btn
                  class="font-weight-bold mr-4"
                  color="blue darken-4"
                  dark
                  large
                  @click="request"
                >
                  Request
                </v-btn>
                <v-btn
                  class="font-weight-bold"
                  color="grey lighten-2"
                  large
                  to="/login"
                >
                  Cancel
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      emailRules: {
        required: (value) => !!value || "Please enter your email address",
      },
      errorMessage: "",
      errorMessageShow: false,
      successMessage: "",
      successMessageShow: false,
    };
  },
  methods: {
    request() {
      this.$axios.post('/api/password/request', {
        email: this.email
        }, {

      })
      .then(response => {
        response;
        this.errorMessage = '';
        this.errorMessageShow = false;
        this.successMessage = 'I sent a password reset email.';
        this.successMessageShow = true;
      })
      .catch(error => {
        console.log(error);
        this.errorMessage = 'The email address is not registered.';
        this.errorMessageShow = true;
        this.successMessage = '';
        this.successMessageShow = false;
      });
    },
  },
};
</script>

<style lang="scss">
.login_title {
  width: 100%;
}
.login {
  height: 100%;
  .row {
    height: 100%;
  }
}
</style>
