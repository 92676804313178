<template>
  <v-list nav dense>
    <!-- Dashboard:admin,panel,reviewer -->
    <v-list-item
        to="/"
        key="Dashboard"
        exact
        v-if="
          this.$store.state.auth.authrole.includes('admin')
          || this.$store.state.auth.authrole.includes('panel')
          || this.$store.state.auth.authrole.includes('reviewer')
        "
    >
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Dashboard
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Dashboard:All -->
    <v-list-item
        to="/mypage"
        key="MyPage"
        exact
        v-if="
          this.$store.state.auth.authrole.includes('proposer')
        "
    >
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Home
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Applicant:admin -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
        "
        to="/proposers/applicant"
        key="Applicant"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-account-box-multiple-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Applicant
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Proposer:admin & panel -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
          || this.$store.state.auth.authrole.includes('panel')
        "
        to="/proposers"
        key="Proposer"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-account-box-multiple</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Proposer
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Proposal(all):admin & panel -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
          || this.$store.state.auth.authrole.includes('panel')
        "
        to="/proposals"
        key="Proposal"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-layers</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Proposal
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Proposal(assigned):reviewer -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('reviewer')
        "
        to="/proposals/assigned_proposals"
        key="Assigned Proposals"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-layers-edit</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Assigned Proposals
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- My Proposal:proposer -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('proposer')
        "
        to="/proposals/myproposal"
        key="MyProposal"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-layers-edit</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          MyProposal
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- My Profile:proposer -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('proposer')
        "
        to="/myprofile"
        key="MyProfile"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-layers-edit</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          MyProfile
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Reviewer:admin & panel -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
          || this.$store.state.auth.authrole.includes('panel')
        "
        to="/reviewers"
        key="Reviewer"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-account-multiple-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Reviewer
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Sample:admin & panel -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
          || this.$store.state.auth.authrole.includes('panel')
        "
        to="/samples"
        key="Sample"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-scatter-plot</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Sample
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- User:admin -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
        "
        to="/users"
        key="User"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          User
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Information:admin -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
        "
        to="/information"
        key="Information"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-newspaper</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Information
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Master:admin -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
        "
        to="/masters"
        key="Master"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-cog-stop-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Master
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- GroupMail:admin -->
    <v-list-item
        v-if="
          this.$store.state.auth.authrole.includes('admin')
        "
        to="/group-mail"
        key="GroupMail"
        exact
    >
      <v-list-item-icon>
        <v-icon>mdi-email-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Group Mail
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      nav_lists: [
        {
          name: "Dashboard",
          icon: "mdi-view-dashboard",
          link: "/",
        },
        {
          name: "Proposer",
          icon: "mdi-account-box-multiple",
          link: "/proposers",
        },
        {
          name: "Proposal",
          icon: "mdi-layers-edit",
          link: "/proposals"
        },
        {
          name: "Reviewer",
          icon: "mdi-account-multiple-check",
          link: "",
          disabled: true,
        },
        {
          name: "Sample",
          icon: "mdi-scatter-plot",
          link: "",
        },
        {
          name: "User",
          icon: "mdi-account",
          link: "/users",
        },
        {
          name: "Information",
          icon: "mdi-newspaper",
          link: "/information",
          disabled: true,
        },
        {
          name: "Master",
          icon: "mdi-cog-stop-outline",
          link: "",
          disabled: true,
        },
      ],
    };
  },
};
</script>
