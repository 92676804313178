<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/proposers" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Proposer Information</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="25%">Name</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.name }}
                  </td>
                </tr>
                <tr>
                  <th width="25%">E-mail</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.email }}
                  </td>
                </tr>
                <tr>
                  <th width="25%">Affiliation</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.affiliation }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Proposals
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Term</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Global Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in fields.created_proposal"
                  :key="item.id"
                >
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.term_id }}
                  </td>
                  <td>
                    <a :href="'/proposals/detail/' + item.id">{{ item.title }}</a>
                  </td>
                  <td>
                    {{ status[item.status] }}
                  </td>
                  <td>
                    {{ item.global_score }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      fields: {},
      status: [],
      earlyCareerScientists: {},
      breadcrumbs: [
        {
          text: "Proposer List",
          disabled: false,
          href: "/proposers/",
        },
        {
          text: "Proposer Detail",
          disabled: true,
          href: "/proposer/detail",
        },
      ],
    };
  },
  methods: {
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/proposers/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;
        });
      this.$axios
        .get("/api/masters/formArray/early_career_type")
        .then((response) => {
          this.earlyCareerScientists = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/proposal_status")
        .then((response) => {
          this.status = response.data;
      });
    })();
  },
};
</script>