<template>
  <v-app>
    <v-snackbar
        v-model="message.view"
        class="headerSnackbar"
        :color="message.color"
        :timeout="message.timeout"
        centered
        top
    >
        {{ message.text }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="message.view = false"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
    <Header :header="header" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
    font-size: 14px !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 14px !important;
}
.nl2br {
  white-space: pre-wrap;
  word-break: break-all;
}
</style>

<script>
import Header from "./templates/Header";
export default {
  components: { Header },
  data() {
    return {
      header: {
        color: "#0c63ac",
        menucolor: "white",
        navcolor: "grey lighten-2",
        avatarcolor: "orange accent-4",
      },
    };
  },
  computed: {
      message() {
          return this.$store.state.message;
      },
  },
  methods: {
    submit() {
      this.buttonStatus = true;
      this.errors = {};
      this.$axios.post('add_url', this.fields).then(response => {
        if(response.data.result == true){ //保存完了時
         this.$store.commit('setMessage', {
            text : response.data.message,
            color : 'success'
          });
          this.buttonStatus = false;
          this.$router.push({name: 'samples'});
        }
      }).catch(error => {
        if (error.response.status === 400) {
          this.errors = error.response.data.errors || {};
        }
      });
    },
  },
}
</script>
