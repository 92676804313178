<template>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showWindow"
    >
      <v-card>
        <v-card-title>New Reviewer</v-card-title>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            <v-select
                dense
                v-model="selectedReviewer"
                :item-text="item => item.name + ' (' + item.email + ') ' + ((item.affiliation == null) ? '' : item.affiliation)"
                item-value="id"
                :items="reviewer_list"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="CloseWindow()">Cancel</v-btn>
          <v-btn @click="AddReviewer()" color="blue darken-4" dark>Add Reviewer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'addReviewerWindow',
  props: ['reviewers', 'showAddReviewerWindow', 'reviewer_list'],
  data() {
    return {
      showWindow: false,
      selectedReviewer: null,
      param: {},
    };
  },
  methods: {
    AddReviewer() {
      this.errors = {};
      this.param.reviewer_id = this.selectedReviewer;
      this.$axios
        .put(`/api/reviewers/add/${this.$route.params.id}`,this.param)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors || {};
          if (error.response.status === 400){
            this.$store.commit("setMessage", {
                            text: 'There are errors with the input data. Please check the contents.',
                            color: "warning",
                        });
          }
        });
      this.$emit('close-add-reviewer', this.showWindow);
    },
    CloseWindow() {
        this.showWindow = false;
        this.$emit('close-add-reviewer', this.showWindow);
    },
  },
  watch: {
    showAddReviewerWindow: {
      immediate: true,
      handler(value) {
        this.showWindow = value;
      },
    },
  }
};
</script>