<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/proposers/applicant" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <v-btn :to="'/proposers/applicant/detail/' + fields.prevID" exact outlined v-if="fields.prevID">
              <v-icon>
                mdi-skip-previous
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
            <span>Applicant Information</span>
            <v-btn :to="'/proposers/applicant/detail/' + fields.nextID" exact outlined v-if="fields.nextID">
              <v-icon>
                mdi-skip-next
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="25%">Name | E-mail | Affiliation (Early Career)</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.name }} | {{ fields.email }} | {{ fields.affiliation }} ({{ earlyCareerScientists[fields.early_career] }})
                  </td>
                </tr>
                <tr>
                  <th>Tentative title of the proposal</th>
                  <td class="text-subtitle-1" colspan="3">
                    {{ fields.proposal_title }}
                  </td>
                </tr>
                <tr>
                  <th>Brief description of the proposal</th>
                  <td class="text-subtitle-1" colspan="3">
                    {{ fields.proposal_abstract }}
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="text-subtitle-1">
                    <div v-if="fields.status == 0" class="red--text font-weight-bold">Not Registered</div>
                    <div v-else-if="fields.status == 1" class="indigo--text">Registered</div>
                    <div v-else-if="fields.status == 2">Rejected</div>
                  </td>
                  <th>
                    Application Date
                  </th>
                  <td>
                    {{ fields.created_at | moment("YYYY-MM-DD HH:mm") }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Collaborator
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Affiliation</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in fields.applicant_collaborator"
                  :key="item.id"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.email }}
                  </td>
                  <td>
                    {{ item.affiliation }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">

      <v-btn
        class="font-weight-bold mr-4 mt-4"
        color="blue darken-4"
        x-large
        dark
        :disabled="loading"
        :loading="loading"
        @click.once="registration"
        v-if="!register && fields.status == 0"
      >
        REGISTER
      </v-btn>
      <v-btn
        class="font-weight-bold mr-4 mt-4"
        color="grey darken-4"
        x-large
        disabled
        v-else-if="fields.status == 1"
      >
        REGISTERED
      </v-btn>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Waiting...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    
    <div class="text-right mt-5 mb-8 pb-8 pr-6">
      <v-btn class="font-weight-bold" color="yellow darken-4" outlined v-if="fields.status == 0" :to="'/proposers/applicant/edit/' + fields.id">
        <v-icon left>mdi-pencil</v-icon>
        Edit
      </v-btn>
      <v-btn class="font-weight-bold ml-2" color="red darken-1" outlined v-if="fields.status == 0" @click="showDialogReject = true">
        <v-icon left>mdi-trash-can</v-icon>
        Reject
      </v-btn>
      <v-btn class="font-weight-bold ml-2" color="red darken-1" outlined v-if="fields.status == 0" @click="showDialogRejectNoMail = true">
        <v-icon left>mdi-trash-can</v-icon>
        REJECT (DON’T SEND MAIL)
      </v-btn>
      <v-btn class="font-weight-bold" color="red darken-1" outlined v-if="fields.status == 2" @click="cancelRejectApplicant">
        <v-icon left>mdi-trash-can</v-icon>
        CANCEL REJECT 
      </v-btn>
    </div>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialogReject"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to reject this application?
             (Reject mail will be sent to the user.)
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialogReject = false">Cancel</v-btn>
          <v-btn @click="rejectApplicant" color="black" dark>Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialogRejectNoMail"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to reject this application?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialogRejectNoMail = false">Cancel</v-btn>
          <v-btn @click="rejectApplicantNoMail" color="black" dark>Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      register: false,
      showDialogReject: false,
      showDialogRejectNoMail: false,
      fields: {},
      users: {},
      status: {},
      earlyCareerScientists: {},
      breadcrumbs: [
        {
          text: "Applicant List",
          disabled: false,
          href: "/proposers/applicant",
        },
        {
          text: "Applicant Detail",
          disabled: true,
          href: "/proposer/applicant/detail",
        },
      ],
    };
  },
  methods: {
    getApplicantData(id) {
      this.$axios
        .get(`/api/applicants/${id}`)
        .then(response => {
          this.fields = response.data;
        });
    },
    rejectApplicant() {
      this.showDialogReject = false;
      this.errors = {};
      this.$axios
        .put(`/api/applicants/reject/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.fields.status = 2;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
    },
    rejectApplicantNoMail() {
      this.showDialogRejectNoMail = false;
      this.errors = {};
      this.$axios
        .put(`/api/applicants/reject_no_mail/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.fields.status = 2;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
    },
    cancelRejectApplicant() {
      this.errors = {};
      this.$axios
        .put(`/api/applicants/cancel_reject/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.fields.status = 0;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
    },
    registration() {
      this.errors = {};
      this.loading = true;
      this.$axios
        .put(`/api/applicants/register/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.fields.status = 1;
            this.loading = false;
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
          this.loading = false;
        });
    },
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    (async () => {
      await this.$axios
        .get(`/api/masters/formArray/User`)
        .then(response => {
          this.users = response.data;
      });
      this.getApplicantData(this.$route.params.id);
      this.$axios
        .get("/api/masters/formArray/early_career_type")
        .then((response) => {
          this.earlyCareerScientists = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/applicant_status")
        .then((response) => {
          this.status = response.data;
          this.status[0] = '-';
      });
    })();
  },
  watch: {
    loading(val) {
      if (!val) return;

      //setTimeout(() => ((this.loading = false), (this.register = true)), 1000);
    },
    '$route' (to) {
      this.getApplicantData(to.params.id)
    }
  },
};
</script>