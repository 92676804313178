<template>
  <v-card color="basil" flat>
    <v-card-text>
      <v-row justify="center">
        <v-col md="10">
          <v-simple-table class="mt-8">
            <template v-slot:default>
              <thead>
                <th>No</th>
                <th>Reviewer</th>
                <th>Status</th>
                <th>Rank</th>
                <th>Science Score</th>
                <th>Technical Score</th>
                <th></th>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in fields.reports"
                  :key="item.id"
                >
                  <td class="text-center">
                    {{ index + 1 }}
                  </td>
                  <td class="text-center">
                    <router-link :to="'/reviewers/detail/' + item.user_id">{{ users[item.user_id].name }}({{ users[item.user_id].affiliation }})</router-link>
                  </td>
                  <td v-if="item.status === 0" class="red--text text--darken-4 font-weight-bold text-center">
                    Not Requested
                  </td>
                  <td v-else-if="item.status === 1" class="blue--text text--darken-4 font-weight-bold text-center">
                    Requested
                  </td>
                  <td v-else-if="item.status === 2" class="orange--text text--darken-3 font-weight-bold text-center">
                    Accepted
                  </td>
                  <td v-else-if="item.status === 3" class="green--text text--darken-4 font-weight-bold text-center">
                    Submitted
                  </td>
                  <td v-else-if="item.status === 9" class="black--text text--darken-4 text-center">
                    Declined
                  </td>
                  <td v-else class="text-center"></td>
                  <td class="text-center">
                    {{ item.rank }}
                  </td>
                  <td class="text-center">
                    {{ item.science_score	 }}
                  </td>
                  <td class="text-center">
                    {{ item.technical_score }}
                  </td>
                  <td v-if="fields.term.disabled === 0 && item.status === 0" class="text-center py-3">
                    <v-btn
                      color="error"
                      fab
                      x-small
                      outlined
                      class="mx-1"
                      @click="reportId = item.id,showCancelReviewerDialog = true"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  <td v-else-if="item.status === 3 && isAdmin" class="text-center py-3">
                    <v-btn
                      color="error"
                      x-small
                      class="mx-1"
                      @click="reportId = item.id,showCancelSubmitDialog = true"
                    >
                      Cancel Submit
                    </v-btn>
                  </td>
                  <td v-else class="text-center py-3"></td>
                </tr>
              </tbody>
              <tfoot class="text-h6">
                <th colspan="3"></th>
                <th>Average</th>
                <th>{{ reviewScienceScoreAverage }}</th>
                <th>{{ reviewTeccchnicalScoreAverage }}</th>
                <th colspan="2"></th>
              </tfoot>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="text-center my-6">
        <v-btn v-if="fields.term.disabled === 0 && fields.status === 2 && this.$store.state.auth.authrole.includes('admin')" class="mr-8" color="blue darken-4" dark @click="clickAddReviewer">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add Reviewer
        </v-btn>
        <v-btn v-if="fields.term.disabled === 0 && fields.status === 2 && this.$store.state.auth.authrole.includes('admin')" class="mr-8" color="orange darken-4" dark @click="clickUpdateGlobalScore"> Update Global Score </v-btn>
        <v-btn
          v-if="fields.status === 2"
          color="green darken-4"
          dark
          :href="`/api/proposals/download_reports/${fields.id}`">
            Download All Reports
          </v-btn>
      </div>
      <div
        v-for="(item) in fields.reports" :key="item.id"
      >
        <div v-if="item.status === 3">
          <v-card class="mb-6">
            <v-card-title class="px-4 py-2 black darken-4">
              <h3 class="subtitle-1 font-weight-bold white--text">
                {{ item.user.name }}
              </h3>
            </v-card-title>
            <v-simple-table class="mt-6">
                <tbody>
                  <tr>
                    <th>Science Comment Strength</th>
                    <td style="white-space:pre-wrap; word-wrap:break-word;">{{ item.science_comment_strength }}</td>
                    <th>Technical Comment Strength</th>
                    <td style="white-space:pre-wrap; word-wrap:break-word;">{{ item.technical_comment_strength }}</td>
                  </tr>
                  <tr>
                    <th>Science Comment Weakness</th>
                    <td style="white-space:pre-wrap; word-wrap:break-word;">{{ item.science_comment_weakness }}</td>
                    <th>Technical Comment Weakness</th>
                    <td style="white-space:pre-wrap; word-wrap:break-word;">{{ item.technical_comment_weakness }}</td>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </div>
      </div>
    </v-card-text>

    <v-dialog 
      transition="dialog-top-transition"
      max-width="600"
      v-model="showCancelReviewerDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to delete the report?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showCancelReviewerDialog = false">Cancel</v-btn>
          <v-btn @click="cancelReviewer(reportId)" color="black" dark>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
      transition="dialog-top-transition"
      max-width="600"
      v-model="showCancelSubmitDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you really want to cancel this submission?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showCancelSubmitDialog = false">NO</v-btn>
          <v-btn @click="cancelSubmit(reportId)" color="black" dark>YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
export default {
  name: 'tabReferee',
  props: ['fields', 'users', 'reviewScienceScoreAverage', 'reviewTeccchnicalScoreAverage'],
  data() {
    return {
      isAdmin: null,
      reportId: null,
      showWindow: false,
      showGlobalScoreWindow: false,
      tempReviewers: [],
      showCancelReviewerDialog: false,
      showCancelSubmitDialog: false,
    };
  },
  methods: {
    clickAddReviewer() {
        this.showWindow = true;
        this.$emit('click-add-reviewer', this.showWindow);
    },
    clickUpdateGlobalScore() {
        this.showGlobalScoreWindow = true;
        this.$emit('click-update-score', this.showGlobalScoreWindow);
    },
    cancelReviewer(id) {
      this.$axios
        .put(`/api/reviewers/cancel/${id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.$router.go({path: this.$router.currentRoute.path, force: true})
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
        });
        this.showCancelReviewerDialog = false;
    },

    cancelSubmit(id) {
      this.$axios
        .put(`/api/reports/cancel/${id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.$router.go({path: this.$router.currentRoute.path, force: true})
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
        });
        this.showCancelSubmitDialog = false;
    }
  },
  mounted() {
    this.isAdmin = this.$store.state.auth.authrole.includes('admin');
  },
}
</script>