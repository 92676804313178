<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/proposals" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <v-btn :to="'/proposals/detail/' + fields.prevID" exact outlined v-if="fields.prevID">
              <v-icon>
                mdi-skip-previous
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
            {{ fields.id }}
            <v-btn :to="'/proposals/detail/' + fields.nextID" exact outlined v-if="fields.nextID">
              <v-icon>
                mdi-skip-next
              </v-icon>
            </v-btn>
            <span v-else>
            </span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Title</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.id }}: {{ fields.title }}
                  </td>
                </tr>
                <tr>
                  <th width="20%">Proposer (Early career)</th>
                  <td class="text-h6" colspan="3">{{ fields.name }} | {{ fields.affiliation }} | {{ fields.email }}
                    ({{ earlyCareerScientists[fields.early_career] }})
                  </td>
                </tr>
                <tr>
                  <th width="20%">Previous Status</th>
                  <td class="text-subtitle-1" colspan="3">
                    <span v-for="item in fields.accepted_terms" :key="item.id" class="mr-4">
                      <span v-if="item.term_id">
                        AO{{ item.term_id }},
                      </span>
                      <span v-else-if="accepted_status_other[item.other_status_id]">
                        {{ accepted_status_other[item.other_status_id] }},
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th width="20%">Collaborator</th>
                  <td colspan="3">
                    <div v-for="item in fields.proposal_collaborator" :key="item.id">
                      {{item.name}} / {{ item.affiliation }} ({{ item.email }})<br>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="text-subtitle-1">
                    <span v-if="fields.status == 1">-</span>
                    <span v-if="fields.status == 2">Submitted</span>
                    <!-- Unlock Submit ボタンの表示 -->
                    <v-btn 
                      x-small 
                      class="mx-1" 
                      color="error" 
                      outlined 
                      v-if="(fields.term.disabled === 0 && fields.status == 2 && submitLimit && isAdmin) || (fields.term.disabled === 0 && fields.status == 2 && fields.is_unlocked == 1 && isAdmin)" 
                      @click="showCancelSubmitDialog = true">
                      Cancel Submit
                    </v-btn>
                  </td>
                  <td class="text-subtitle-1">
                    <span v-if="fields.is_unlocked == 1" class="error--text">UNLOCKED</span>
                    <!-- Unlock Cancel ボタンの表示 -->
                    <v-btn 
                      x-small 
                      class="mx-1" 
                      outlined 
                      v-if="fields.term.disabled === 0 && fields.is_unlocked == 1 && isAdmin" 
                      @click="showCancelUnlockDialog = true">
                      Cancel Unlock
                    </v-btn>
                    <!-- Unlock ボタンの表示 -->
                    <v-btn 
                      x-small 
                      class="mx-1" 
                      color="error" 
                      outlined 
                      v-if="fields.term.disabled === 0 && fields.is_unlocked == 0 && isAdmin" 
                      @click="showUnlockDialog = true">
                      Unlock
                    </v-btn>
                  </td>
                  <th>Global Score / Rank</th>
                  <td class="text-subtitle-1">{{ fields.global_score ? fields.global_score : "-" }} / {{ fields.rank ? fields.rank : "-" }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4" justify="center" align="center">
      <v-col md="10">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="blue lighten-5"
            color="blue darken-3"
            grow
            slider-size="4"
          >
            <v-tab> Proposal </v-tab>

            <v-tab> Addition </v-tab>

            <v-tab> Review </v-tab>

            <v-tab :disabled="sampleLoading"> Sample </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <tabDetail :fields="fields" :user_type="user_type" />
            </v-tab-item>
            <v-tab-item>
              <tabAddition :fields="fields" @click-edit-note="onClickEditNote"/>
            </v-tab-item>
            <v-tab-item>
              <tabReferee :fields="fields" :users="users" @click-add-reviewer="onClickAddReviewer" @click-update-score="onClickUpdateScore" :reviewScienceScoreAverage="reviewScienceScoreAverage" :reviewTeccchnicalScoreAverage="reviewTeccchnicalScoreAverage" />
            </v-tab-item>
            <v-tab-item>
              <tabAssignedSample :fields="fields" :sample_lists="sample_lists" :sampleDBUrl="sampleDBUrl" :requestedSampleTotal="requestedSampleTotal" :assignedSampleTotal="assignedSampleTotal" :sampleTypes="sampleTypes" :chambers="chambers" />
            </v-tab-item>
          </v-tabs-items>
          <v-dialog 
            transition="dialog-top-transition"
            max-width="600"
            v-model="showCancelSubmitDialog"
          >
            <v-card>
              <v-card-text>
                <div class="text-h6 pa-8 grey--text text-center">
                  Do you really want to cancel this submission?
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-5">
                <v-btn @click="showCancelSubmitDialog = false">NO</v-btn>
                <v-btn @click="cancelSubmit(proposalId)" color="black" dark>YES</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog 
            transition="dialog-top-transition"
            max-width="600"
            v-model="showUnlockDialog"
          >
            <v-card>
              <v-card-text>
                <div class="text-h6 pa-8 grey--text text-center">
                  Do you really want to unlock?
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-5">
                <v-btn @click="showUnlockDialog = false">NO</v-btn>
                <v-btn @click="unlock(proposalId)" color="black" dark>YES</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog 
            transition="dialog-top-transition"
            max-width="600"
            v-model="showCancelUnlockDialog"
          >
            <v-card>
              <v-card-text>
                <div class="text-h6 pa-8 grey--text text-center">
                  Do you really want to cancel unlock?
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-5">
                <v-btn @click="showCancelUnlockDialog = false">NO</v-btn>
                <v-btn @click="cancelUnlock(proposalId)" color="black" dark>YES</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
        </v-card>
      </v-col>
    </v-row>
    <editAdditionalNoteWindow :showEditAdditionalNoteWindow="showEditAdditionalNoteWindow" @close-edit-note="onCloseEditNote" @exe-get-proposal-date="getProposalData" :additional_note="additional_notes" />
    <addReviewerWindow :showAddReviewerWindow="showAddReviewerWindow" @close-add-reviewer="onCloseAddReviewer" :reviewer_list="reviewer_list" />
    <updateScoreWindow :showUpdateScoreWindow="showUpdateScoreWindow" @close-update-score="onCloseUpdateScore" :showUpdateScoreWindowFields="showUpdateScoreWindowFields" :globalScore="globalScore" :newScore="newScore" :rank="rank" :newRank="newRank"/>
  </v-container>
</template>

<script>
import tabDetail from "./tabDetail";
import tabAddition from "./tabAddition";
import tabReferee from "./tabReferee";
import tabAssignedSample from "./tabAssignedSample";
import addReviewerWindow from "./addReviewerWindow";
import updateScoreWindow from "./updateScoreWindow";
import editAdditionalNoteWindow from "./editAdditionalNoteWindow";
import moment from "moment";
export default {
  components: { tabDetail, tabAddition, tabReferee, tabAssignedSample, addReviewerWindow, editAdditionalNoteWindow, updateScoreWindow},
  data() {
    return {
      loading: false,
      sampleLoading: true,
      user_type: "",
      register: false,
      tab: null,
      fields: {},
      earlyCareerScientists: {},
      accepted_status_other: [],
      sampleTypes: [],
      chambers: [],
      sample_lists: [],
      requestedSampleTotal: 0,
      assignedSampleTotal: 0,
      sampleDBUrl:"",
      showAddReviewerWindow: false,
      showUpdateScoreWindow: false,
      showEditAdditionalNoteWindow: false,
      additional_notes: "",
      reviewer_list: [],
      reviewers: {},
      users: {},
      reviewScienceScoreAverage: 0,
      reviewTeccchnicalScoreAverage: 0,
      reportsCount: 0,
      showCancelSubmitDialog: false,
      showUnlockDialog: false,
      showCancelUnlockDialog: false,
      submitLimit: null,
      showUpdateScoreWindowFields: {},
      globalScore: 0,
      newScore: 0,
      rank: 0,
      newRank: 0,
      breadcrumbs: [
        {
          text: "Proposal List",
          disabled: false,
          href: "/proposals/",
        },
        {
          text: "Proposal Detail",
          disabled: true,
          href: "/proposals/detail",
        },
      ],
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/masters/formArray/early_career_type")
        .then((response) => {
          this.earlyCareerScientists = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/proposal_previos_accepted_status")
        .then((response) => {
          this.accepted_status_other = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/sample_type")
        .then((response) => {
          this.sampleTypes = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/ChamberList")
        .then((response) => {
          this.chambers = response.data;
      });
      this.$axios
        .get(`/api/masters/getAllSamples`)
        .then(response => {
          for(let kw in response.data) {
            this.sample_lists[response.data[kw].id] = response.data[kw];
          }
          this.sampleLoading = false;
        });
      this.getProposalData(this.$route.params.id);
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then((response) => {
          this.sampleDBUrl = response.data;
      });
      this.$axios
        .get(`/api/users/all`)
        .then(response => {
          this.user_list = response.data;
          this.user_list.forEach(e => {
            this.users[e.id] = e; //Reportのuser_idとusersのキーが一致するよう整理
          });
        });
      this.$axios
        .get(`/api/reviewers/get_all_reviewer`)
        .then(response => {
          this.reviewer_list = response.data;
          this.reviewer_list.forEach(e => {
            this.reviewers[e.id] = e; //Reportのuser_idとreviewersのキーが一致するよう整理
          });
        });
      this.$axios
        .get(`/api/masters/checkProposalLimit`)
        .then((response) => {
          this.submitLimit = response.data;
      });
    })();
    this.isAdmin = this.$store.state.auth.authrole.includes('admin');
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  methods: {
    getProposalData(id) {
      this.$axios
      .get(`/api/proposals/${id}`)
      .then(response => {
        this.fields = response.data;
        let totalR = 0;
        let totalA = 0;
        for (let i in response.data.requested_samples) {
          if (response.data.requested_samples[i].sample_type === 0
            || response.data.requested_samples[i].sample_type === 1) {
              if (!isNaN(response.data.requested_samples[i].weight) && response.data.requested_samples[i].weight) {
                totalR += parseFloat(response.data.requested_samples[i].weight);
              }
          }
        }
        for (let i in response.data.assigned_samples_status_true) {
          totalA += parseFloat(response.data.assigned_samples_status_true[i].weight);
        }
        this.requestedSampleTotal = (Math.floor(totalR* 10))/10;
        this.assignedSampleTotal = (Math.floor(totalA* 10))/10;

        //reviewのScienceScoreとTechnicalScoreの平均を取得
        let totalS = 0;
        let totalT = 0;
        this.reportsCount = 0;
        for (let i in response.data.reports) {
          if (response.data.reports[i].status === 3) {
            totalS += isNaN(parseFloat(response.data.reports[i].science_score)) ? 0 : parseFloat(response.data.reports[i].science_score);
            totalT += isNaN(parseFloat(response.data.reports[i].technical_score)) ? 0 : parseFloat(response.data.reports[i].technical_score);
            this.reportsCount++;
          }
        }
        totalS =  totalS / this.reportsCount;
        totalT =  totalT / this.reportsCount;
        this.reviewScienceScoreAverage = isNaN((Math.round(totalS* 100))/100) ? 0 : (Math.round(totalS* 100))/100;
        this.reviewTeccchnicalScoreAverage = isNaN((Math.round(totalT* 100))/100) ? 0 : (Math.round(totalT* 100))/100;
        this.$axios
          .get(`/api/proposals/score/${this.$route.params.id}`)
          .then((response) => {
            this.showUpdateScoreWindowFields = response.data;
            this.globalScore = this.fields.global_score;
            this.rank = this.fields.rank;

            if (this.fields.global_score == null || this.fields.global_score == 0) {
              this.newScore = this.showUpdateScoreWindowFields.total_score_average;
            } else {
              this.newScore = this.fields.global_score;
            }

            if (this.fields.rank == null || this.fields.rank == 0) {
              this.newRank = this.showUpdateScoreWindowFields.rank_average;
            } else {
              this.newRank = this.fields.rank;
            }
        });
      });
    },
    onClickAddReviewer() {
      this.showAddReviewerWindow = true;
      this.errors = {};
    },
    onClickUpdateScore() {
      this.showUpdateScoreWindow = true;
    },
    onCloseAddReviewer() {
      this.showAddReviewerWindow = false;
    },
    onCloseUpdateScore(id) {
      this.showUpdateScoreWindow = false;
      this.getProposalData(id);
    },
    onClickEditNote() {
      this.additional_notes = this.fields.additional_notes;
      this.showEditAdditionalNoteWindow = true;
    },
    onCloseEditNote() {
      this.showEditAdditionalNoteWindow = false;
    },
    cancelSubmit() {
      this.errors = {};
      this.$axios
        .put(`/api/proposals/cancel/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.submitCheck = false;
            this.fields.status = 1;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
      this.showCancelSubmitDialog = false;
    },
    unlock() {
      this.errors = {};
      this.$axios
        .put(`/api/proposals/unlock/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.fields.is_unlocked = 1;
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
        this.showUnlockDialog = false;
    },
    cancelUnlock() {
      this.errors = {};
      this.$axios
        .put(`/api/proposals/cancel_unlock/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }
          this.fields.is_unlocked = 0;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
        this.showCancelUnlockDialog = false;
    },
  },
  watch: {
    '$route' (to) {
      this.getProposalData(to.params.id)
    },
  },
};

</script>