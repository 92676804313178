<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn :to="'/proposers/applicant/detail/' + $route.params.id" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" class="mb-4">
      <v-col xs="10" md="10">
        <div class="text-left text-h5">
            Registration Form (Notice of Intent for the {{termText}} AO)
        </div>
      </v-col>
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title
            class="px-4 py-2 d-flex justify-space-between"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Proposer
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Name*</th>
                  <td class="py-2">
                    <div class="d-flex justify-space-between">
                      <div class="v-input__control" style="width: 58%;">
                        <v-text-field
                          outlined
                          label="First Name*"
                          style="margin-top: 30px;"
                          color="blue darken-4"
                          background-color="white"
                          placeholder="Taro"
                          v-model="fields.first_name"
                          :rules="[rules.required,rules.counter50]"
                          :error-messages="
                            errors.first_name ? errors.first_name[0] :''
                          "
                          :error-count="
                            errors.first_name ? errors.first_name.length : 0
                          "
                        ></v-text-field>
                      </div>
                      <div class="v-input__control" style="width: 38%; margin-left: 2%">
                      <v-text-field
                        outlined
                        label="Middle Name"
                        style="margin-top: 30px;"
                        color="blue darken-4"
                        background-color="white"
                        v-model="fields.middle_name"
                        :rules="[rules.counter50]"
                        :error-messages="
                          errors.middle_name ? errors.middle_name[0] :''
                        "
                        :error-count="
                          errors.middle_name ? errors.middle_name.length : 0
                        "
                      ></v-text-field>
                      </div>
                      <div class="v-input__control" style="width: 58%; margin-left: 2%">
                      <v-text-field
                        outlined
                        label="Last Name*"
                        style="margin-top: 30px;"
                        color="blue darken-4"
                        background-color="white"
                        placeholder="Suzuki"
                        v-model="fields.last_name"
                        :rules="[rules.required, rules.counter50]"
                        :error-messages="
                          errors.last_name ? errors.last_name[0] :''
                        "
                        :error-count="
                          errors.last_name ? errors.last_name.length : 0
                        "
                      ></v-text-field>
                    </div>
                    <div class="v-input__control" style="width: 38%; margin-left: 2%">
                      <v-text-field
                        outlined
                        label="Suffix"
                        style="margin-top: 30px;"
                        color="blue darken-4"
                        background-color="white"
                        v-model="fields.suffix"
                        :rules="[rules.counter50]"
                        :error-messages="
                          errors.suffix ? errors.suffix :''
                        "
                        :error-count="
                          errors.suffix ? errors.suffix.length : 0
                        "
                      ></v-text-field>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>E-mail*</th>
                  <td>
                    <div class="v-input__control">
                      <v-text-field
                        outlined
                        style="margin-top: 30px;"
                        color="blue darken-4"
                        background-color="white"
                        placeholder="taro@example.com"
                        v-model="fields.email"
                        :rules="[rules.required,rules.counter200,rules.email]"
                        :error-messages="
                          errors.email ? errors.email[0] :''
                        "
                        :error-count="
                          errors.email ? errors.email.length : 0
                        "
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Affiliation*</th>
                  <td>
                    <div class="v-input__control">
                      <v-text-field
                        outlined
                        color="black"
                        style="margin-top: 30px;"
                        background-color="white"
                        placeholder="Example Univ."
                        v-model="fields.affiliation"
                        :rules="[rules.required, rules.counter200]"
                        :error-messages="
                          errors.affiliation ? errors.affiliation[0] :''
                        "
                        :error-count="
                          errors.affiliation ? errors.affiliation.length : 0
                        "
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Early Career Scientist</th>
                  <td class="text-subtitle-1">
                    <div class="v-input__control">
                      <v-radio-group row v-model="fields.early_career">
                        <v-radio
                          v-for="earlyCareer in earlyCareerScientists"
                          :key="earlyCareer.id"
                          :label="earlyCareer.label"
                          :value="earlyCareer.id"
                          color="blue darken-4"
                          :rules="[rules.required]"
                          :error-messages="
                            errors.early_career ? errors.early_career[0] :''
                          "
                          :error-count="
                            errors.early_career ? errors.early_career.length : 0
                          "
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Tentative title of the proposal*</th>
                  <td>
                    <div class="v-input__control">
                      <v-text-field
                        outlined
                        style="margin-top: 30px;"
                        color="black"
                        background-color="white"
                        v-model="fields.proposal_title"
                        :rules="[rules.required, rules.counter200]"
                        :error-messages="
                          errors.proposal_title ? errors.proposal_title[0] :''
                        "
                        :error-count="
                          errors.proposal_title ? errors.proposal_title.length : 0
                        "
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Brief description of the proposal (up to 150 words)*</th>
                  <td>
                    <div class="v-input__control">
                      <v-textarea
                        outlined
                        solo
                        dense
                        flat
                        style="margin-top: 30px;"
                        color="black"
                        v-model="fields.proposal_abstract"
                        :rules="[rules.counter5000]"
                        :error-messages="
                          word_count_abstract > 150 ? 'Max 150 words' : '' || errors['proposal_abstract'] ? errors['proposal_abstract'] : ''
                        "
                        :error-count="
                          word_count_abstract > 150 ? 1 : 0
                        "
                      ></v-textarea>
                      <div v-if="errors && errors.proposal_abstract" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text pl-3" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.proposal_abstract[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="text-right">{{ word_count_abstract }} / 150 words</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="10">
        <v-card color="white" :key="index">
          <v-card-title
            class="px-4 py-2 indigo d-flex white--text"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Collaborator
            </h3>
            <v-dialog
              transition="dialog-top-transition"
              max-width="600">
              <template v-slot:activator="{ on }">
                <v-icon middle v-on="on" color="white" class="ml-4">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <v-card>
                <v-card-title>Collaborators</v-card-title>
                <v-card-text>
                  <div class="pa-4">
                    <p>Enter collaborator’s information. Pre-entered information is at the time of account creation. Check them and correct, add, or delete as necessary. </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-title>
          <transition-group tag="div">
            <template v-for="(collaborator, index) in this.fields.collaborators">
                  <div class="pa-4 pb-0" :key="index">
                    <v-row dense>
                      <v-col cols="6">
                        <v-row dense>
                          <v-col cols="6">
                            <div class="d-flex justify-space-between">
                              <div class="print-index">
                                  {{ index + 1 }}
                              </div>
                              <div class="v-input__control" style="width: 60%;">
                                <v-text-field
                                  outlined
                                  label="First Name*"
                                  class="mb-1"
                                  background-color="white"
                                  color="black"
                                  placeholder="Taro"
                                  hide-details
                                  v-model="collaborator.first_name"
                                  :error-messages="
                                    errors['collaborators.' + index + '.first_name'] ? errors['collaborators.' + index + '.first_name'] :''
                                  "
                                  :rules="[rules.required, rules.counter50]"
                                ></v-text-field>
                                <div
                                  class="v-text-field__details"
                                  v-if="
                                    errors['collaborators.' + index + '.first_name']
                                  "
                                >
                                  <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                      <div class="messages__message">{{ errors['collaborators.' + index + '.first_name'][0] }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="v-input__control" style="width: 37%; margin-left: 3%">
                                <v-text-field
                                  outlined
                                  label="Middle Name"
                                  class="mb-1"
                                  background-color="white"
                                  color="black"
                                  hide-details
                                  v-model="collaborator.middle_name"
                                  :error-messages="
                                    errors['collaborators.' + index + '.middle_name'] ? errors['collaborators.' + index + '.middle_name'] :''
                                  "
                                  :rules="[rules.counter50]"
                                ></v-text-field>
                                <div
                                  class="v-text-field__details"
                                  v-if="
                                    errors['collaborators.' + index + '.middle_name']
                                  "
                                >
                                  <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                      <div class="messages__message">{{ errors['collaborators.' + index + '.middle_name'][0] }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex justify-space-between">
                              <div class="v-input__control" style="width: 60%;">
                                <v-text-field
                                  outlined
                                  label="Last Name*"
                                  class="mb-1"
                                  background-color="white"
                                  color="black"
                                  placeholder="Suzuki"
                                  hide-details
                                  v-model="collaborator.last_name"
                                  :error-messages="
                                    errors['collaborators.' + index + '.last_name'] ? errors['collaborators.' + index + '.last_name'] :''
                                  "
                                  :rules="[rules.required, rules.counter50]"
                                ></v-text-field>
                                <div
                                  class="v-text-field__details"
                                  v-if="
                                    errors['collaborators.' + index + '.last_name']
                                  "
                                >
                                  <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                      <div class="messages__message">{{ errors['collaborators.' + index + '.last_name'][0] }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="v-input__control" style="width: 37%; margin-left: 3%">
                                <v-text-field
                                  outlined
                                  label="Suffix"
                                  class="mb-1"
                                  background-color="white"
                                  color="black"
                                  hide-details
                                  v-model="collaborator.suffix"
                                  :error-messages="
                                    errors['collaborators.' + index + '.suffix'] ? errors['collaborators.' + index + '.suffix'] :''
                                  "
                                  :rules="[rules.counter50]"
                                ></v-text-field>
                                <div
                                  class="v-text-field__details"
                                  v-if="
                                    errors['collaborators.' + index + '.suffix']
                                  "
                                >
                                  <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                      <div class="messages__message">{{ errors['collaborators.' + index + '.suffix'][0] }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row dense>
                          <v-col>
                            <v-text-field
                              outlined
                              label="E-Mail*"
                              class="mb-1"
                              background-color="white"
                              color="black"
                              placeholder="taro@example.com"
                              hide-details
                              v-model="collaborator.email"
                              :error-messages="
                                errors['collaborators.' + index + '.email'] ? errors['collaborators.' + index + '.email'] :''
                              "
                              :rules="[rules.required,rules.counter200, rules.email]"
                            ></v-text-field>
                            <div
                              class="v-text-field__details"
                              v-if="
                                errors['collaborators.' + index + '.email']
                              "
                            >
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                  <div class="messages__message">{{ errors['collaborators.' + index + '.email'][0] }}</div>
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <v-text-field
                              outlined
                              label="Affiliation*"
                              class="mb-1"
                              background-color="white"
                              color="black"
                              placeholder="Example Univ."
                              hide-details
                              v-model="collaborator.affiliation"
                              :error-messages="
                                errors['collaborators.' + index + '.affiliation'] ? errors['collaborators.' + index + '.affiliation'] :''
                              "
                              :rules="[rules.required, rules.counter200]"
                            ></v-text-field>
                            <div
                              class="v-text-field__details"
                              v-if="
                                errors['collaborators.' + index + '.affiliation']
                              "
                            >
                              <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                  <div class="messages__message">{{ errors['collaborators.' + index + '.affiliation'][0] }}</div>
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <div>
                            <div :key="index">
                              <v-btn small icon class="pt-8 pl-2">
                                <v-icon @click.stop="deleteCollaborator(index)">
                                  mdi-trash-can
                                </v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </transition-group>
            </v-card>
          <div class="text-right mt-4 pb-6">
            <v-btn @click="createCollaborator()">
              Add Collaborator
              <v-icon right medium class="ml-4">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        :to="'/proposers/applicant/detail/' + $route.params.id"
        :disabled="buttonStatus"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="save"
      >
        Save
      </v-btn>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Waiting...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      word_count: 0,
      loading: false,
      buttonStatus: false,
      earlyCareerScientists: {},
      breadcrumbs: [],
      index: '',
      termText: '',
      fields: {
        methods:"validate",
        first_name:"",
        middle_name:"",
        last_name:"",
        suffix:"",
        email:"",
        affiliation:"",
        early_career:0,
        proposal_title:"",
        proposal_abstract:"",
        collaborators: [],
      },
      rules:{
        required: value => !!value || 'This field is required.',
        counter50: value => (!value || value.length <= 50) || 'This form is limited in 50 words',
        counter150: value => (!value || value.length <= 150) || 'This form is limited in 150 words',
        counter200: value => (!value || value.length <= 200) || 'This form is limited in 200 words',
        counter5000: value => (!value || value.length <= 5000) || 'This form is limited in 5000 words',
        email: value => {
          const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
          return pattern.test(value) || 'Only email address can be inputted in this form.'
        },
      },
    };
  },
  computed: {
    word_count_abstract: function () {
      return this.proposal_abstract == '' || this.proposal_abstract == null ? 0 : this.proposal_abstract.match(/\S+/g).length;
    },
    first_name: {
      get() {
        return this.$store.state.first_name;
      },
      set(value) {
        this.$store.commit("updateFirstName", value);
      },
    },
    middle_name: {
      get() {
        return this.$store.state.middle_name;
      },
      set(value) {
        this.$store.commit("updateMiddleName", value);
      },
    },
    last_name: {
      get() {
        return this.$store.state.last_name;
      },
      set(value) {
        this.$store.commit("updateLastName", value);
      },
    },
    suffix: {
      get() {
        return this.$store.state.suffix;
      },
      set(value) {
        this.$store.commit("updateSuffix", value);
      },
    },
    email: {
      get() {
        return this.$store.state.email;
      },
      set(value) {
        this.$store.commit("updateEmail", value);
      },
    },
    affiliation: {
      get() {
        return this.$store.state.affiliation;
      },
      set(value) {
        this.$store.commit("updateAffiliation", value);
      },
    },
    early_career: {
      get() {
        return this.$store.state.early_career;
      },
      set(value) {
        this.$store.commit("updateEarlyCareer", value);
      },
    },
    proposal_title: {
      get() {
        return this.$store.state.proposal_title;
      },
      set(value) {
        this.$store.commit("updateProposalTitle", value);
      },
    },
    proposal_abstract: {
      get() {
        return this.$store.state.proposal_abstract;
      },
      set(value) {
        this.$store.commit("updateProposalAbstract", value);
        this.word_count = value.match(/\S+/g).length;
      },
    },
    collaborators: {
      get() {
        return this.$store.state.collaborators;
      },
      set(value) {
        this.$store.commit("updateCollaborators", value);
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      (async () => {
        await this.$axios.get("/api/masters/early_career_type").then((response) => {
          this.earlyCareerScientists = response.data;
        });
      this.$axios.get("/api/masters/getTermText/0")
        .then((response) => {
          this.termText = response.data;
        });
      this.$axios
        .get(`/api/proposers/applicant/edit/${this.$route.params.id}`)
        .then((response) => {
          this.fields.first_name = response.data.first_name;
          this.fields.middle_name = response.data.middle_name;
          this.fields.last_name = response.data.last_name;
          this.fields.suffix = response.data.suffix;
          this.fields.email = response.data.email;
          this.fields.affiliation = response.data.affiliation;
          this.fields.early_career = response.data.early_career;
          this.fields.proposal_title = response.data.proposal_title;
          this.fields.proposal_abstract = response.data.proposal_abstract;
          this.fields.collaborators = response.data.applicant_collaborator;
        });
      })();
    },
    addCollaborator() {
      this.proposal_collaborator.push({ name: "" });
    },
    createCollaborator() {
      const title = this.fields.collaborators.length + 1;
      this.fields.collaborators.push({ title: title });
    },
    deleteCollaborator(index) {
      this.fields.collaborators.splice(index, 1);
    },
    save() {
      this.loading = true;
      this.buttonStatus = true;
      this.errors = {};
      this.$axios
        .put(`/api/proposers/applicant/edit/${this.$route.params.id}`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
           this.$router.back()
          } else {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
          this.loading = false;
          this.buttonStatus = false;
        })
        .catch((error) => {
          this.loading = false;
          this.buttonStatus = false;
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
        });
    },
  },
}
</script>