var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"nav":"","dense":""}},[(
        this.$store.state.auth.authrole.includes('admin')
        || this.$store.state.auth.authrole.includes('panel')
        || this.$store.state.auth.authrole.includes('reviewer')
      )?_c('v-list-item',{key:"Dashboard",attrs:{"to":"/","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Dashboard ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('proposer')
      )?_c('v-list-item',{key:"MyPage",attrs:{"to":"/mypage","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Home ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
      )?_c('v-list-item',{key:"Applicant",attrs:{"to":"/proposers/applicant","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-box-multiple-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Applicant ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
        || this.$store.state.auth.authrole.includes('panel')
      )?_c('v-list-item',{key:"Proposer",attrs:{"to":"/proposers","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-box-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Proposer ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
        || this.$store.state.auth.authrole.includes('panel')
      )?_c('v-list-item',{key:"Proposal",attrs:{"to":"/proposals","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-layers")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Proposal ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('reviewer')
      )?_c('v-list-item',{key:"Assigned Proposals",attrs:{"to":"/proposals/assigned_proposals","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-layers-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Assigned Proposals ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('proposer')
      )?_c('v-list-item',{key:"MyProposal",attrs:{"to":"/proposals/myproposal","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-layers-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" MyProposal ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('proposer')
      )?_c('v-list-item',{key:"MyProfile",attrs:{"to":"/myprofile","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-layers-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" MyProfile ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
        || this.$store.state.auth.authrole.includes('panel')
      )?_c('v-list-item',{key:"Reviewer",attrs:{"to":"/reviewers","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Reviewer ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
        || this.$store.state.auth.authrole.includes('panel')
      )?_c('v-list-item',{key:"Sample",attrs:{"to":"/samples","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-scatter-plot")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Sample ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
      )?_c('v-list-item',{key:"User",attrs:{"to":"/users","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" User ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
      )?_c('v-list-item',{key:"Information",attrs:{"to":"/information","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-newspaper")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Information ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
      )?_c('v-list-item',{key:"Master",attrs:{"to":"/masters","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog-stop-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Master ")])],1)],1):_vm._e(),(
        this.$store.state.auth.authrole.includes('admin')
      )?_c('v-list-item',{key:"GroupMail",attrs:{"to":"/group-mail","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Group Mail ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }