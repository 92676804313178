<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Assigned Proposals</h2>
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="mt-5 mb-5 px-6">
      <v-col lg="12">
        <template>
          <v-alert
            v-if="this.error != null"
            dense
            outlined
            type="error"
          >{{ this.error }}</v-alert>
          <div>
            <v-card>
              <v-data-table
                :items="items"
                :headers="headers"
                :page.sync="page"
                hide-default-footer
                no-data-text="No data"
                disable-pagination
              >
                <template v-slot:item="{ item }">
                  <tr v-if="item.status !== 0"><!-- status = 0 （初期状態）の proposal は対象に含めない。 -->
                    <td>
                      <v-btn
                        x-small
                        outlined
                        :to="'/reports/detail/' + item.id"
                        >DETAIL</v-btn
                      >
                    </td>
                    <td>{{ item.proposal.name }}</td>
                    <td>{{ item.proposal.affiliation }}</td>
                    <td>{{ item.proposal.id }}: {{ item.proposal.title }}</td>
                    <td>{{ item.proposal.shortAbstract }}</td>
                    <td>
                      <v-select
                        :items="rankRange"
                        dense
                        outlined
                        hide-details
                        color="brown"
                        background-color="white"
                        flat
                        v-model=ranks[item.id]
                      ></v-select>
                    </td>
                    <td>{{ item.science_score }}</td>
                    <td>{{ item.technical_score }}</td>
                    <td v-if="item.status === 1">
                      <v-btn color="red darken-4" x-small dark>{{ status[1] }}</v-btn>
                    </td>
                    <td v-else-if="item.status === 2">
                      <v-btn color="black darken-4" x-small dark>{{ status[2] }}</v-btn>
                    </td>
                    <td v-else-if="item.status === 3">
                      <v-btn color="greens darken-4" x-small dark>{{ status[3] }}</v-btn>
                    </td>
                    <td v-else></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </template>
      </v-col>
      <v-col lg="12">
        <div class="px-5" align="center">
          Give a number for each proposal as the rank, then click [SAVE]. The smallest number (i.e., 1) is the best and the largest number is the poorest. Each proposal should have different number to give the difference rank.
        </div>
      </v-col>
    </v-row>
    <div class="text-center mt-4 pb-6" v-if="items.length > 0">
      <v-btn 
          class="font-weight-bold ml-5"
          color="orange darken-4"
          dark
          large
          @click="showDialog = true">
        Save
      </v-btn>
    </div>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to save rank?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false">NO</v-btn>
          <v-btn @click="save" color="black" dark>YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Search from "../../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      error: null,
      terms:[],
      keywords_name: [],
      status: [],
      global_score: [],
      showDialog: false,
      ranks:[],
      rankRange:[],
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        affiliation: "",
        title: "",
        abstract: "",
        keywords: null,
        term: null,
        not_submitted: true,
        submitted: true,
        assigned: true,
        reviewed: true,
        scored: true,
        excellent: true,
        very_good: true,
        good: true,
        fair: true,
        poor: true,
        not_scored: true,
        rank: null,
      },
      //search用
      searchScheme: [
        "name",
        "email",
        "affiliation",
        "title",
        "abstract",
        "keywords",
        "term",
        "not_submitted",
        "submitted",
        "assigned",
        "reviewed",
        "scored",
        "excellent",
        "very_good",
        "good",
        "fair",
        "poor",
        "not_scored",
        "rank",
        ], //検索条件
      searchURL: "/api/assigned_proposals", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "12%",
        },
        {
          text: "Affiliation",
          sortable: false,
          value: "affiliation",
          width: "12%",
        },
        {
          text: "Title",
          sortable: false,
          value: "title",
          width: "15%",
        },
        {
          text: "Abstract",
          sortable: false,
          value: "abstract",
          width: "40%",
        },
        {
          text: "Rank",
          sortable: false,
          value: "rank",
          width: "10%",
        },
        {
          text: "Science Score",
          sortable: false,
          value: "global score",
          width: "5%",
        },
        {
          text: "Technical Score",
          sortable: false,
          value: "technical score",
          width: "5%",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: "5%",
        },
      ],
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get(`/api/masters/Keyword`)
        .then(response => {
          for(let kw in response.data) {
            this.keywords_name.push({id: response.data[kw].id, label: response.data[kw].label});
          }
        });
      this.$axios
        .get("/api/masters/formArray/report_status")
        .then((response) => {
          this.status = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/score_list")
        .then((response) => {
          this.global_score = response.data;
      });
      this.$axios
        .get(`/api/reports/get_rank/${this.$store.state.auth.authuser.id}`)
        .then((response) => { 
          this.ranks = response.data['ranks'];
          this.rankRange = response.data['rankRange'];
      });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    proposalSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    async downLoad(){
      try {
        //動作確認用
        const res = await this.$axios.get('/api/proposals/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
    save() {
      this.error = null;
      this.$axios
        .post("/api/reports/update_rank", this.ranks)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }else{
            this.error = response.data.message;
          }
        this.showDialog = false;
      });
    }
  }
}
</script>
