<template>
  <v-navigation-drawer
    app
    clipped
    mobile-breakpoint="960"
    v-model="setDrawer"
    :color="header.navcolor"
  >
    <v-container>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title pt-4">
            MENU
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <SideMenu />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import SideMenu from "./SideMenu";

export default {
  components: { SideMenu },
  methods: {
    menu_close() {
      this.nav_lists.forEach((nav_list) => (nav_list.active = false));
    },
  },
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    header: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  computed: {
    setDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        return this.$emit("update:drawer", val);
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
</style>
