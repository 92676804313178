<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn :to="'/reports/detail/' + fields.id" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-card-title
            class="px-4 py-2 d-flex justify-space-between"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Proposal
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">ID: Title</th>
                  <td class="text-h6">
                    {{ fields.reports.proposal.id }}: {{ fields.reports.proposal.title }}
                  </td>
                </tr>
                <tr>
                  <th>Proposer Name</th>
                  <td class="text-subtitle-1">{{ fields.reports.proposal.name }}</td>
                </tr>
                <tr>
                  <th>Abstract</th>
                  <td class="text-subtitle-1">
                    {{ fields.reports.proposal.abstract }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4" justify="center">
      <v-col md="10">
        <v-card>
          <v-card-title
            class="px-4 py-2 indigo d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold">
              Review
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Science score
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Science score</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Choose one score from 5-point scale from the viewpoint of scientific merit.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-col md="4">
                      <v-select
                        dense
                        v-model=fields.reports.science_score
                        :items=score_list
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="brown"
                        background-color="white"
                        flat
                      ></v-select>
                      <div v-if="errors.science_score" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            This field is required.
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </td>
                </tr>
                <tr>
                  <th>Technical score
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Technical score</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Choose one score from 5-point scale from the viewpoint of technical feasibility.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-col md="4">
                      <v-select
                        dense
                        v-model=fields.reports.technical_score
                        :items=score_list
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="brown"
                        background-color="white"
                        flat
                      ></v-select>
                      <div v-if="errors.technical_score" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            This field is required.
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </td>
                </tr>
                <tr>
                  <th>Science Comment Strength
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Science Comment Strength</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Write your review comment of strength points of the reviewed proposal from the viewpoint of scientific merit.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.reports.science_comment_strength
                    ></v-textarea>
                  </td>
                </tr>
                <tr>
                  <th>Science Comment Weakness
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Science Comment Weakness</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Write your review comment of weakness points of the reviewed proposal from the viewpoint of scientific merit.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.reports.science_comment_weakness
                    ></v-textarea>
                  </td>
                </tr>
                <tr>
                  <th>Technical Comment Strength
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Technical Comment Strength</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Write your review comment of strength points of the reviewed proposal from the viewpoint of technical feasibility.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.reports.technical_comment_strength
                    ></v-textarea>
                  </td>
                </tr>
                <tr>
                  <th>Technical Comment Weakness
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600">
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" color="black" class="ml-2">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Technical Comment Weakness</v-card-title>
                        <v-card-text>
                          <div class="pa-4">
                            <p>
                              Write your review comment of weakness points of the reviewed proposal from the viewpoint of technical feasibility.
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </th>
                  <td class="pt-3 pb-4">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model=fields.reports.technical_comment_weakness
                    ></v-textarea>
                  </td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        @click="cancel"
        :disabled="buttonStatus"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="submit"
      >
        Save
      </v-btn>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Waiting...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      early_career: 0,
      loading: false,
      buttonStatus: false,
      fields: {
        reports:{
          science_score: null,
          proposal: {
            title: '',
          },
        },
      },
      score_list: [],
      breadcrumbs: [],
      id: this.$route.params.id,
    };
  },
  mounted() {
    (async () => {
      this.$axios
      .get(`/api/assigned_proposals/${this.$route.params.id}`)
      .then(response => {
        this.fields = response.data;
      }),
      this.$axios
        .get("/api/masters/formArray/score_list")
        .then((response) => {
          this.score_list.push({id: '', label: ''});
          for(let d in response.data) {
            this.score_list.push({id: Number(d), label: response.data[d]});
          }
      });
    })();
  },
  methods: {
    cancel() {
      this.$router.push({
        path: `/reports/detail/${this.$route.params.id}`,
        query:{tab: 'tab-4'}
      });
    },
    submit() {
      this.loading = true;
      this.buttonStatus = true;
      this.errors = {};
      this.$axios.put(`/api/reports/${this.$route.params.id}`, this.fields.reports).then(response => {
        if (response.data.result == true) {
          this.$store.commit("setMessage", {
            text: 'Success',
            color: "success",
          });
          this.$router.push({
            path: `/reports/detail/${this.$route.params.id}`,
            query:{tab: 'tab-4'}
          });
        }
      })
      .catch((error) => {
        this.loading = false;
        this.buttonStatus = false;
        if (error.response.status === 400) {
          this.errors = error.response.data.errors;
        } else if (error.response.status == 403) {
          this.$router.replace({
              path: "/403",
          });
        }
      });
    }
  }
 };
</script>
