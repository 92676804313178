<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Proposer List</h2>
      <div class="pt-3 pr-2">
        <v-btn small dark color="blue darken-4" to="/proposers/collaborators"
          >Proposer &amp; Collaborator List</v-btn
        >
      </div>
      
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="10">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-6" justify="start">
            <v-col>
              <v-text-field
                label="Collaborator Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.collaborator_name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Collaborator Email"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.collaborator_email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Collaborator Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.collaborator_affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="px-6 d-flex justify-start">
            <v-checkbox
              hide-details
              label="Not Created"
              class="ma-4 ml-0"
              v-model = searchForm.not_created
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Not Submitted"
              class="ma-4"
              v-model = searchForm.not_submitted
            ></v-checkbox>
            <v-checkbox
              hide-details
              label="Submitted"
              class="ma-4"
              v-model = searchForm.submitted
            ></v-checkbox>

            <v-col cols="2" md="2">
              <v-select
                label="Term"
                dense
                v-model = searchForm.term
                :items = terms
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
              ></v-select>
            </v-col>

            <v-col cols="2" md="4">
              <v-select
                label="Early Career"
                dense
                v-model = searchForm.early_career
                :items = earlyCareer
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
              ></v-select>
            </v-col>
          </div>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="proposerSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/proposers?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <div class="d-flex justify-space-between align-end mb-5">
          <div class="text-caption">{{total}} results</div>
            <v-btn color="deep-orange darken-1" dark @click="downLoad()"
            ><v-icon>mdi-download</v-icon>Download CSV</v-btn
            >
        </div>
        <div>
          <v-card>
            <v-data-table
              :items="items"
              :headers="headers"
              :page.sync="page"
              hide-default-footer
              no-data-text="No data"
              disable-pagination
            >
            <template v-slot:item="{ item }">
                <tr v-bind:class="{ disabled: item.disabled }">
                  <td>
                    <v-btn
                      x-small
                      outlined
                      :to="'/proposers/detail/' + item.user_id"
                      >DETAIL</v-btn
                    >
                  </td>
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.email}}<br>
                    {{ item.affiliation }}
                  </td>
                  <td>{{ earlyCareerShort[item.early_career] }}</td>
                  <td class="py-3">
                    <div v-if="item.proposal_collaborator.length < 3">
                      <span v-for="(collaborator, index) in item.proposal_collaborator" :key="collaborator.id">
                        {{index + 1}}:{{ collaborator.name }} ({{ collaborator.email }}) {{ collaborator.affiliation }} <br />
                      </span>
                    </div>
                    <div v-else>
                      <span v-for="(collaborator, index) in item.proposal_collaborator" :key="collaborator.id">
                        <div v-if="index < 3" >
                          {{index+1}}:{{ collaborator.name }} ({{ collaborator.email }}) {{ collaborator.affiliation }}
                        </div>
                        <div v-else>
                          <div v-if="isActive">
                              {{index+1}}:{{ collaborator.name }} ({{ collaborator.email }}) {{ collaborator.affiliation }}
                          </div>
                        </div>
                      </span>
                      <div class="text-right">
                        <span class="button" @click="active">...[detailed]</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="ml-4">
                      <b v-if="item.status == 0">-</b>
                      <p v-else-if="item.status == 1"><b class="red--text text--darken-4">Not Submitted</b></p>
                      <p v-else>
                        {{ item.id }}:<router-link :to="{name: 'ProposalsDetail', params: {id: item.id}}">{{ item.title }}</router-link> <b class="green--text text--darken-4">Submitted</b>
                      </p>
                    </span>
                  </td>
                </tr>
            </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="emitChangePage()"
              circle
              color="blue darken-4"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      name: null,
      buttonStatus: false,
      registDialog: false,
      errors: [],
      terms:[],
      earlyCareer:[],
      earlyCareerShort: [],
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "",
        },
        {
          text: "E-mail / Affiliation",
          sortable: false,
          value: "email affiliation",
          width: "",
        },
        {
          text: "Career",
          sortable: false,
          value: "career",
          width: "",
        },
        {
          text: "Collaborator",
          sortable: false,
          value: "collaborator",
          width: "",
        },
        {
          text: "Proposal",
          sortable: false,
          value: "proposal",
          width: "",
        },
      ],
      searchForm: {
        //検索のデフォルト値
        name: "",
        affiliation: "",
        email: "",
        collaborator_name: "",
        collaborator_email: "",
        collaborator_affiliation: "",
        not_created: true,
        not_submitted: true,
        submitted: true,
        term: null,
        early_career: null,
      },
      searchScheme: [
        "name",
        "affiliation",
        "email",
        "collaborator_name",
        "collaborator_email",
        "collaborator_affiliation",
        "not_created",
        "not_submitted",
        "submitted",
        "term",
        "early_career",
      ], //検索条件
      searchURL: "/api/proposers",
      isActive: false,
    };
  },
  filters: {
    omitTitle(title) {
      const words = title.split(/[\s]/);
      let trimmedTitle = '';
      if(words.length > 5){
        for(let i = 0; i < 5; i++){
          trimmedTitle += words[i] + ' ';
        }
      }else{
        words.forEach(value => {
          trimmedTitle += value + ' ';
        });
      }
      return trimmedTitle;
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get("/api/masters/formArray/early_career_short")
        .then((response) => {
          this.earlyCareerShort = response.data;
      });
      this.$axios
        .get("/api/masters/early_career_type")
        .then((response) => {
          this.earlyCareer.push({id: null, label: "-"});
          for(let k in response.data) {
            this.earlyCareer.push({id: response.data[k].id, label: response.data[k].label});
          }
      });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    proposerSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    async downLoad(){
      try {
        const res = await this.$axios.get('/api/proposers/download',{
          params: {
            name: this.searchForm.name,
            affiliation: this.searchForm.affiliation,
            email: this.searchForm.email,
            collaborator_name: this.searchForm.collaborator_name,
            collaborator_email: this.searchForm.collaborator_email,
            collaborator_affiliation: this.searchForm.collaborator_affiliation,
            not_created: this.searchForm.not_created,
            not_submitted: this.searchForm.not_submitted,
            submitted: this.searchForm.submitted,
            term: this.searchForm.term,
            early_career: this.searchForm.early_career,
          }
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
    changeStatus(status){
      this.searchForm.status = status;
    },
  },
  watch: {
    loading(val) {
      if (!val) return;

      //setTimeout(() => ((this.loading = false), (this.register = true)), 1000);
    },
  },
};
</script>