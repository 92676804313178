<template>
  <v-container grid-list-md>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Keyword Master</h2>
      <div class="pt-2 pr-2">
        <v-btn
          dark
          color="blue darken-4"
          class="mr-3"
          to="/masters/keywords/add"
          ><v-icon class="mr-2">mdi-plus</v-icon>ADD</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>

      <v-row justify="center" align="center" class="mt-4 mb-5">
        <v-col lg="8">
          <v-card>
            <v-data-table
              :items="items"
              :headers="headers"
              :sort-by="['sort']"
              hide-default-footer
              no-data-text="No data"
              disable-pagination
            >
              <template v-slot:item="{ item }">
                  <tr v-bind:class="{ disabled: item.disabled }">
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.sort }}</td>
                    <td>{{ item.selected_keywords.length }}</td>
                    <td v-if="item.hidden === 0"> </td>
                    <td v-else>X</td>
                    <td>
                      <v-btn
                        color="primary"
                        fab
                        x-small
                        outlined
                        class="mx-1"
                        :to="'/masters/keywords/edit/' + item.id"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                  </tr>
              </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="emitChangePage()"
              circle
              color="blue darken-4"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Search from "../../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      breadcrumbs: [
        {
          text: "Masters",
          disabled: false,
          href: "/masters",
        },
        {
          text: "Keyword Master",
          disabled: true,
          href: "/masters/keywords",
        },
      ],
      errors: [],
      headers: [
        {
          text: "ID",
          sortable: false,
          value: "id",
          width: "10%",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "30%",
        },
        {
          text: "Sort",
          sortable: false,
          value: "sort",
          width: "15%",
        },
        {
          text: "Count",
          sortable: false,
          value: "count",
          width: "15%",
        },
        {
          text: "Hidden",
          sortable: false,
          value: "hidden",
          width: "15%",
        },
        {
          text: "",
          sortable: false,
          width: "15%",
        }
      ],
      searchURL: "/api/keywords", //一覧取得APIのURL
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.list(false);
    })();
  },
  methods: {
    list(scrollFlg) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //ページングがクリックされた時
    changePage(value) {
        this.page = value.page;
        this.pageCount = value.pageCount;
        this.itemsPerPage = value.itemsPerPage;
        let query = { page: 1 };
        query.page = value.page;
        this.search(query);
    },
  },
};
</script>

<style lang="scss">
.v-data-table {
  tbody {
    tr.disabled {
      background-color: #ddd !important;
    }
  }
}
</style>

