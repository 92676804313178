<template>
  <v-container grid-list-md>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Assign Samples (
        Particle &amp; Aggregate
        / <a href="/samples/assign/gas">Gas</a>
        / <a href="/samples/assign/processed">Previously allocated</a>
        )
      </h2>
      <div class="pt-3 pr-2">
        <div class="d-flex justify-space-between align-end mb-5">
            <v-btn color="deep-orange darken-1 ml-2" dark @click="downLoad()"
            ><v-icon>mdi-download</v-icon>Download CSV</v-btn
            >
        </div>
      </div>
    </div>
    <v-divider></v-divider>
      <v-row justify="center" align="center" class="mt-5 mb-5 px-6">
        <v-col>
          <v-simple-table height="780px" id="table">
            <thead>
              <tr>
                  <th class="row-header" style="padding:0px;">
                    <table class="inner-table" style="width: 670px; height:100%;">
                      <tr>
                        <td style="width:200px;">
                          Proposal
                        </td>
                        <td class="row-header" >Score<br>Rank</td>
                        <td class="row-header" style="width: 150px;">Requested<br>sample</td>
                        <td class="row-header" style="width: 150px;">Remarks</td>
                        <td class="row-header" style="width: 80px;">Assigned<br>sample</td>
                      </tr>
                    </table>
                  </th>
                  <th v-for="(value, key) in sample_lists" :key="key" style="white-space: nowrap;">
                    {{value.name}}
                    <br><span class="text-caption">{{value.weight}} mg</span>
                    <br><span class="text-caption" v-if="!isNaN(value.size)">{{value.size | sizeMM}} mm</span>
                    <br><span class="text-caption" v-if="value.sample_type == 0">Particle</span>
                    <span class="text-caption" v-if="value.sample_type == 1">Aggregate</span>
                  </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, key) in proposals" :key="key" :isAdmin="isAdmin">
                  <th class="row-header" style="padding:0px;">
                    <table class="inner-table" style="width: 670px; height:100%;">
                      <tr>
                        <td style="width:200px;">
                          <a
                            :href="'/proposals/detail/' + value.id"
                            target="_blank"
                            style="display:block;width:100%; word-break: break-word; word-wrap: break-word;"
                          >
                            {{value.id}}. {{value.name}}
                          </a>
                          <span class="text-body-2">{{value.title}}</span>
                        </td>
                        <td>
                          <!-- Score & Rank -->
                          <span v-if="value.global_score > 4" class="red--text font-weight-bold text-h6">{{value.global_score}}</span>
                          <span v-else-if="value.global_score > 3" class="orange--text font-weight-bold text-h6">{{value.global_score}}</span>
                          <span v-else-if="value.global_score > 2" class="green--text font-weight-bold text-h6">{{value.global_score}}</span>
                          <span v-else-if="value.global_score > 1" class="blue--text font-weight-bold text-h6">{{value.global_score}}</span>
                          <span v-else>{{value.global_score}}</span>
                          <br>{{value.rank}}
                        </td>
                        <td style="width: 150px;">
                          <!-- Requested Samples -->
                          <div v-for="(req, key2) in value.requests" :key="key2">
                            <span v-if="req.target">
                              {{req.priority}}. 
                              <span v-if="req.any">Any 
                                <span v-if="req.weight != null">({{req.weight}} mg)</span>
                                <span v-if="req.size != null">({{req.size | sizeMM}} mm)</span>
                                <span v-if="req.chamber == 0">[Any]</span>
                                <span v-if="req.chamber == 1">[A]</span>
                                <span v-if="req.chamber == 2">[C]</span>
                              </span>
                              <span v-else>{{samples[req.sample]}}</span>
                              <br>
                            </span>
                            <span v-else class="text-caption">
                              ({{req.priority}}. 
                              <span v-if="req.any">Any</span>
                              <span v-else>{{samples[req.sample]}}</span>)
                              <br>
                            </span>
                          </div>
                        </td>
                        <td style="width: 150px;" class="text-body-2">
                          <!-- Remarks -->
                          {{value.properties}}
                        </td>
                        <td style="width: 80px;" class="text-body-2">
                          <!-- Assigned Samples -->
                          <div v-for="(sv, sk) in requests" :key="sk">
                            <span v-for="(ap,ak) in sv.assigned" :key="ak">
                              <span v-if="ap == value.id">
                                {{sv.name}}
                              </span>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </th>
                  <td v-for="(sv, sk) in requests" :key="sk">
                    <v-col align-content="center" justify="center">
                      <span v-if="sv.data[key]==null"></span>
                      <span v-else-if="sv.data[key].priority==1">
                          <span class="red--text font-weight-bold text-h5">{{sv.data[key].priority}}</span>
                      </span>
                      <span v-else-if="sv.data[key].priority==2">
                          <span class="pink--text font-weight-bold text-h6">{{sv.data[key].priority}}</span></span>
                      <span v-else-if="sv.data[key].priority==3">
                          <span class="deep-orange--text font-weight-bold text-subtitle-1">{{sv.data[key].priority}}</span></span>
                      <span v-else-if="sv.data[key].priority==4">
                          <span class="orange--text font-weight-bold">{{sv.data[key].priority}}</span></span>
                      <span v-else-if="sv.data[key].priority==5">
                          <span class="green--text font-weight-bold">{{sv.data[key].priority}}</span></span>
                      <span v-else>
                          <span class="green--text font-weight-bold">{{sv.data[key].priority}}</span></span>
                    </v-col>
                    <v-row align-content="center" justify="center">
                      <v-checkbox v-if="isAdmin" class="pa-0 mt-1 ml-2" v-model="sv.assigned" :value="value.id">
                      </v-checkbox>
                    </v-row>
                  </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="text-center my-6">
        <v-btn v-if="isAdmin" class="font-weight-bold mr-4" color="green darken-4" large dark @click="showDialog = true,buttonStatus = true" :disabled="buttonStatus">
          Save
        </v-btn>
      </div>
      <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to save samples?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false,buttonStatus = false">NO</v-btn>
          <v-btn @click="save()" color="black" dark>YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sampleLoading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Loading samples...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<style>
#table {  
  white-space: pre-line;
}
#table thead tr td,
#table thead tr th,
#table tbody tr td,
#table tbody tr th{
  width: 100px;
  text-align: center;
  border: 0.5px solid rgb(241, 241, 241);
}
th.row-header {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
thead{
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}
table.inner-table {
  border-collapse: collapse;
  margin: 0px;
  border: 0px;
}
</style>

<script>
import Search from "../../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      showDialog: false,
      loading: false,
      sampleLoading:true,
      buttonStatus: false,
      isAdmin: false,
      errors: [],
      samples : [],
      sample_lists : [],
      sample_ids: [],
      proposals: [
        //{id: 1, name: "Taro Suzuki", global_score: 5, average_score: 4.8, requests:[{priority:1, sample:"A00001"},{priority:2, sample:"A00003"},{priority:3, sample:"A00002"},{priority:4, sample:"A00005"},{priority:5, sample:"A00008"}], number: "2", weight:"3.0 mg (Each)", properties:"oreuon oorencoae oruenocau ereou"},
      ],
      requests: [
      ],
      breadcrumbs: [
        {
          text: "Sample List",
          disabled: false,
          href: "/samples",
        },
        {
          text: "Assign Samples",
          disabled: true,
          href: "/samples/assign",
        },
      ],
    }
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getSamples`)
        .then(response => {
          for(let kw in response.data) {
            this.samples[response.data[kw].id] = response.data[kw].name;
          }
          this.sampleLoading = false;
        });
      this.$axios
        .get(`/api/proposals/allocation/1`)
        .then(response => {
          for(let kw in response.data) {
            var properties = (response.data[kw].properties.length > 30) ? response.data[kw].properties.substring(0,30) + '...' : response.data[kw].properties;
            var prop = {
              id: response.data[kw].id,
              name: response.data[kw].name,
              title: response.data[kw].title,
              global_score: response.data[kw].global_score,
              rank: response.data[kw].rank,
              requests:[
              ],
              properties: properties
            };
            for(let s in response.data[kw].requested_samples) {
              if (response.data[kw].requested_samples[s].sample_type == 0 || response.data[kw].requested_samples[s].sample_type == 1) {
                prop.requests.push({
                  priority: response.data[kw].requested_samples[s].priority,
                  sample: response.data[kw].requested_samples[s].sample_id,
                  any: response.data[kw].requested_samples[s].sample_id ? false: true,
                  weight: response.data[kw].requested_samples[s].weight,
                  size: response.data[kw].requested_samples[s].size,
                  chamber: response.data[kw].requested_samples[s].chamber,
                  target: true
                });
              } else {
                prop.requests.push({
                  priority: response.data[kw].requested_samples[s].priority,
                  sample: response.data[kw].requested_samples[s].sample_id,
                  any: response.data[kw].requested_samples[s].sample_id ? false: true,
                  target: false
                });
              }
            }
            this.proposals.push(prop);
          }
        });
        
      this.$axios
        .get(`/api/samples/allocation/1`)
        .then(response => {
          console.log(response.data);
          for(let kw in response.data) {
            this.sample_lists.push({
              id: response.data[kw].id, 
              name: response.data[kw].name,
              sample_type: response.data[kw].sample_type,
              weight: response.data[kw].weight,
              size: response.data[kw].size,
            });
            this.sample_ids.push({ id: response.data[kw].id, checked: false});
            this.requests.push({
              id: response.data[kw].id, 
              name: response.data[kw].name,
              sample_type: response.data[kw].sample_type,
              weight: response.data[kw].weight,
              size: response.data[kw].size,
              data: response.data[kw].requested,
              assigned: response.data[kw].assigned,
            });
          }
          this.sampleLoading = false;
        });
    })();
    this.isAdmin = this.$store.state.auth.authrole.includes('admin');
  },
  filters: {
    sizeMM(value) {
      const size = value / 1000;
      return size.toFixed(3);
    }
  },
  methods: {
    async downLoad(){
      try {
        const res = await this.$axios.get('/api/samples/download/1',{
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
    save() {
      this.loading = true;
      var assigned_proposals = [];
      for(let key in this.requests) {
        if (this.requests[key].assigned != null && this.requests[key].assigned.length > 0) {
          for (let pid in this.requests[key].assigned) {
            assigned_proposals.push({
              'sample_id':this.requests[key].id,
              'proposal_id': this.requests[key].assigned[pid],
              'sample_type': this.requests[key].sample_type
            });
          } 
        }
      }
      this.$axios
        .post(`/api/samples/allocation`,assigned_proposals)
        .then((response) => {
          this.showDialog = false;
          this.loading = false;
          this.buttonStatus = false;
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }else if(response.data.result == false){
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.showDialog = false;
            this.loading = false;
            this.buttonStatus = false;
            this.$store.commit("setMessage", {
              text: this.errors,
              color: "error",
            });
          }
        });
    }
  }
}
</script>