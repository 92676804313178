<template>
  <v-container grid-list-md>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <h2 class="py-2 px-6">Proposer &amp; Collaborator List</h2>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="10">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.affiliation
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="px-6 d-flex justify-start">
            <v-col cols="2" md="2">
              <v-select
                label="Term"
                dense
                :items=terms
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
                v-model = searchForm.term
              ></v-select>
            </v-col>
          </v-row>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="peopleSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/proposers/collaborators?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <div>
          <div class="d-flex justify-space-between align-end mb-5">
            <div class="text-caption">{{ total }} results</div>
            <v-btn color="deep-orange darken-1" dark @click="downLoad()"
              ><v-icon>mdi-download</v-icon>Download CSV</v-btn
            >
          </div>
          <v-card>
            <v-data-table
              :items="items"
              :headers="headers"
              :page.sync="page"
              hide-default-footer
              no-data-text="No data"
              disable-pagination
            >
            <template v-slot:item="{ item }">
              <tr v-bind:class="{ disabled: item.disabled }">
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.affiliation }}</td>
                <td v-if="item.proposal_id">
                  <router-link :to="{name:'ProposalsDetail',params:{id:item.proposal_id}}" >Proposal ID:{{ item.proposal_id }}</router-link></td>
                <td v-else></td>
                <td v-if="item.proposal_collaborator_id[0]">
                  <span v-for="(collaborator) in item.proposal_collaborator_id" :key="collaborator.id">
                    <router-link :to="{name:'ProposalsDetail',params:{id:collaborator}}" >Proposal ID:{{ collaborator }}<br></router-link>
                  </span>
                </td>
                <td v-else></td>
              </tr>
            </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="emitChangePage()"
              circle
              color="blue darken-4"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      name: null,
      buttonStatus: false,
      registDialog: false,
      terms:[],
      errors: [],
      proposals: [],
      collaborateProposals: [],
      breadcrumbs: [
        {
          text: "Proposer List",
          disabled: false,
          href: "/proposers",
        },
        {
          text: "Proposer & Collaborator List",
          disabled: true,
          href: "/#/proposers/collaborator",
        },
      ],
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "",
        },
        {
          text: "E-mail",
          sortable: false,
          value: "email",
          width: "",
        },
        {
          text: "Affiliation",
          sortable: false,
          value: "affiliation",
          width: "",
        },
        {
          text: "Proposal",
          sortable: false,
          value: "proposal",
          width: "",
        },
        {
          text: "Collaborator",
          sortable: false,
          value: "collaborator",
          width: "",
        },
      ],
      searchForm: {
        //検索のデフォルト値
        name: "",
        affiliation: "",
        email: "",
        term: null,
      },
      searchScheme: [
        "name",
        "affiliation",
        "email",
        "term",
      ], //検索条件
      searchURL: "/api/people",
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    peopleSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    async downLoad(){
      try {
        const res = await this.$axios.get('/api/people/download',{
          params: {
            name: this.searchForm.name,
            affiliation: this.searchForm.affiliation,
            email: this.searchForm.email,
            term: this.searchForm.term,
          }
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
  },
};
</script>