<template>
  <v-card color="basil" flat>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <th width="20%">Science Score</th>
              <td width="30%">{{ fields.reports.science_score }}</td>
              <th width="20%">Technical Score</th>
              <td width="30%">{{ fields.reports.technical_score }}</td>
            </tr>
            <tr>
              <th>Science Comment Strength</th>
              <td colspan="3" class="nl2br">
                {{ fields.reports.science_comment_strength }}
              </td>
            </tr>
            <tr>
              <th>Science Comment Weakness</th>
              <td colspan="3" class="nl2br">
                {{ fields.reports.science_comment_weakness }}
              </td>
            </tr>
            <tr>
              <th>Technical Comment Strength</th>
              <td colspan="3" class="nl2br">
                {{ fields.reports.technical_comment_strength }}
              </td>
            </tr>
            <tr>
              <th>Technical Comment Weakness</th>
              <td colspan="3" class="nl2br">
                {{ fields.reports.technical_comment_weakness }}
              </td>
            </tr>
            <tr v-if="fields.reports.submitted_at !== null">
              <th>
                Submit Date
              </th>
              <td>
                {{ fields.reports.submitted_at | moment("YYYY-MM-DD HH:mm") }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center my-6" v-if="user_type != 'proposer'">
        <v-btn
          class="font-weight-bold mr-4"
          color="green darken-4"
          dark
          large
          v-if="editCheck"
          :disabled="buttonStatus"
          :href="`/reports/edit/${fields.reports.id}`"
        >
          Edit
        </v-btn>
        <v-btn
          class="font-weight-bold ml-5"
          color="orange darken-4"
          dark
          large
          v-if="submitCheck"
          :disabled="buttonStatus"
          @click="showDialog = true"
        >
          Submit
        </v-btn>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card color="black" dark>
            <v-card-text>
              Waiting...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-card-text>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Are you sure to submit?<br>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false">Cancel</v-btn>
          <v-btn @click="submit" color="black" dark>Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: 'tabReport',
  props: ['fields', 'user_type', 'submitCheck', 'editCheck'],
  data() {
    return {
      showDialog: false,
      loading: false,
      buttonStatus: false,
    };
  },
  methods: {
    submit(){
      this.showDialog = false;
      this.$parent.editCheck = false;
      this.loading = true;
      this.buttonStatus = true;
      this.$axios
        .put(`/api/reports/submit/${this.fields.reports.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
          }
          this.loading = false;
          this.buttonStatus = false;
        });
    },
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
};
</script>
