<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Proposal List</h2>
      <div class="pt-3 pr-2">
        <v-btn small dark color="blue darken-4" to="/proposals/reviewers"
          >Proposal Reviewer List</v-btn
        >
        <v-btn small dark color="blue darken-4 ml-2" to="/proposals/assigned_samples"
          >Assigned Samples List</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="12">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-6" justify="start">
            <v-col>
              <v-text-field
                label="Title"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.title
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Abstract"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.abstract
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-select
                label="Keywords"
                dense
                :items = keywords_name
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
                v-model = searchForm.keywords
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="px-6" justify="start" align="center">
            <v-col md="2" class="d-flex justify-end"> Status: </v-col>
            <v-col md="8">
              <div class="d-flex justify-start align-center">
                <v-checkbox
                  hide-details
                  label="Not Submitted"
                  class="mr-4 mt-0"
                  v-model="searchForm.not_submitted"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Submitted"
                  class="mr-4 mt-0"
                  v-model="searchForm.submitted"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Scored"
                  class="mt-0"
                  v-model="searchForm.scored"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col md="2">
              <v-select
                label="Term"
                dense
                :items = terms
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
                v-model = searchForm.term
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="px-6 mb-5" justify="start" align="center">
            <v-col md="2" class="d-flex justify-end">Global Score: </v-col>
            <v-col md="10">
              <div class="d-flex justify-space-between align-center">
                <div class="d-flex">
                <v-checkbox
                  hide-details
                  label="Excellent"
                  class="mr-5 mt-0"
                  v-model="searchForm.excellent"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Very Good"
                  class="mr-5 mt-0"
                  v-model="searchForm.very_good"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Good"
                  class="mr-5 mt-0"
                  v-model="searchForm.good"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Fair"
                  class="mr-5 mt-0"
                  v-model="searchForm.fair"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Poor"
                  class="mr-5 mt-0"
                  v-model="searchForm.poor"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="(Not Scored)"
                  class="mt-0"
                  v-model="searchForm.not_scored"
                ></v-checkbox>
                </div>
                <div>
                  <v-checkbox
                    hide-details
                    label="Unlocked"
                    class="mr-5 mt-0"
                    v-model="searchForm.is_unlocked"
                  ></v-checkbox>
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="proposalSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/proposals?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <template>
          <div>
            <div class="d-flex justify-space-between align-end mb-5">
              <div class="text-caption">{{ total }} results</div>
              <div>
                <v-btn color="green darken-4" dark @click="showDialog = true" class="mr-5" v-if="isAdmin"
                  ><v-icon>mdi-download</v-icon>Download all PDF</v-btn
                >
                <v-btn color="deep-orange darken-1" dark @click="downLoad"
                  ><v-icon>mdi-download</v-icon>Download CSV</v-btn
                >
              </div>
            </div>
            <v-card>
              <v-data-table
                :items="items"
                :headers="headers"
                :page.sync="page"
                hide-default-footer
                no-data-text="No data"
                disable-pagination
              >
                <template v-slot:item="{ item }">
                  <tr v-if="item.status !== 0"><!-- status = 0 （初期状態）の proposal は対象に含めない。 -->
                    <td>
                      <v-btn
                        x-small
                        outlined
                        :to="'/proposals/detail/' + item.id"
                        >DETAIL</v-btn
                      >
                    </td>
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.email }}<br>
                      {{ item.affiliation }}
                    </td>
                    <td>{{ item.id }}: {{ item.title }}</td>
                    <td>{{ item.shortAbstract }}</td>
                    <td>
                      <span v-for="k in item.selected_keywords" :key="k.id">
                        {{ k.keyword_other }}<br>
                      </span>
                    </td>
                    <td v-if="item.progress === 1">
                      <b class="red--text text--darken-4" x-small dark>{{ status[item.progress] }}</b>
                    </td>
                    <td v-else-if="item.progress === 2">
                      <b color="black--text text--darken-4" x-small dark>{{ status[item.progress] }}</b>
                    </td>
                    <td v-else-if="item.progress === 3">
                      <b color="greens--text text--darken-4" x-small dark>{{ status[item.progress] }}</b>
                    </td>
                    <td v-else-if="item.progress === 4">
                      <b color="red--text text--darken-4" x-small dark>{{ status[item.progress] }}</b>
                    </td>
                    <td v-else-if="item.progress === 5">
                      <b class="blue--text text--darken-4" x-small dark>{{ status[item.progress] }}</b>
                    </td>
                    <td v-else></td>
                    <td v-if="item.global_score === null">
                      -
                    </td>
                    <td v-else-if="item.global_score <= 1">
                      <b class="green--text text--darken-4" x-small dark>{{ global_score[1] }}</b>
                    </td>
                    <td v-else-if="1 < item.global_score && item.global_score <= 2">
                      <b class="red--text text--darken-4" x-small dark>{{ global_score[2] }}</b>
                    </td>
                    <td v-else-if="2 < item.global_score && item.global_score <= 3">
                      <b class="gray--text text--darken-4" x-small dark>{{ global_score[3] }}</b>
                    </td>
                    <td v-else-if="3 < item.global_score && item.global_score <= 4">
                      <b class="yellow--text text--darken-4" x-small dark>{{ global_score[4] }}</b>
                    </td>
                    <td v-else-if="4 < item.global_score">
                      <b class="blue--text text--darken-4" x-small dark>{{ global_score[5] }}</b>
                    </td>
                    <td v-else></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
            <div class="text-center pt-5">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @input="emitChangePage()"
                circle
                color="blue darken-4"
              ></v-pagination>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you want to download all PDFs displayed on this page?
            (This process may takes a long time.)
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false">Cancel</v-btn>
          <v-btn @click="downLoadPDF" color="black" dark>Download</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      errors: [],
      terms:[],
      keywords_name: [],
      status: [],
      global_score: [],
      showDialog: false,
      isAdmin: false,
      //検索条件
      searchForm: {
        //検索のデフォルト値
        name: "",
        email: "",
        affiliation: "",
        title: "",
        abstract: "",
        keywords: null,
        term: null,
        not_submitted: true,
        submitted: true,
        assigned: true,
        reviewed: true,
        scored: true,
        excellent: true,
        very_good: true,
        good: true,
        fair: true,
        poor: true,
        not_scored: true,
        is_unlocked: false,
      },
      //search用
      searchScheme: [
        "name",
        "email",
        "affiliation",
        "title",
        "abstract",
        "keywords",
        "term",
        "not_submitted",
        "submitted",
        "assigned",
        "reviewed",
        "scored",
        "excellent",
        "very_good",
        "good",
        "fair",
        "poor",
        "not_scored",
        "is_unlocked",
        ], //検索条件
      searchURL: "/api/proposals", //一覧取得APIのURL
       //data-tableに表示するheaders<th>の設定
      headers: [
        {
            text: "",
            sortable: false,
            width: "5%",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "10%",
        },
        {
          text: "Email / Affiliation",
          sortable: false,
          value: "email affiliation",
          width: "10%",
        },
        {
          text: "ID: Title",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Abstract",
          sortable: false,
          value: "abstract",
          width: "25%",
        },
        {
          text: "Keywords",
          sortable: false,
          value: "keywords",
          width: "10%",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: "5%",
        },
        {
          text: "Global Score",
          sortable: false,
          value: "global score",
          width: "5%",
        },
      ],
      isActive: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term = response.data.id;
          this.list(false,this.searchForm.term);
        });
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get(`/api/masters/Keyword`)
        .then(response => {
          for(let kw in response.data) {
            this.keywords_name.push({id: response.data[kw].id, label: response.data[kw].label});
          }
        });
      this.$axios
        .get("/api/masters/formArray/proposal_progress_list")
        .then((response) => {
          this.status = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/global_score_list")
        .then((response) => {
          this.global_score = response.data;
      });
      this.isAdmin = this.$store.state.auth.authrole.includes('admin');
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    //検索ボタンがクリックされた時
    proposalSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    async downLoad(){
      try {
        //動作確認用
        const res = await this.$axios.get('/api/proposals/download');
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
    downLoadPDF(){
      //PDFの全件ダウンロード
      this.showDialog = false;
      try {
          for(let key in this.items) {
            if (this.items[key].status == 2) {
              //提出済みのPDFのみ
              //連続でダウンロードさせる
              this.$axios({
                  url: '/api/proposals/download_reports/'+ this.items[key].id,
                  method: 'get',
                  responseType: 'blob',
              })
              .then(response => {
                  const blob = new Blob([response.data]);
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.setAttribute('download', 'proposal_' + this.items[key].id + '.zip');
                  document.body.appendChild(link);
                  link.click();
              })
              .catch(error => {
                console.log(error);
              });
            }
          }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    }
  },
};
</script>
