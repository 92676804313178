<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn onClick="window.history.back();" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
              <span>{{fields.title}}</span>
            </v-card-title>
            <v-card-subtitle v-if="fields.date" class="text-right">{{fields.date | moment("YYYY-MM-DD") }}</v-card-subtitle>
            <v-card-text class="pa-4 text-body-1 nl2br">
              {{fields.content}}
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      breadcrumbs: [
      ],
      fields: {},
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/information/show/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;
        });
    })();
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
};
</script>