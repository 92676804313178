<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/users" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>User Edit</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Name</th>
                  <td class="py-2">
                    <div class="d-flex justify-space-between">
                      <div class="v-input__control" style="width: 58%;">
                        <v-text-field
                          outlined
                          label="First Name*"
                          background-color="white"
                          color="black"
                          placeholder="Taro"
                          hide-details
                          dense
                          v-model="fields.first_name"
                          :rules="[() => !!fields.first_name || 'This field is required']"
                          :error-messages="
                            errors.first_name ? errors.first_name[0] :''
                          "
                        ></v-text-field>
                        <div v-if="errors && errors.first_name" class="text-danger v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              {{ errors.first_name[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="v-input__control" style="width: 38%; margin-left: 2%">
                        <v-text-field
                          outlined
                          label="Middle Name"
                          background-color="white"
                          color="black"
                          hide-details
                          dense
                          v-model="fields.middle_name"
                          :error-messages="
                            errors.middle_name ? errors.middle_name[0] :''
                          "
                        ></v-text-field>
                        <div v-if="errors && errors.middle_name" class="text-danger v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              {{ errors.middle_name[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="v-input__control" style="width: 58%; margin-left: 2%">
                        <v-text-field
                          outlined
                          label="Last Name*"
                          background-color="white"
                          color="black"
                          placeholder="Suzuki"
                          hide-details
                          dense
                          v-model="fields.last_name"
                          :rules="[() => !!fields.last_name || 'This field is required']"
                          :error-messages="
                            errors.last_name ? errors.last_name[0] :''
                          "
                        ></v-text-field>
                        <div v-if="errors && errors.last_name" class="text-danger v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              {{ errors.last_name[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="v-input__control" style="width: 38%; margin-left: 2%">
                        <v-text-field
                          outlined
                          label="Suffix"
                          background-color="white"
                          color="black"
                          hide-details
                          dense
                          v-model="fields.suffix"
                          :error-messages="
                            errors.suffix ? errors.suffix[0] :''
                          "
                        ></v-text-field>
                        <div v-if="errors && errors.suffix" class="text-danger v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              {{ errors.suffix[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td class="py-2">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.email"
                        :rules="[() => !!fields.email || 'This field is required']"
                        :error-messages="
                          errors.email ? errors.email[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.email" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.email[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Affiliation</th>
                  <td class="py-2">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.affiliation"
                        :error-messages="
                          errors.affiliation ? errors.affiliation[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.affiliation" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.affiliation[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Authorization</th>
                  <td class="pt-3 pb-4">
                    <v-checkbox
                      v-model="fields.is_administration_office"
                      label="Administration Office"
                      hide-details
                      dense
                      color="blue darken-4"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="fields.is_panel"
                      label="Panel"
                      hide-details
                      dense
                      color="blue darken-4"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="fields.is_reviewer"
                      label="Reviewer"
                      hide-details
                      dense
                      color="blue darken-4"
                    ></v-checkbox>
                  </td>
                </tr>
                <tr>
                  <th>Disabled</th>
                  <td class="pt-3 pb-4">
                    <v-checkbox
                      v-model="fields.disabled"
                      label="Disabled"
                      hide-details
                      dense
                      color="blue darken-4"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="8">
        <div class="d-flex justify-start mt-6">
          <p style="width: 100px">Created:</p>
          {{ fields.created_at | moment("YYYY-MM-DD HH:mm") }}
        </div>
        <div class="d-flex justify-start mb-6">
          <p style="width: 100px">Last Update:</p>
          {{ fields.updated_at | moment("YYYY-MM-DD HH:mm") }} (UserName)
        </div>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        :to="'/users/detail/' + fields.id"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="submit"
      >
        Save
      </v-btn>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Waiting...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
        <v-card>
          <v-card-text>
            <div class="text-h6 pa-8 grey--text text-center">
              Saved successfully
            </div>
          </v-card-text>
          <v-card-actions class="justify-center pb-5">
            <v-btn :to="'/users/detail/' + fields.id" color="black" dark>Back</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      fields: {},
      errors: {},
      loading: false,
      dialog: false,
      buttonStatus: false,
      breadcrumbs: [
        {
          text: "User List",
          disabled: false,
          href: "/users/",
        },
        {
          text: "User Detail",
          disabled: false,
          href: `/users/detail/${this.$route.params.id}`,
        },
        {
          text: "User Edit",
          disabled: true,
          href: `/users/edit/${this.$route.params.id}`,
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("setOverlay", {
              overlay: true,
            });
    (async () => {
      this.$axios
        .get(`/api/users/${this.$route.params.id}`)
        .then((response) => {
          this.fields = response.data;
          this.$store.commit("setOverlay", {
              overlay: false,
            });
        })
        .catch((error) => {
            if (error.response.status == "404") {
                  this.$router.replace({
                      path: "/404",
                  });
              }
      });
    })();
     
  },
  methods: {
    submit() {
      this.buttonStatus = true;
      this.errors = {};
      this.$axios
        .put(`/api/users/${this.$route.params.id}`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.buttonStatus = true;

            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            /* if (this.$store.state.auth.authuser.id == this.$route.params.id) {
              this.$store.commit("auth/setAuthUser", {
                authuser: response.data.user,
                authrole: response.data.role,
              });
            }*/
           this.$router.back()
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessage", {
                            text: '入力内容にエラーがあります。内容確認してください。',
                            color: "warning",
                        });
            this.buttonStatus = false;
          }
        });
    },
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  watch: {
    loading(val) {
      if (!val) return;

      setTimeout(() => ((this.loading = false), (this.dialog = true)), 1000);
    },
  },
};
</script>
