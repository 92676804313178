import axios from "axios";
import store from "../store";

export function checkAuth() {
  return new Promise((resolve) => {
    if(store.state.auth.authrole){
      resolve(true);
    }
    else{
      axios.get("/api/login_user").then((response) => {
        if (response.status == 200 && response.data != 0) {
          resolve(true);
        }
      }).catch(
          function (error) {
              resolve(false);
              console.log(error);
          }
        );
    }
  });

}

export function checkAuthByRole(role) {
  return new Promise((resolve) => {
    var flg = false;
    if (store.state.auth.authrole && store.state.auth.authrole != 0) {
      flg = store.state.auth.authrole.some(el => role.includes(el));
    }
    resolve(flg);
  });
}