<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/group-mail" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Group Mail Add</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Title*</th>
                  <td class="py-3">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.title"
                        :rules="[rules.required]"
                        :error-messages="
                          errors.title ? errors.title[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.title" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.title[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Content</th>
                  <td class="pt-3">
                    <v-textarea
                      label = "#name# and #affiliation# convert to user's own."
                      outlined
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model="fields.content"
                    ></v-textarea>
                  </td>
                </tr>
                <tr>
                <th>Bcc</th>
                  <td class="py-3">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        label = "Input bcc email address separated by commas.(a@example.com,b@example.com...)"
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.bcc"
                        :rules="[rules.counter255]"
                        :error-messages="
                          errors.bcc ? errors.bcc[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.bcc" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.bcc[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                <th>Reply To Address</th>
                  <td class="py-3">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.reply_to_address"
                        :rules="[rules.counter255,rules.email]"
                        :error-messages="
                          errors.reply_to_address ? errors.reply_to_address[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.reply_to_address" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.reply_to_address[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                <th>Reply To Name</th>
                  <td class="py-3">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.reply_to_name"
                        :rules="[rules.counter255]"
                        :error-messages="
                          errors.reply_to_name ? errors.reply_to_name[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.reply_to_name" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.reply_to_name[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Destination
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Affiliation</th>
                  <th>Admin</th>
                  <th>Panel</th>
                  <th>Reviewer</th>
                  <th>Proposer</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(destination,index) in assignedDestinations" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ destination.name }}
                  </td>
                  <td>
                    {{ destination.email }}
                  </td>
                  <td>
                    {{ destination.affiliation }}
                  </td>
                  <td class="text-center"><span v-if="destination.is_administration_office">x</span></td>
                  <td class="text-center"><span v-if="destination.is_panel">x</span></td>
                  <td class="text-center"><span v-if="destination.is_reviewer">x</span></td>
                  <td class="text-center"><span v-if="destination.is_proposer">x</span></td>
                  <td>
                    <v-btn
                        color="error"
                        fab
                        x-small
                        outlined
                        class="mx-1"
                        @click="none"
                      >
                        <v-icon @click.stop="deleteDestination(index)">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-row>
          <v-col>
            <v-card color="black">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <h3 class="subtitle-1 font-weight-bold mt-2 ml-3">Import Address</h3>
                    </tr>
                  </thead>
                  <tbody>
                    <td>
                      <div class="v-input__control d-flex justify-space-between mt-2 ml-3 mr-3" style="width: auto;">
                        <v-textarea
                          label = "Input destination email addresses separated by commas."
                          outlined
                          auto-grow
                          v-model="inputDestinationEmail"
                        ></v-textarea>
                        <div v-if="failedImportMessage" class="text-danger v-text-field__details">
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              {{ failedImportMessage }}
                            </div>
                          </div>
                        </div>
                        <div v-else-if="successImportMessage" class="v-text-field__details">
                          <div class="v-messages theme--light primary--text" role="status">
                            <div class="v-messages__wrapper">
                              {{ successImportMessage }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-end">
                        <v-btn 
                          depressed
                          dark
                          small
                          color="green"
                          class="mb-2 mr-2"
                          @click="importDestination()"
                          >
                          import
                        </v-btn>
                      </div>
                    </td>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        v-bind:href="'/group-mail'"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="submit"
      >
        Save
      </v-btn>
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
        <v-card>
          <v-card-text>
            <div class="text-h6 pa-8 grey--text text-center">
              Saved successfully
            </div>
          </v-card-text>
          <v-card-actions class="justify-center pb-5">
            <v-btn to="/information/detail" color="black" dark>Back</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      inputDestinationEmail: '',
      successImportMessage: '',
      failedImportMessage: '',
      outputDestinationEmail: '',
      notExistEmailFlag : true,
      errors: {},
      fields: {},
      destinationsList: [],
      assignedDestinations: [],
      splitDestinationEmail: [],
      rules: {
        required: value => !!value || 'This field is required.',
        counter255: value => value.length <= 255 || 'This form is limited in 255 words.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if(!(value.length === 0)){
            return pattern.test(value) || 'This field have to be email format.'
          }
        },
      },
      breadcrumbs: [
        {
          text: "Group Mail List",
          disabled: false,
          href: "/group-mail/",
        },
        {
          text: "Group Mail Add",
          disabled: true,
          href: "/group-mail/add",
        },
      ],
      dialog: false,
      buttonStatus: false,
    };
  },
  methods: {
    importDestination() {
      //初期化処理
      this.successImportMessage = '';
      this.failedImportMessage = '';
      this.outputDestinationEmail = '';
      this.splitInputDestinationEmail = [];
      this.inputDestinationEmail = this.inputDestinationEmail.replace(/\s+/g, ''); //改行文字とスペースの削除
      //未入力対応処理
      if(this.inputDestinationEmail === ''){
        this.failedImportMessage = 'No input.'
        throw new Error();
      }
      //入力されたメールアドレスが,で区切られていれば分割して格納し、そうでなければ入力値をそのまま格納
      if(this.inputDestinationEmail.indexOf(',') !== -1){
        this.splitInputDestinationEmail = this.inputDestinationEmail.split(',');
      }else{
        this.splitInputDestinationEmail.push(this.inputDestinationEmail);
      }
      //入力されたメールアドレス1件ごとに既存のメールアドレスリストに登録されているか調べる
      this.splitInputDestinationEmail.forEach((elementOfSplitInputDestinationsEmail) => {
        this.destinationsList.forEach((elementOfDestinationsList) => {
          this.outputDestinationEmail = elementOfSplitInputDestinationsEmail; //foreachループのスコープ外でメールアドレスをメッセージ表示に使用できるよう格納
          //同じアドレスかつ disabled = 0  の userが存在する場合はdestinationに追加するか判定
          if(elementOfDestinationsList['email'] === elementOfSplitInputDestinationsEmail && elementOfDestinationsList['disabled'] === 0){
            this.notExistEmailFlag = false; //メールアドレスが登録されているため、存在否定フラグをfalseにする
            //destinationに既に格納されていない場合のみ、表示リストに追加
            if(!(this.assignedDestinations.includes(elementOfDestinationsList))){
              this.assignedDestinations.push(elementOfDestinationsList);
            }
          //指定されたメールアドレスのユーザーが存在するがdisabledの場合はエラーメッセージを表示して読み込み中断
          }else if(elementOfDestinationsList['email'] === elementOfSplitInputDestinationsEmail && elementOfDestinationsList['disabled'] === 1){
            this.failedImportMessage = elementOfSplitInputDestinationsEmail + ' is disabled.'
            throw new Error();
          }
        });
        //指定されたメールアドレスのユーザーが存在しない場合はエラーメッセージを表示して読み込み中断する
        if(this.notExistEmailFlag){
          this.failedImportMessage = this.outputDestinationEmail + ' is not registered.'
          throw new Error();
        }else{
          this.notExistEmailFlag = true; //次に入力されたメールアドレスが既に登録されているか調べるため、存在否定フラグを初期値のtrueに戻す
        }
      });
      this.successImportMessage = 'Imported.'
      this.inputDestinationEmail = '';
    },
    deleteDestination(index) {
      this.assignedDestinations.splice(index, 1);
      this.successImportMessage = '';
      this.failedImportMessage = '';
    },
    submit() {
      this.successImportMessage = '';
      this.failedImportMessage = '';
      this.fields.destinations = this.assignedDestinations;
      this.buttonStatus = true;
      this.errors = {};
      this.$axios
        .post(`/api/group_mail/add`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.$router.back()
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
            this.buttonStatus = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors || {};
          if (error.response.status === 400){
            this.$store.commit("setMessage", {
                            text: 'There are errors with the input data. Please check the contents.',
                            color: "warning",
                        });
          }
          this.buttonStatus = false;
        });
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/users/all")
        .then((response) => {
          response.data.forEach(e => {
            var dataTmp = {};
            dataTmp.id = e['id'];
            dataTmp.name = e['name'];
            dataTmp.email = e['email'];
            dataTmp.affiliation = e['affiliation'];
            dataTmp.is_administration_office = e['is_administration_office'];
            dataTmp.is_panel = e['is_panel'];
            dataTmp.is_proposer = e['is_proposer'];
            dataTmp.is_reviewer = e['is_reviewer'];
            dataTmp.disabled = e['disabled'];
            dataTmp.assigned = false;
            this.destinationsList.push(dataTmp);
          });
        });
    })();
  },
};
</script>