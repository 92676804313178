<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Samples</h2>
      <div class="pt-3 pr-2">
        <v-btn small dark color="blue darken-4 ml-2" to="/samples/assign/particle"
          >Assign Samples (Particle / Aggregate)</v-btn
        >
        <v-btn small dark color="blue darken-4 ml-2" to="/samples/assign/gas"
          >Assign Samples (Gas)</v-btn
        >
        <v-btn small dark color="blue darken-4 ml-2" to="/samples/assign/processed"
          >Assign Samples (Previously allocated)</v-btn
        >
        <v-btn small dark color="orange darken-4 ml-2" to="/samples/assigned"
          >Assigned Samples List</v-btn
        >
      </div>
    </div>
    <v-row class="mt-2 px-6 justify-end">
      <v-col cols="auto" class="d-flex align-center">
        <v-checkbox
          v-model="showAllAvailableSamples"
          label='Show all "available" samples'>
        </v-checkbox>
        <v-btn class="ml-4" color="deep-orange darken-1" dark @click="downLoadCsv">
          <v-icon>mdi-download</v-icon>Download CSV
        </v-btn>
      </v-col>
    </v-row>
      <v-row justify="center" align="center" class="mt-5 mb-5 px-6">
        <v-col lg="12">
          <template>
            <div>
              <v-card>
                <v-data-table                
                  :items="filteredSamples"
                  :headers="headers"
                  :page.sync="page"
                  hide-default-footer
                  no-data-text="No data"
                  disable-pagination
                >
                  <template v-slot:item="{ item }">
                    <tr v-if="item.status !== 0">
                      <td><a :href="SampleDBUrl + item.id" target="_blank">{{ item.name }}</a></td>
                      <td>{{ sampleTypes[item.type] }}</td>
                      <td>{{ item.weight }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        <span v-for="index in item.requested_sample" :key="index.id">
                          <a v-if="(searchForm.term == '' && index.proposal.term.disabled == 0) || searchForm.term == index.proposal.term_id" :href="detailURL + index.proposal_id">Proposal:{{ index.proposal_id }} (score: {{ index.proposal.global_score }}) [priority: {{ index.priority }}]</a><br>
                        </span>
                      </td>
                      <td>
                        <span v-for="index in item.assigned_sample" :key="index.id">
                          <a v-if="(searchForm.term == '' && index.proposal.term.disabled == 0) || searchForm.term == index.proposal.term_id" :href="detailURL + index.proposal_id">Proposal:{{ index.proposal_id }}</a><br>
                        </span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-dialog v-model="sampleLoading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Loading samples...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      sampleLoading: true,
      errors: [],
      sample_lists : [],
      showAllAvailableSamples: false,
      detailURL: '/proposals/detail/',
      SampleDBUrl: '',
      sampleTypes: [],
      searchForm: {
        term: '',
      },
      terms: [],
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "10%",
        },
        {
          text: "Type",
          sortable: false,
          value: "type",
          width: "10%",
        },
        {
          text: "Weight[mg]",
          sortable: false,
          value: "weight",
          width: "10%",
        },
        {
          text: "Size[mm]",
          sortable: false,
          value: "size",
          width: "10%",
        },
        {
          text: "Requested",
          sortable: false,
          value: "requested",
          width: "10%",
        },
        {
          text: "Assigned",
          sortable: false,
          value: "assigned",
          width: "10%",
        },
      ],
    }
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/samples/current`)
        .then(response => {
          for(let kw in response.data) {
            this.sample_lists.push({
              id: response.data[kw].id,
              name: response.data[kw].name,
              weight: response.data[kw].weight,
              size: (!isNaN(response.data[kw].size)) ? (response.data[kw].size / 1000).toFixed(3) : response.data[kw].size,
              type: response.data[kw].type,
              pressure: response.data[kw].pressure,
              volume: response.data[kw].volume,
              requested_sample: response.data[kw].requested_sample,
              assigned_sample: response.data[kw].assigned_sample,
              requested: response.data[kw].requested,
            });
          }
          this.sampleLoading = false;
        });
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then(response => {
          this.SampleDBUrl = response.data;
        });
      this.$axios
        .get("/api/masters/formArray/sample_type")
        .then((response) => {
          this.sampleTypes = response.data;
      });
      this.$axios
        .get(`/api/masters/getAllTerms`)
        .then(response => {
          this.terms.push({id:0, label: ''});
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
    })();
  },
  computed: {
    filteredSamples() {
      if (this.showAllAvailableSamples) {
        return this.sample_lists;
      } else {
        return this.sample_lists.filter(sample => sample.requested != 0);
      }
    }
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtureを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    async downLoadCsv(){
      try {
        //動作確認用
        const showAllAvailableInt = this.showAllAvailableSamples ? 1 : 0;
        const res = await this.$axios.get(`/api/samples/current/download/${showAllAvailableInt}`);
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    },
  }
}
</script>