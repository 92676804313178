<template>
    <v-app>
        <div class="ma-9 pa-9 text-center">
            <span class="pa-2 secondary rounded-circle d-inline-block">
                <v-icon
                large
                color="white"
                >
                mdi-exclamation-thick
                </v-icon>
            </span>
            <div class="text-h1 my-4">403</div>
            <p>Forbidden</p>
        </div>
        <div class="mt-4 text-center">
            <v-btn
            depressed
            dark
            color="cyan"
            to="/login"
            >
            GO TO TOP
            </v-btn>

        </div>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            pagesName: this.$route.name
        }
    }
}
</script>