<template>
  <v-app>
    <v-container fluid class="login">
      <v-row justify="center" align="center">
        <v-col xs="10" sm="6" md="6" lg="4">
          <v-card color="grey lighten-5">
            <v-toolbar color="grey lighten-2" flat>
              <h4 class="mx-auto">
                Password Reset
              </h4>
            </v-toolbar>
            <v-divider> </v-divider>
            <div class="pa-6">
              If youw want to Reset Password, Press "Reset"
            </div>
            <div class="pa-6 pt-0">
              <div class="text-center">
                <v-btn
                  class="font-weight-bold mr-4"
                  color="blue darken-4"
                  dark
                  large
                  @click.once="reset"
                >
                  Reset
                </v-btn>
                <v-btn
                  class="font-weight-bold"
                  color="grey lighten-2"
                  large
                  to="/login"
                >
                  Cancel
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      email: this.$route.query.email,
      token: this.$route.params.token,
    };
  },
  methods: {
    reset() {
      this.$axios.post('/api/password/reset', {
        email: this.email,
        token: this.token,
        }, {

      })
      .then(response => {
        response;
        this.$router.push("/login");
      })
      .catch(error => {
        console.log(error);
        alert('The email address is not registered.');
      });
    },
  }
};
</script>

<style lang="scss">
.login_title {
  width: 100%;
}
.login {
  height: 100%;
  .row {
    height: 100%;
  }
}
</style>
