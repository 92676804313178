<template>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showWindow"
    >
      <v-card>
        <v-card-title>Additional Note</v-card-title>
        <v-card-text>
          <div class="text-h6 pa-4 grey--text text-center">
            <v-textarea
              outlined
              solo
              dense
              flat
              rows="5"
              auto-grow
              color="black"
              v-model="params.note"
              :rules="[rules.counter5000]"
              :error-messages="
                errors.note ? errors.note[0] :''
              "
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="CloseWindow()">Cancel</v-btn>
          <v-btn @click="UpdateNote()" color="blue darken-4" dark>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'editAdditionalNoteWindow',
  props: ['additional_note', 'showEditAdditionalNoteWindow'],
  data() {
    return {
      showWindow: false,
      params: {note: null},
      rules: {
        counter5000: value => value.length <= 5000 || 'This form is limited in 5000 words.',
      },
      errors: {},
    };
  },
  methods: {
    UpdateNote() {
    this.errors = {};
    this.$axios
      .put(`/api/proposals/add/${this.$route.params.id}`,this.params)
      .then((response) => {
        if (response.data.result == true) {
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "success",
          });
          this.$emit('exe-get-proposal-date',this.$route.params.id);
        }else{
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "alert",
          });
        }
      })
      .catch((error) => {
        this.errors = error.response.data.errors || {};
        if (error.response.status === 400){
          this.$store.commit("setMessage", {
                          text: 'There are errors with the input data. Please check the contents.',
                          color: "warning",
                      });
        }
      });
      this.$emit('close-edit-note', this.showWindow);
    },
    CloseWindow() {
        this.showWindow = false;
        this.$emit('close-edit-note', this.showWindow);
    },
  },
  watch: {
    additional_note: {
      immediate: true,
      handler(value) {
        this.params.note = value;
      },
    },
    showEditAdditionalNoteWindow: {
      immediate: true,
      handler(value) {
        this.showWindow = value;
      },
    },
  }
};
</script>