<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/information" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Information Edit</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Title*</th>
                  <td class="py-2">
                    <div class="v-input__control">
                      <v-text-field
                        solo
                        outlined
                        flat
                        color="black"
                        hide-details
                        dense
                        v-model="fields.title"
                        :rules="[rules.required,rules.counter100]"
                        :error-messages="
                          errors.title ? errors.title[0] :''
                        "
                      ></v-text-field>
                      <div v-if="errors && errors.title" class="text-danger v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            {{ errors.title[0] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Target *</th>
                  <td class="text-subtitle-1">
                    <v-radio-group row v-model="fields.target">
                      <v-radio
                        v-for="t in targetList"
                        :key="t.id"
                        :label="t.label"
                        :value="t.id"
                        color="blue darken-4"
                      ></v-radio>
                    </v-radio-group>
                    <div v-if="errors && errors.target" class="text-danger v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          {{ errors.target[0] }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Content</th>
                  <td class="pt-3">
                    <v-textarea
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      :rules="[rules.counter5000]"
                      v-model="fields.content"
                    ></v-textarea>
                    <div class="v-text-field__details" v-if="errors['content']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['content'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>URL</th>
                  <td class="pt-3">
                    <v-text-field
                      outlined
                      solo
                      dense
                      flat
                      rows="5"
                      auto-grow
                      color="black"
                      v-model="fields.url"
                      :rules="[rules.url, rules.counter200]"
                    ></v-text-field>
                    <div class="v-text-field__details" v-if="errors['url']">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="messages__message">{{ errors['url'][0] }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td class="text-subtitle-1">
                    <v-menu
                      ref="menu"
                      v-model="date"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fields.date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fields.date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </td>
                </tr>
                <tr>
                  <th>Published</th>
                  <td class="pt-3 pb-4">
                    <v-checkbox
                      v-model="fields.published"
                      label="Publish immediately"
                      hide-details
                      dense
                      color="blue darken-4"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        to="/information"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="submit"
      >
        Save
      </v-btn>
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
        <v-card>
          <v-card-text>
            <div class="text-h6 pa-8 grey--text text-center">
              Saved successfully
            </div>
          </v-card-text>
          <v-card-actions class="justify-center pb-5">
            <v-btn to="/information/detail" color="black" dark>Back</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      fields: {},
      target: 0,
      temp: '',
      targetList: {},
      rules: {
        required: value => !!value || 'This field is required.',
        counter100: value => value.length <= 100 || 'This form is limited in 100 words.',
        counter200: value => value.length <= 200 || 'This form is limited in 200 words.',
        counter5000: value => value.length <= 5000 || 'This form is limited in 5000 words.',
        url: value => {
          const pattern = /(https|http)?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g | /\S/g;
          return pattern.test(value) || 'Only url can be input in this form.'
        },
      },
      breadcrumbs: [
        {
          text: "Information List",
          disabled: false,
          href: "/information/",
        },
        {
          text: "Information Add",
          disabled: true,
          href: "/information/add",
        },
      ],
      loading: false,
      dialog: false,
      buttonStatus: false,

      date: "",
      menu: false,
      modal: false,
      menu2: false,
    };
  },
  methods: {
      submit() {
      this.buttonStatus = true;
      this.errors = {};
      this.$axios
        .put(`/api/information/${this.$route.params.id}`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.$router.back()
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
            this.buttonStatus = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors || {};
          if (error.response.status === 400){
            this.$store.commit("setMessage", {
                            text: 'There are errors with the input data. Please check the contents.',
                            color: "warning",
                        });
          }
          this.buttonStatus = false;
        });
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/masters/information_target_list").then((response) => {
        this.targetList = response.data;
      });
      this.$axios
        .get(`/api/information/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;
          this.fields.date = this.fields.date.substr(0,10);
      });
    })();
  },
  watch: {
    loading(val) {
      if (!val) return;

      setTimeout(() => ((this.loading = false), (this.dialog = true)), 1000);
    },
  },
};
</script>
