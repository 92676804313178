<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between">
      <h2 class="py-2 px-6">Reviewer List</h2>
      
    </div>
    <v-divider></v-divider>

    <v-row justify="center" align="center" class="my-5">
      <v-col lg="10">
        <v-card color="grey lighten-5">
          <v-card-title class="px-4 py-2 black">
            <h3 class="subtitle-1 font-weight-bold white--text">Search</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <v-row class="pa-6 pb-0" justify="start">
            <v-col>
              <v-text-field
                label="Name"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.name
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="E-mail"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.email
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Affiliation"
                dense
                outlined
                hide-details
                color="brown"
                background-color="white"
                v-model = searchForm.affiliation
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="px-6 d-flex justify-start">
            <v-checkbox
              class="ma-4"
              v-for="status in reportStatus"
              dense
              hide-details
              :key="status.id"
              :label="status.label"
              :value="status.id"
              v-model=searchForm.status
              >
            </v-checkbox>

            <v-col cols="2" md="2">
              <v-select
                label="Term"
                dense
                v-model = searchForm.term_id
                :items = terms
                item-text="label"
                item-value="value"
                outlined
                hide-details
                color="brown"
                background-color="white"
                flat
              ></v-select>
            </v-col>
          </div>
          <div class="text-center pb-4 mt-3">
            <v-btn class="ma-2" dark color="black" @click="reviewerSearch()">
              <v-icon>mdi-magnify</v-icon>
              Search
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="black"
                v-bind:href="'/reviewers?clear'"
            >
              Reset
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-5 px-6">
      <v-col lg="12">
        <div class="d-flex justify-space-between align-end mb-5">
          <div class="text-caption">{{total}} results</div>
          <div>
            <v-btn
              color="green darken-1"
              dark
              @click="showDialog = true"
              v-if="showButton && $store.state.auth.authuser.is_administration_office"
            >
              SEND ALL REQUEST
            </v-btn>
            <v-btn color="deep-orange darken-1 ml-2" dark @click="downLoadCsv"
            ><v-icon>mdi-download</v-icon>Download CSV</v-btn
            >
          </div>
        </div>
        <div>
          <v-card>
            <v-data-table
              :items="items"
              :headers="headers"
              :page.sync="page"
              hide-default-footer
              no-data-text="No data"
              disable-pagination
            >
            <template v-slot:item="{ item }">
                <tr v-bind:class="{ disabled: item.disabled }">
                  <td>
                    <v-btn
                      x-small
                      outlined
                      :to="'/reviewers/detail/' + item.id"
                      >DETAIL</v-btn
                    >
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.affiliation }}</td>
                  <td class="py-3">
                    <span v-for="report in item.report" :key="report.id">
                      <span style="width:120px;">
                      {{report.proposal_id}}: <router-link :to="'/proposals/detail/' + report.proposal_id">{{ report.proposal_title }}</router-link>
                      </span>
                      <span class="ml-4">
                      <b class="red--text text--darken-4" x-small dark v-if="report.status === 0">{{ status[report.status] }}</b>
                      <b class="blue--text text--darken-4" x-small dark v-else-if="report.status === 1">{{ status[report.status] }}</b>
                      <b class="orange--text text--darken-4" x-small dark v-else-if="report.status === 2">{{ status[report.status] }}</b>
                      <b class="green--text text--darken-4" x-small dark v-else-if="report.status === 3">{{ status[report.status] }}</b>
                      <b class="black--text text--darken-4" x-small dark v-else-if="report.status === 9">{{ status[report.status] }}</b>
                      </span>
                      <br />
                    </span>
                  </td>
                </tr>
            </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="emitChangePage()"
              circle
              color="blue darken-4"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you send a review request mail for all reviewers?
            （Passwords for reviewers not logged in will be reset.）
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false">Cancel</v-btn>
          <v-btn @click="sendAllRequest" color="black" dark>SEND</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
export default {
  mixins: [Search],
  data() {
    return {
      name: null,
      buttonStatus: false,
      registDialog: false,
      errors: [],
      terms:[],
      items:[],
      reportStatus:[],
      status:[],
      headers: [
        {
            text: "",
            sortable: false,
            width: "",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
          width: "",
        },
        {
          text: "E-mail",
          sortable: false,
          value: "email",
          width: "",
        },
        {
          text: "Affiliation",
          sortable: false,
          value: "affiliation",
          width: "",
        },
        {
          text: "Proposal",
          sortable: false,
          value: "proposal",
          width: "",
        },
      ],
      searchForm: {
        //検索のデフォルト値
        name: "",
        affiliation: "",
        email: "",
        status: [],
        term_id: null,
      },
      searchScheme: [
        "name",
        "email",
        "affiliation",
        "status",
        "term_id",
      ], //検索条件
      searchURL: "/api/reviewers",
      isActive: false,
      showDialog: false,
      loading: false,
      showButton: true,
    };
  },
  filters: {
    omitTitle(title) {
      const words = title.split(/[\s]/);
      let trimmedTitle = '';
      if(words.length > 5){
        for(let i = 0; i < 5; i++){
          trimmedTitle += words[i] + ' ';
        }
      }else{
        words.forEach(value => {
          trimmedTitle += value + ' ';
        });
      }
      return trimmedTitle;
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/masters/getTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms.push({id:t, label: t});
          }
        });
      this.$axios
        .get(`/api/masters/getTerm`)
        .then(response => {
          this.searchForm.term_id = String(response.data.id);
          this.list(false,this.searchForm.term_id);
        });
      this.$axios
        .get("/api/masters/report_status")
        .then((response) => {
          this.reportStatus = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/report_status")
        .then((response) => {
          this.status = response.data;
      });
    })();
  },
  methods: {
    list(scrollFlg,defaultTerm) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.term_id = defaultTerm;
      // search.js の機能を共有使う
      // ロード時のAPI通信時のみ第2引数にtrueを入れて検索条件の復元を行う。
      this.search(query, true);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value); //search.js の機能を共有使う
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    changeStatus(status){
      this.searchForm.status = status;
    },
    //検索ボタンがクリックされた時
    reviewerSearch() {
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase(); //search.js の機能を共有使う
      this.search(query); //search.js の機能を共有使う
    },
    sendAllRequest() {
      this.showDialog = false;
      this.loading = true;
      this.showButton = false;
      this.$axios
        .put("/api/reviewers/request_all_reviewers", this.searchForm)
        .then((response) => {
          this.loading = false;
          this.showButton = true;
          this.$store.commit("setMessage", {
            text: response.data.message,
            color: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.showButton = true;
          console.log(error);
        });
    },
    async downLoadCsv() {
      try {
        //動作確認用
        const res = await this.$axios.get('/api/reviewers/download', {
          params: {
            name: this.searchForm.name,
            email: this.searchForm.email,
            affiliation: this.searchForm.affiliation,
            status: this.searchForm.status,
            term_id: this.searchForm.term_id,
          }
        });
        if(res.status === 200){
          window.location = res.request.responseURL;
        } else {
          //エラー表示は後で適切な形に修正する
          alert("failed");
        }
      } catch(e) {
        console.log(e);
        //エラー表示は後で適切な形に修正する
        alert("failed");
      }
    }
  },
  watch: {
    loading(val) {
      if (!val) return;

      //setTimeout(() => ((this.loading = false), (this.register = true)), 1000);
    },
  },
};
</script>