<template>
  <v-card color="basil" flat>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <th width="40%">Collaborator</th>
              <td class="py-3">
                <div v-for="item in fields.proposal_collaborator" :key="item.id">
                  {{item.name}} / {{ item.affiliation }} ({{ item.email }})<br>
                </div>
              </td>
            </tr>
            <tr v-if="user_type == 'proposer'">
              <th>
                Attached PDF File 
              </th>
              <td>
                <a :href="`/api/proposals/download_proposal/${fields.id}`" v-if="fields.file">{{fields.file.original_file_name}}</a></td>
            </tr>
            <tr>
              <th>Title</th>
              <td>{{fields.id}}: {{fields.title}}</td>
            </tr>
            <tr>
              <th>Abstract</th>
              <td class="nl2br">{{fields.abstract}}</td>
            </tr>
            <tr>
              <th>Keywords</th>
              <td>
                <span v-for="item in fields.selected_keywords" :key="item.id">
                  {{item.keyword_other}}<br>
                </span>
              </td>
            </tr>
            <tr>
              <th>Analysis Method</th>
              <td>
                <span v-for="item in fields.selected_methods" :key="item.id">
                  {{item.analysis_method_other}}<br>
                </span>
              </td>
            </tr>
            <tr>
              <th>Remarks</th>
              <td class="nl2br">{{fields.properties}}</td>
            </tr>
            <tr>
              <th>Effects on samples after the analysis</th>
              <td class="nl2br">{{fields.post_analysis_alternation}}</td>
            </tr>
            <tr>
              <th>Handling Facility/Tools</th>
              <td class="nl2br">{{fields.methods_to_handle_of_sample}}</td>
            </tr>
            <tr>
              <th>
                Transfer protocol of samples
              </th>
              <td>
                <span v-for="item in fields.selected_conditions" :key="item.id">
                  {{item.environmental_condition_other}}<br>
                </span>
                {{fields.environmental_condition_other}}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center my-6" v-if="user_type != 'proposer'">
        <v-btn class="font-weight-bold mr-4" color="green darken-4" large dark
          :href="`/api/proposals/download_proposals/${fields.id}`"
        >
          PDF Export
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'tabDetail',
  props: ['fields', 'user_type'],
  data() {
    return {
    };
  },
};
</script>
