<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/mypage" exact outlined>Back</v-btn>
      </div>
    </div>
    
    <v-row justify="center" align="center" class="mb-4" v-if="this.fields.id">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">Title</th>
                  <td class="text-h6">{{ fields.title }}</td>
                </tr>
                <tr>
                  <th width="20%">Name</th>
                  <td class="text-h6">{{ fields.name }}</td>
                </tr>
                <tr>
                  <th>Affiliation</th>
                  <td class="text-subtitle-1">{{ fields.affiliation }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td class="text-subtitle-1">{{ fields.email }}</td>
                </tr>
                <tr>
                  <th width="20%">Early career</th>
                  <td class="text-subtitle-1">{{ earlyCareerScientists[fields.early_career] }}</td>
                </tr>
                <tr>
                  <th width="20%">Previous Status</th>
                  <td class="text-subtitle-1" colspan="3">
                    <span v-for="item in fields.accepted_terms" :key="item.id" class="mr-4">
                      <span v-if="item.term_id">
                        AO{{ item.term_id }},
                      </span>
                      <span v-else-if="accepted_status_other[item.other_status_id]">
                        {{ accepted_status_other[item.other_status_id] }},
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th width="20%">Collaborator</th>
                  <td>
                    <div v-for="item in fields.proposal_collaborator" :key="item.id">
                      {{item.name}} / {{ item.affiliation }} ({{ item.email }})<br>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="text-subtitle-1">
                    <div v-if="fields.status == 0">
                      -
                    </div>
                    <div v-else-if="fields.status == 1" class="red--text text--darken-4">
                      <b>Not Submitted</b>
                    </div>
                    <div v-else>
                      Submitted
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row justify="center" align="center" class="mb-4" v-else>
      <v-col lg="10">
        <h2 align="left">Registration (Notice of Intent) for the {{ termText }} AO</h2>
        <p class="mt-2 mb-0 orange--text text--darken-2 text-h6">Users registered in the previous AOs also need registration for the {{termText}} AO. Please click "Registration" below and fill out the form.</p>
        <v-list>
          <v-list-item>
            <v-list-item-content class="text-h6">
              <a href="/applicants/create">
                &gt;&gt;Registration (Notice of Intent)
              </a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row> -->

    <v-row class="mb-4" justify="center" align="center" v-if="this.fields.id">
      <v-col md="10">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="blue lighten-5"
            color="blue darken-3"
            grow
            slider-size="4"
          >
            <v-tab> Proposal </v-tab>

            <v-tab :disabled="sampleLoading"> Sample </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <tabDetail :fields="fields" :user_type="user_type" />
            </v-tab-item>
            <v-tab-item>
              <tabAssignedSample :fields="fields" :sample_lists="sample_lists" :sampleDBUrl="sampleDBUrl" :requestedSampleTotal="requestedSampleTotal" :assignedSampleTotal="assignedSampleTotal" :sampleTypes="sampleTypes" :chambers="chambers" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8" v-if="this.fields.id && this.proposalAccepted">
      <span v-if="!submitLimit && fields.is_unlocked != 1">
        <v-btn
          class="font-weight-bold"
          color="grey darken-1"
          large
          disabled
          >Edit</v-btn>
      </span>
      <span v-else-if="!disabledButtonStatus">
        <v-btn
          class="font-weight-bold"
          :to="'/proposals/edit/' + fields.id"
          color="blue darken-4"
          large
          dark>Edit</v-btn>
      </span>

      <span v-if="fields.status == 2 || !submitCheck">
        <v-btn
          class="font-weight-bold ml-5"
          color="grey darken-1"
          large
          disabled
        >
          Submit
        </v-btn>
      </span>
      <span v-else-if="!submitLimit && fields.is_unlocked != 1">
        <v-btn
          class="font-weight-bold ml-5"
          color="grey darken-1"
          large
          disabled
        >
          Submit
        </v-btn>
      </span>
      <span v-else-if="!disabledButtonStatus">
        <v-btn
          class="font-weight-bold ml-5"
          color="orange darken-4"
          dark
          large
          @click="showDialog = true"
        >
          Submit
        </v-btn>
      </span>

      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            Waiting...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="login-btn text-center mt-4 mb-8" v-else-if="this.fields.id && !this.proposalAccepted">
      <span>
        <v-btn
          class="font-weight-bold"
          color="grey darken-1"
          large
          disabled>Edit</v-btn>
      </span>
      <span>
        <v-btn
          class="font-weight-bold ml-5"
          color="orange darken-4"
          large
          disabled>Submit</v-btn>
      </span>
    </div>
    <div class="text-center mt-4 mb-8 text-h6 red--text" v-if="this.fields.id && !this.proposalAccepted">
      Proposal submission page is already open. Please proceed to make a proposal.
    </div>

    <v-row v-if="havePastProposals" justify="center" align="center">
      <v-col md="10">
        <v-card color="black">
          <v-card-title
            class="px-4 py-2 black d-flex justify-space-between white--text"
          >
            <h3 class="subtitle-1 font-weight-bold ">
              Previous proposals
            </h3>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Term</th>
                  <th>Title</th>
                  <th>Abstract</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in old_proposals"
                  :key="item.id"
                >
                  <td>
                    {{ terms[item.term_id] }}
                  </td>
                  <td>
                    <a :href="'/proposals/oldProposal/' + item.id">{{ item.title }}</a>
                  </td>
                  <td>
                    {{ item.shortAbstract }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="showDialog"
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-8 grey--text text-center">
            Do you submit this Proposal? 
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="showDialog = false">Cancel</v-btn>
          <v-btn @click="submit" color="black" dark>Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import tabDetail from "../detail/tabDetail";
import tabAssignedSample from "./tabAssignedSample";
import moment from "moment";
export default {
  components: { tabDetail, tabAssignedSample },
  data() {
    return {
      loading: false,
      sampleLoading: true,
      disabledButtonStatus: false,
      submitCheck: false,
      showDialog: false,
      user_type: "proposer",
      register: false,
      tab: null,
      sample_lists: [],
      termText: '',
      fields: {
        id: "loading",
        file: {
          original_file_name: '',
        },
      },
      sampleDBUrl:"",
      requestedSampleTotal: 0,
      assignedSampleTotal: 0,
      earlyCareerScientists: {},
      sampleTypes: [],
      accepted_status_other: [],
      chambers: [],
      submitLimit: null,
      proposalAccepted: false,
      terms: [],
      havePastProposals: false,
      old_proposals: {},
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/mypage",
        },
        {
          text: "MyProposal",
          disabled: true,
          href: "/proposals/myProposal",
        }
      ],
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/masters/formArray/early_career_type")
        .then((response) => {
          this.earlyCareerScientists = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/proposal_previos_accepted_status")
        .then((response) => {
          this.accepted_status_other = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/sample_type")
        .then((response) => {
          this.sampleTypes = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/ChamberList")
        .then((response) => {
          this.chambers = response.data;
      });
      await this.$axios
        .get(`/api/masters/checkProposalLimit`)
        .then((response) => {
          this.submitLimit = response.data;
      });
      await this.$axios
        .get(`/api/masters/checkProposalAccepted`)
        .then((response) => {
          this.proposalAccepted = response.data;
      });
      this.$axios
        .get(`/api/masters/getAllTerms`)
        .then(response => {
          for(let t in response.data) {
            this.terms[t] = t;
          }
        });
      this.$axios
        .get(`/api/masters/getTermText/0`)
        .then((response) => {
          this.termText = response.data;
        });
      this.$axios
        .get(`/api/myProposal`)
        .then(response => {
          this.fields = response.data;
          if (response.data.id) {
            let totalR = 0;
            let totalA = 0;
            for (let i in response.data.requested_samples) {
              if (response.data.requested_samples[i].sample_type === 0
                || response.data.requested_samples[i].sample_type === 1) {
                if (!isNaN(response.data.requested_samples[i].weight) && response.data.requested_samples[i].weight) {
                  totalR += parseFloat(response.data.requested_samples[i].weight);
                }
              }
            }
            for (let i in response.data.assigned_samples_status_true) {
              totalA += parseFloat(response.data.assigned_samples_status_true[i].weight);
            }
            this.requestedSampleTotal = (Math.floor(totalR* 10))/10;
            this.assignedSampleTotal = (Math.floor(totalA* 10))/10;
            this.$axios
            .get(`/api/proposals/check/${response.data.id}`)
            .then(response => {
              this.submitCheck = response.data;
            });
          } else {
            this.sampleLoading = false;
          }
        });
      this.$axios
        .get(`/api/masters/getAllSamples`)
        .then(response => {
          for(let kw in response.data) {
            this.sample_lists[response.data[kw].id] = response.data[kw];
          }
          this.sampleLoading = false;
        });
      this.$axios
        .get(`/api/myAllProposals`)
        .then(response => {
          if (response.data.length > 0) {
            this.havePastProposals = true;
            this.old_proposals = response.data;
          }
        });
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then((response) => {
          this.sampleDBUrl = response.data;
        });
    })();
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  methods: {
    submit() {
      this.showDialog = false;
      this.loading = true;
      this.disabledButtonStatus = true;
      this.errors = {};
      this.$axios
        .put(`/api/proposals/submit/${this.fields.id}`)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.submitCheck = false;
            this.fields.status = 2;
          }
          this.loading = false;
          this.disabledButtonStatus = false;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
          }
          this.loading = false;
          this.disabledButtonStatus = false;
        });
    },
  },
};
</script>
