<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/proposals/assigned_proposals" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="10">
        <v-card color="grey lighten-4">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="20%">ID: Title</th>
                  <td class="text-h6" colspan="3">
                    {{ fields.reports.proposal.id }}: {{ fields.reports.proposal.title }}
                  </td>
                </tr>
                <tr>
                  <th width="20%">Proposer (Early career)</th>
                  <td class="text-h6" colspan="3">{{ fields.reports.proposal.name }} | {{ fields.reports.proposal.affiliation }} | {{ fields.reports.proposal.email }}
                    ({{ earlyCareerScientists[fields.reports.proposal.early_career] }})
                  </td>
                </tr>
                <tr>
                  <th width="20%">Collaborator</th>
                  <td colspan="3">
                    <div v-for="item in fields.reports.proposal.proposal_collaborator" :key="item.id">
                      {{ item.name }} / {{ item.affiliation }} ({{ item.email }})<br>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4" justify="center" align="center">
      <v-col md="10">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="blue lighten-5"
            color="blue darken-3"
            grow
            slider-size="4"
          >
            <v-tab href="#tab-1"> Proposal </v-tab>

            <v-tab href="#tab-2"> Addition </v-tab>

            <v-tab :disabled="sampleLoading" href="#tab-3"> Sample </v-tab>
            
            <v-tab href="#tab-4"> Review </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <tabDetail :fields="fields" :user_type="user_type" />
            </v-tab-item>
            <v-tab-item value="tab-2">
              <tabAddition :fields="fields" />
            </v-tab-item>
            <v-tab-item value="tab-3">
              <tabAssignedSample :fields="fields" :sample_lists="sample_lists" :sampleDBUrl="sampleDBUrl" :requestedSampleTotal="requestedSampleTotal" :assignedSampleTotal="assignedSampleTotal" :sampleTypes="sampleTypes" :chambers="chambers" />
            </v-tab-item>
            <v-tab-item value="tab-4">
              <tabReport :fields="fields" :user_type="user_type" :submitCheck="submitCheck" :showDialog="showDialog" :editCheck="editCheck" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tabDetail from "./tabDetail";
import tabAddition from "./tabAddition";
import tabAssignedSample from "./tabAssignedSample";
import tabReport from "./tabReport";
import moment from "moment";
export default {
  components: { tabDetail, tabAddition, tabAssignedSample, tabReport},
  data() {
    return {
      sampleLoading: true,
      user_type: "",
      register: false,
      tab: '',
      fields: {
        reports: {
          proposal: {},
        },
      },
      earlyCareerScientists: {},
      sampleTypes: [],
      chambers: [],
      sample_lists: [],
      requestedSampleTotal: 0,
      assignedSampleTotal: 0,
      sampleDBUrl:"",
      additional_notes: "",
      breadcrumbs: [
        {
          text: "Assigned Proposals",
          disabled: false,
          href: "/proposals/assigned_proposals",
        },
        {
          text: "Proposal Detail",
          disabled: true,
          href: "/proposals/detail",
        },
      ],
      submitCheck: false,
      showDialog: false,
      editCheck: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get("/api/masters/formArray/early_career_type")
        .then((response) => {
          this.earlyCareerScientists = response.data;
      });
      this.$axios
        .get(`/api/masters/getSamples`)
        .then(response => {
          for(let kw in response.data) {
            this.sample_lists[response.data[kw].id] = response.data[kw];
          }
          this.sampleLoading = false;
        });
      this.$axios
        .get("/api/masters/formArray/sample_type")
        .then((response) => {
          this.sampleTypes = response.data;
      });
      this.$axios
        .get("/api/masters/formArray/ChamberList")
        .then((response) => {
          this.chambers = response.data;
      });
      this.getProposalData(this.$route.params.id);
      this.$axios
        .get(`/api/masters/getSampleDBUrl`)
        .then((response) => {
          this.sampleDBUrl = response.data;
      });
    })();
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  methods: {
    getProposalData(id) {
      this.$axios
      .get(`/api/assigned_proposals/${id}`)
      .then(response => {
        this.fields = response.data;
        if (response.data.reports.status == 2 || response.data.reports.status == 3) {
          this.editCheck = true;
        }
        let totalR = 0;
        let totalA = 0;
        for (let i in this.fields.reports.proposal.requested_samples) {
          if (this.fields.reports.proposal.requested_samples[i].sample_type === 0
            || this.fields.reports.proposal.requested_samples[i].sample_type === 1) {
            if (!isNaN(this.fields.reports.proposal.requested_samples[i].weight) && this.fields.reports.proposal.requested_samples[i].weight) {
              totalR += parseFloat(this.fields.reports.proposal.requested_samples[i].weight);
            }
          }
        }
        for (let i in this.fields.reports.proposal.assigned_samples_status_true) {
          totalA += parseFloat(this.fields.reports.proposal.assigned_samples_status_true[i].weight);
        }
        this.requestedSampleTotal = (Math.floor(totalR* 10))/10;
        this.assignedSampleTotal = (Math.floor(totalA* 10))/10;
        this.$axios
          .get(`/api/reports/check/${response.data.reports.id}`)
          .then(response => {
            this.submitCheck = response.data;
        });
      })
      .catch((error) => {
        if (error.response.status == 403) {
          this.$router.replace({
            path: "/403",
          });
        }
      });
      if (this.$route.query.tab) {
        this.tab = this.$route.query.tab;
      }
    },
  },
  watch: {
    '$route' (to) {
      this.getProposalData(to.params.id)
    },
  },
};

</script>
