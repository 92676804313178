<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/masters/keywords" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>New Keyword</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>New Keyword*</th>
                  <td class="py-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model="fields.name"
                      :rules="[rules.required, rules.counter50]"
                      :error-messages="
                        errors.name ? errors.name[0] :''
                      "
                    ></v-text-field>
                    <div v-if="errors && errors.name" class="text-danger v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          {{ errors.name[0] }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Sort</th>
                  <td class="py-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      type="number" @keydown.e.prevent
                      step="1" 
                      v-model="fields.sort"
                      :rules="[rules.integer]"
                      :error-messages="
                        errors.sort ? errors.sort[0] :''
                      "
                    ></v-text-field>
                    <div v-if="errors && errors.sort" class="text-danger v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          {{ errors.sort[0] }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        to="/masters/keywords"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        @click="submit"
      >
        Save
      </v-btn>
    </div>
    
    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Unregistered Keywords</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in keywords"
                  :key="item.name"
                >
                  <td>
                    <v-btn
                      class="d-flex justify-start"
                      @click="select(item.name)">Select
                    </v-btn>
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.count }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      fields: {name:'',sort:null},
      keywords: {},
      rules: {
        required: value => !!value || 'This field is required.',
        counter50: value => value.length <= 50 || 'This form is limited in 50 words.',
        integer: value => {
          const pattern = /^\d+$/
          return pattern.test(value) || 'Only integer can be inputted in this form.'
        },
      },
      breadcrumbs: [
        {
          text: "Masters",
          disabled: false,
          href: "/masters",
        },
        {
          text: "Keyword Master",
          disabled: false,
          href: "/masters/keywords",
        },
        {
          text: "Keyword Add",
          disabled: true,
          href: "/masters/keywords/add",
        },
      ],
      loading: false,
      dialog: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/keywords/not_registered`)
        .then(response => {
          this.keywords = response.data;
        });
    })();
  },
  methods: {
    submit() {
      this.errors = {};
      this.$axios
        .post(`/api/keywords/add`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.$router.back()
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors || {};
          if (error.response.status === 400){
            this.$store.commit("setMessage", {
                            text: 'There are errors with the input data. Please check the contents.',
                            color: "warning",
                        });
          }
        });
    },
    select(name) {
      this.fields.name = name;
    },
  }
};
</script>
