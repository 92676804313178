<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/masters/methods" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>Edit Method</span>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Method*</th>
                  <td class="py-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      v-model="fields.name"
                      :rules="[rules.required, rules.counter50]"
                      :error-messages="
                        errors.name ? errors.name[0] :''
                      "
                    ></v-text-field>
                    <div v-if="errors && errors.name" class="text-danger v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          {{ errors.name[0] }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Sort</th>
                  <td class="py-2">
                    <v-text-field
                      solo
                      outlined
                      flat
                      color="black"
                      hide-details
                      dense
                      type="number" @keydown.e.prevent
                      step="1" 
                      v-model="fields.sort"
                      :rules="[rules.integer]"
                      :error-messages="
                        errors.sort ? errors.sort[0] :''
                      "
                    ></v-text-field>
                    <div v-if="errors && errors.sort" class="text-danger v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          {{ errors.sort[0] }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-checkbox 
                      hide-details
                      label="Hidden"
                      class="ma-4 ml-0"
                      value="Hidden"
                      v-model="fields.hidden"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-btn text-center mt-4 mb-8">
      <v-btn
        class="font-weight-bold mr-4"
        color="grey lighten-2"
        large
        to="/masters/methods"
      >
        Cancel
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="blue darken-4"
        dark
        large
        :disabled="buttonStatus"
        @click="submit"
      >
        Save
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      fields: {
        name: '',
        sort: null,
        hidden: null
      },
      rules: {
        required: value => !!value || 'This field is required.',
        counter50: value => value.length <= 50 || 'This form is limited in 50 words.',
      },
      breadcrumbs: [
        {
          text: "Masters",
          disabled: false,
          href: "/masters",
        },
        {
          text: "Method Master",
          disabled: false,
          href: "/masters/methods",
        },
        {
          text: "Method Edit",
          disabled: true,
          href: "/masters/methods/edit",
        },
      ],
      loading: false,
      dialog: false,
      buttonStatus: false,
    };
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/methods/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;
          if(this.fields.hidden === 1) this.fields.hidden = 'Hidden';
        });
    })();
  },
  methods: {
    submit() {
      this.errors = {};
      if(this.fields.hidden === 'Hidden') this.fields.hidden = 1;
      this.$axios
        .put(`/api/methods/${this.$route.params.id}`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "success",
            });
            this.$router.back()
          }else{
            this.$store.commit("setMessage", {
              text: response.data.message,
              color: "alert",
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors || {};
          if (error.response.status === 400){
            this.$store.commit("setMessage", {
                            text: 'There are errors with the input data. Please check the contents.',
                            color: "warning",
                        });
          }
        });
    },
  }
};
</script>
