<template>
  <v-container grid-list-md>
    <div class="d-flex justify-space-between mb-8">
      <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
      <div class="pa-4">
        <v-btn to="/users" exact outlined>Back</v-btn>
      </div>
    </div>

    <v-row justify="center" align="center" class="mb-4">
      <v-col lg="8">
        <v-card color="grey lighten-4">
          <v-card-title class="d-flex justify-space-between">
            <span>User Information</span>
            <v-btn
              color="black"
              dark
              :to="'/users/edit/' + fields.id"
              > EDIT </v-btn>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="30%">Name</th>
                  <td class="text-h6">{{ fields.name }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td class="text-subtitle-1">{{ fields.email }}</td>
                </tr>
                <tr>
                  <th>Affiliation</th>
                  <td class="text-subtitle-1">{{ fields.affiliation }}</td>
                </tr>
                <tr>
                  <th>Disabled</th>
                  <td class="text-subtitle-1">
                    <span v-if="fields.disabled" class="text-caption">X</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <th>Is Administration Office</th>
                  <td class="text-subtitle-1">
                    <span v-if="fields.is_administration_office" class="text-caption">X</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <th>Is Panel</th>
                  <td class="text-subtitle-1">
                    <span v-if="fields.is_panel" class="text-caption">X</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <th>Is Reviewer</th>
                  <td class="text-subtitle-1">
                    <span v-if="fields.is_reviewer" class="text-caption">X</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <th>Is Proposer</th>
                  <td class="text-subtitle-1">
                    <span v-if="fields.is_proposer" class="text-caption">X</span>
                    <span v-else>-</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="pa-6">
      <v-alert v-if="successMessageShow" border="top" type="success" dark>{{
        successMessage
      }}</v-alert>
    </div>
    <v-row justify="center" align="center">
      <v-col md="8">
        <div class="d-flex justify-start mt-6">
          <p style="width: 100px">Created:</p>
          {{ fields.created_at | moment("YYYY-MM-DD HH:mm") }}
        </div>
        <div class="d-flex justify-start">
          <p style="width: 100px">Last Update:</p>
          {{ fields.updated_at | moment("YYYY-MM-DD HH:mm") }} ({{ users[this.fields.last_updator_id] }})
        </div>
        <div class="d-flex justify-start mb-6">
          <p style="width: 100px">Last Login:</p>
          <span v-if="fields.last_login_at">
            {{ fields.last_login_at | moment("YYYY-MM-DD HH:mm") }}
          </span>
        </div>
      </v-col>
    </v-row>

    <div class="text-right mb-8 pb-8 pr-6">
      <v-btn
        class="font-weight-bold mr-4"
        color="blue darken-1"
        outlined
        @click="passwordReset"
      >
        <v-icon left>mdi-send</v-icon>
        PASSWORD RESET
      </v-btn>
      <v-btn v-if="fields.disabled === 1 && fields.id !== this.$store.state.auth.authuser.id" class="pafont-weight-bold" color="red darken-1" outlined @click="deleteUser">
        <v-icon left>mdi-trash-can</v-icon>
        Delete
      </v-btn>
    </div>
  </v-container>
</template>


<script>
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      register: false,
      fields: {},
      users: {},
      breadcrumbs: [
        {
          text: "User List",
          disabled: false,
          href: "/users/",
        },
        {
          text: "User Detail",
          disabled: true,
          href: "/users/detail",
        },
      ],
      successMessage: "",
      successMessageShow: false,
    };
  },
  filters: {
    /**
    * @param {Date} value    - Date オブジェクト
    * @param {string} format - 変換したいフォーマット
    */
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    (async () => {
      this.$axios
        .get(`/api/users/${this.$route.params.id}`)
        .then(response => {
          this.fields = response.data;
        });
      await this.$axios
        .get(`/api/masters/formArray/User`)
        .then(response => {
          this.users = response.data;
        });
    })();
  },
  methods: {
    passwordReset: function() {
      if(confirm('Do you want to reset password?')){
        this.$axios
          .put(`/api/users/reset_password/${this.$route.params.id}`)
          .then((response) => {
            response;
            this.successMessage = response.data.message;
            this.successMessageShow = true;
        });
      }
    },
    deleteUser: function() {
      if(confirm('Do you delete the user?')){
        this.$axios
          .put(`/api/users/delete/${this.$route.params.id}`)
          .then((response) => {
            response;
            if(response.data.result == true){
              this.$store.commit("setMessage",{
                text:response.data.message,
                color:"success",
              });
              this.$router.back();
            }else if(response.data.result == false){
              this.$store.commit("setMessage",{
                text:response.data.message,
                color:"alert",
              });
            }
        });
      }
    },
  },
  watch: {
    loading(val) {
      if (!val) return;

      //setTimeout(() => ((this.loading = false), (this.register = true)), 1000);
    },
  },
};
</script>